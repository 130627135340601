/*

note that show modal prop is an object of the lead source
we use it to pre-fill values when opening the modal

*/

import React from "react";

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

import { Modal, UncontrolledTooltip } from "reactstrap";
import { toggleStandardLoader } from 'store/functions/system/system';
import validator from 'utils/validator';

import _lead_sources from '_functions/lead_sources';
import { validatePhoneNumber } from 'utils/validation';
import { connect } from 'react-redux';

const required_fields = ['name', 'source' ]

class UpdateSourceModal extends React.Component {

    state = {
        lead_source: {}
    }

    componentWillReceiveProps = (nextProps) => {

        if(!this.props.showModal && nextProps.showModal) {
            this.setState({lead_source: Object.assign({}, nextProps.showModal)})
        }

    }

    onSave = async () => {
        
        this.setState({duplicateError: false})

        const validateFields = validator.requiredFields('lead_source', required_fields, this)
        const newState = validateFields.state
        
        if(newState.division === 'false') {
            return this.setState({lead_source: {...newState, divisionState: 'invalid'}})
        }

        let formattedPhone = validatePhoneNumber(newState.source)
        if(formattedPhone) newState.source = formattedPhone

        if(this.props.lead_sources.some(ls => ls.source && ls.active && ls._id !== newState._id && ls.source.toLowerCase() === newState.source.toLowerCase())) {
            return this.setState({duplicateError: true})
        }

        if(validateFields.success) {

            toggleStandardLoader(true)

            const updated = await _lead_sources.update(newState._id, {
                ...newState,
                amount_spent: !newState.amount_spent ? 0 : newState.amount_spent
            })
            
            if(updated.success) {
                this.props.onSuccess()
                this.props.toggleModal()
                this.setState({lead_source: {}})
            }

            toggleStandardLoader(false)

        }

    }

    render() {

        const { showModal, toggleModal, divisions } = this.props;
        const { lead_source, duplicateError} = this.state

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal ? true : false }
                toggle={() => toggleModal()}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title">Update Lead Source</h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => toggleModal()}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    {duplicateError ? 
                        <div className="alert alert-danger">An active lead source currently exists with the same source.</div> 
                    : null}

                    {lead_source.name === 'Other' ? (
                        <>
                        <p className="text-sm font-weight-bold"><i className="text-danger mr-2 fas fa-exclamation-triangle " /> Note that the "Other" lead source is the default lead source within the system. You may not delete it, change it's name, or mark it as inactive.</p>
                        <hr className="my-2"/>
                        </>
                    ) : null}

                    {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="name"
                        title="Name"
                    />
                    ): null}

                    <StandardFormGroup
                        obj={lead_source}
                        objName='lead_source'
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        type="select"
                        field="division"
                        title="Source Division"
                    >
                        <option value="false"></option>
                        {divisions.map(division => (
                            <option value={division._id} key={division._id}>{division.name}</option>
                        ))}
                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="amount_spent"
                        title={(
                            <span>
                                Amount Spent
                                <i id="tooltipAmountSpent" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipAmountSpent">
                                    This is the amount spent on this lead source to calculate it's profitability.
                                </UncontrolledTooltip>

                            </span>
                        )}
                        type="number"
                    />

                    {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="source"
                        title={(
                            <span>
                                Lead Source
                                <i id="tooltipLeadSource" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipLeadSource">
                                    This is usually a phone number or a web page URL.
                                </UncontrolledTooltip>

                            </span>
                        )}                    
                    />
                    ) : null}

                    {lead_source.name !== 'Other' ? (
                    <StandardFormGroup
                        obj={lead_source}
                        objName="lead_source"
                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                        field="lead_cost"
                        title={(
                            <span>
                                Cost Per Lead
                                <i id="tooltipLeadCost" className="fas fa-info-circle text-info-original ml-2" />
                                <UncontrolledTooltip delay={0} placement="top" target="tooltipLeadCost">
                                    This is the amount spent on every lead that gets put into this group.
                                </UncontrolledTooltip>

                            </span>
                        )}                    
                    />
                    ) : null}

                    {lead_source.name !== 'Other' ? (
                        <StandardFormGroup
                            obj={lead_source}
                            objName="lead_source"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`fState`]: v ? 'valid' : 'invalid'}})}
                            field="active"
                            formatter={e => e === true || e === 'true' ? 'true' : 'false'}
                            type="select"
                            title={"Is Active"}
                        >
                            <option value="true" >Yes</option>
                            <option value="false" >No</option>
                        </StandardFormGroup>
                    ) : null}
                    

                </div>

                <div className="modal-footer text-right">
                    <button className="btn btn-success" onClick={this.onSave}>
                        <i className="fas fa-save mr-2" />
                        Update Source
                    </button>
                </div>

            </Modal>

        );
    }
}

UpdateSourceModal.propTypes = {
    onSuccess     : PropTypes.func.isRequired,
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.object,
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(UpdateSourceModal);