import apiWorker from '../apiWorker'

const v2_call_center = {

    call_phone_numbers: {
        findByDivision: (division) => apiWorker('get', `/v1/call_phone_numbers/${division}`, null, null, null, 'TALK_SERVER_URL'),
        create: (data) => apiWorker('post', `/v1/call_phone_numbers`, data, null, null, 'TALK_SERVER_URL'),
        update: (call_number, data) => apiWorker('patch', `/v1/call_phone_numbers/${call_number}`, data, null, null, 'TALK_SERVER_URL'),
        delete: (call_number) => apiWorker('delete', `/v1/call_phone_numbers/${call_number}`, null, null, null, 'TALK_SERVER_URL'),

    }

}

export default v2_call_center;