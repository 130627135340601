/*
Documentation

This page shows all of the systems user_statuses

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _user_statuses from '_functions/user_statuses';

class UserStatuses extends React.Component {

    state = {
        user_status: {},
        userStatusToDelete: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({user_status: row})} className="cursor-pointer text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "time_to_delinquent",
            text: "Time To Delinquent",
            headerStyle: {width: 150},
            formatter: (cell, row) => row.time_to_delinquent
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({user_status: row})}><i className="cursor-pointer fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({userStatusToDelete: row})} className="cursor-pointer fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onCreate = async () => {

        const { user_status } = this.state

        if(!user_status.name) {
            return this.setState({user_status: {...user_status, nameState: 'invalid'}})
        }

        if(!user_status.time_to_delinquent) {
            return this.setState({user_status: {...user_status, time_to_delinquentState: 'invalid'}})
        }

        this.setState({loading: true})

        await _user_statuses.create({...user_status, time_to_delinquent: parseInt(user_status.time_to_delinquent)})

        this.setState({user_status: {}, loading: false})

    }

    onUpdate = async () => {

        const { user_status } = this.state

        if(!user_status.name) {
            return this.setState({user_status: {nameState: 'invalid'}})
        }

        if(!user_status.time_to_delinquent) {
            return this.setState({user_status: {time_to_delinquentState: 'invalid'}})
        }

        this.setState({loading: true})

        await _user_statuses.update(user_status._id, {...user_status, time_to_delinquent: parseInt(user_status.time_to_delinquent)})

        this.setState({user_status: {}, loading: false})

    }

    render() {

        const { user_statuses } = this.props;
        const userStatusLength = this.props.user_statuses.user_statuses.length;
        const userStatusToDelete = this.state.userStatusToDelete;

        const { user_status, loading } = this.state;

        const isUpdating = user_status._id;

        return (
            <>

            <Helmet>
                <title>{`User Statuses (${userStatusLength}) `}</title>
                <meta name="description" content="User Statuses" />
            </Helmet>

            {userStatusToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/user_statuses/delete/${userStatusToDelete._id}`}
                    confirmBtnText="Delete Status"
                    title={<span className="text-capitalize">{`Delete Status: ${userStatusToDelete.name}`}</span>}
                    text="Deleting this user status will not allow users to select it in the future. If a user is currently set to this status it will remain until they change it."
                    onClose={() => this.setState({userStatusToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="User Statuses"
                    breadcrumb_2="All"
                />

                <Card className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Statuses</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm">User statuses allow anyone with permission the ability to add, update, and delete the options staff members have to select for their status. Adding in a status and time will affect the admin call center and give alerts when a status has been set for to long. As an example, creating a status called "break" with a 15 minute time to delinquency will allow a user to say they are on a break and once they have set their status to that will alert admins 15 minutes after they set that to their status.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card className={isUpdating ?"card-color card-warning" : "card-color card-success"}>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update User' : 'Create User' }
                                </CardTitle>
                            </CardHeader>

                            <CardBody>

                                <StandardFormGroup
                                    obj={user_status}
                                    objName="user_status"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Status Name"
                                />

                                <StandardFormGroup
                                    obj={user_status}
                                    objName="user_status"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="number"
                                    field="time_to_delinquent"
                                    title="Time To Delinquent"
                                    description="This time should be in minutes. If you do not want to mark this status as delinquent after a period of time enter 0 "
                                />

                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update User</button>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New User</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card className="card-color card-primary">

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All User Statuses</CardTitle>
                            </CardHeader>

                            {user_statuses.total ? (

                                <ToolkitProvider
                                    data={user_statuses.user_statuses}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No User Statuses Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_statuses: state.user_statuses,
    };
};

export default connect(mapStateToProps, '')(UserStatuses);

