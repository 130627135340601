/*
Documentation


contact fields
first_name
middle_name
last_name
email
phone
_id

//static fields
date Jan 1st, 2020

case fields
case._id
case.name
case.case_number
case.chapter

custom data
cd.[custom field name]

custom data pulls first from the case, then from the contact
duplicate fields will be pulled from the contact

*/

import React, { Component } from 'react';
import { Modal, UncontrolledTooltip } from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";



class Copy extends Component {

    state = {
        copiedText: false
    };

    render() {

        const { field } = this.props

        return (
            <>
                <CopyToClipboard
                    text={`{${field}}`}
                    onCopy={() => {
                        this.setState({ copiedText: true }, () => {
                            setTimeout(() => { this.setState({ copiedText: false }) }, 500)
                        })
                    }}
                >
                    <i className="far fa-clipboard text-success mr-2" id={field.replace(/\./g, '')} />
                </CopyToClipboard>
                <UncontrolledTooltip
                    delay={0}
                    trigger="hover focus"
                    target={field.replace(/\./g, '')}
                >
                    {this.state.copiedText ? "Copied": "Copy To Clipboard"}
                </UncontrolledTooltip>
            </>
        )

    }

}


class Info extends Component {


    state = {

    };

    render() {

        const { showModal, toggleModal } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModal}
                toggle={toggleModal}
                size="lg"
            >
                <div className="modal-header">

                    <h5 className="modal-title">Appointment Notifications</h5>

                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>

                </div>

                <div className="modal-body bg-secondary">

                    <p className="text-sm mb-1">Appointment notifications can be populated with the following merge fields below.</p>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0">

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Contact Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>first_name</td>
                                    <td>Contact's first name.</td>
                                    <td><Copy field="first_name" /> {"{first_name}"}</td>
                                </tr>
                                <tr>
                                    <td>middle_name</td>
                                    <td>Contact's middle name.</td>
                                    <td><Copy field="middle_name" />{"{middle_name}"}</td>
                                </tr>
                                <tr>
                                    <td>last_name</td>
                                    <td>Contact's last name.</td>
                                    <td><Copy field="last_name" />{"{last_name}"}</td>
                                </tr>
                                <tr>
                                    <td>email</td>
                                    <td>The primary email of the contact.</td>
                                    <td><Copy field="email" />{"{email}"}</td>
                                </tr>
                                <tr>
                                    <td>phone</td>
                                    <td>The primary phone of the contact.</td>
                                    <td><Copy field="phone" />{"{phone}"}</td>
                                </tr>
                                <tr>
                                    <td>_id</td>
                                    <td>The system _id of the contact.</td>
                                    <td><Copy field="_id" />{"{_id}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Appointment Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>appointment.name</td>
                                    <td>The appointment name.</td>
                                    <td><Copy field="appointment.name" />{"{appointment.name}"}</td>
                                </tr>
                                <tr>
                                    <td>appointment.date</td>
                                    <td>The start of the appointment formatted MM/DD/YYYY h:mm.</td>
                                    <td><Copy field="appointment.date" />{"{appointment.date}"}</td>
                                </tr>
                                <tr>
                                    <td>appointment.date_range</td>
                                    <td>The date of the appointment formatted MM/DD/YYYY h:mm - h:mm.</td>
                                    <td><Copy field="appointment.date_range" />{"{appointment.date_range}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Case Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>case.missing_docs_list</td>
                                    <td style={{whiteSpace: 'pre-line'}}>A Special field pulling in all missing docs for the associated case.</td>
                                    <td><Copy field="case.missing_docs_list" />{"{case.missing_docs_list}"}</td>
                                    <td>case.case_number</td>
                                    <td style={{whiteSpace: 'pre-line'}}>The identifier for the associated case.</td>
                                    <td><Copy field="case.case_number" />{"{case.case_number}"}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Office Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>office.name</td>
                                    <td>The office name.</td>
                                    <td><Copy field="office.name" />{"{office.name}"}</td>
                                </tr>
                                <tr>
                                    <td>office.email</td>
                                    <td>The office email.</td>
                                    <td><Copy field="office.email" />{"{office.email}"}</td>
                                </tr>
                                <tr>
                                    <td>office.phone</td>
                                    <td>The office phone.</td>
                                    <td><Copy field="office.phone" />{"{office.phone}"}</td>
                                </tr>
                                <tr>
                                    <td>office.address_line_1</td>
                                    <td>The office address line 1.</td>
                                    <td><Copy field="office.address_line_1" />{"{office.address_line_1}"}</td>
                                </tr>
                                <tr>
                                    <td>office.address_line_2</td>
                                    <td>The office address line 2.</td>
                                    <td><Copy field="office.address_line_2" />{"{office.address_line_2}"}</td>
                                </tr>
                                <tr>
                                    <td>office.city</td>
                                    <td>The office city.</td>
                                    <td><Copy field="office.city" />{"{office.city}"}</td>
                                </tr>

                                <tr>
                                    <td>office.state</td>
                                    <td>The office state.</td>
                                    <td><Copy field="office.state" />{"{office.state}"}</td>
                                </tr>
                                <tr>
                                    <td>office.postal_code</td>
                                    <td>The office postal_code.</td>
                                    <td><Copy field="office.postal_code" />{"{office.postal_code}"}</td>
                                </tr>
                                <tr>
                                    <td>office.country</td>
                                    <td>The office country.</td>
                                    <td><Copy field="office.country" />{"{office.country}"}</td>
                                </tr>

                                <tr>
                                    <td>office.map</td>
                                    <td>A link to google maps representing the office address.</td>
                                    <td><Copy field="office.map" />{"{office.map}"}</td>
                                </tr>


                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Court Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>court.name</td>
                                    <td>The court name.</td>
                                    <td><Copy field="court.name" />{"{court.name}"}</td>
                                </tr>
                                <tr>
                                    <td>court.email</td>
                                    <td>The court email.</td>
                                    <td><Copy field="court.email" />{"{court.email}"}</td>
                                </tr>
                                <tr>
                                    <td>court.phone</td>
                                    <td>The court phone.</td>
                                    <td><Copy field="court.phone" />{"{court.phone}"}</td>
                                </tr>
                                <tr>
                                    <td>court.address_line_1</td>
                                    <td>The court address line 1.</td>
                                    <td><Copy field="court.address_line_1" />{"{court.address_line_1}"}</td>
                                </tr>
                                <tr>
                                    <td>court.address_line_2</td>
                                    <td>The court address line 2.</td>
                                    <td><Copy field="court.address_line_2" />{"{court.address_line_2}"}</td>
                                </tr>
                                <tr>
                                    <td>court.city</td>
                                    <td>The court city.</td>
                                    <td><Copy field="court.city" />{"{court.city}"}</td>
                                </tr>

                                <tr>
                                    <td>court.state</td>
                                    <td>The court state.</td>
                                    <td><Copy field="court.state" />{"{court.state}"}</td>
                                </tr>
                                <tr>
                                    <td>court.postal_code</td>
                                    <td>The court postal_code.</td>
                                    <td><Copy field="court.postal_code" />{"{court.postal_code}"}</td>
                                </tr>
                                <tr>
                                    <td>court.country</td>
                                    <td>The court country.</td>
                                    <td><Copy field="court.country" />{"{court.country}"}</td>
                                </tr>

                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Hearing Location Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>location_hearing.name</td>
                                    <td>The location hearing name.</td>
                                    <td><Copy field="location_hearing.name" />{"{location_hearing.name}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.email</td>
                                    <td>The location hearing email.</td>
                                    <td><Copy field="location_hearing.email" />{"{location_hearing.email}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.phone</td>
                                    <td>The location hearing phone.</td>
                                    <td><Copy field="location_hearing.phone" />{"{location_hearing.phone}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.address_line_1</td>
                                    <td>The location hearing address line 1.</td>
                                    <td><Copy field="location_hearing.address_line_1" />{"{location_hearing.address_line_1}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.address_line_2</td>
                                    <td>The location hearing address line 2.</td>
                                    <td><Copy field="location_hearing.address_line_2" />{"{location_hearing.address_line_2}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.city</td>
                                    <td>The location hearing city.</td>
                                    <td><Copy field="location_hearing.city" />{"{location_hearing.city}"}</td>
                                </tr>

                                <tr>
                                    <td>location_hearing.state</td>
                                    <td>The location hearing state.</td>
                                    <td><Copy field="location_hearing.state" />{"{location_hearing.state}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.postal_code</td>
                                    <td>The location hearing postal_code.</td>
                                    <td><Copy field="location_hearing.postal_code" />{"{location_hearing.postal_code}"}</td>
                                </tr>
                                <tr>
                                    <td>location_hearing.country</td>
                                    <td>The location hearing country.</td>
                                    <td><Copy field="location_hearing.country" />{"{location_hearing.country}"}</td>
                                </tr>

                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Trustee Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>trustee.first_name</td>
                                    <td>The first name of the trustee.</td>
                                    <td><Copy field="trustee.first_name" />{"{trustee.first_name}"}</td>
                                </tr>

                                <tr>
                                    <td>trustee.middle_name</td>
                                    <td>The middle_name of the trustee.</td>
                                    <td><Copy field="trustee.middle_name" />{"{trustee.middle_name}"}</td>
                                </tr>

                                <tr>
                                    <td>trustee.last_name</td>
                                    <td>The last name of the trustee.</td>
                                    <td><Copy field="trustee.last_name" />{"{trustee.last_name}"}</td>
                                </tr>

                                <tr>
                                    <td>trustee.email</td>
                                    <td>The trustee email.</td>
                                    <td><Copy field="trustee.email" />{"{trustee.email}"}</td>
                                </tr>

                                <tr>
                                    <td>trustee.phone</td>
                                    <td>The trustee phone.</td>
                                    <td><Copy field="trustee.phone" />{"{trustee.phone}"}</td>
                                </tr>

                                <tr>
                                    <td>trustee._id</td>
                                    <td>The trustee _id.</td>
                                    <td><Copy field="trustee._id" />{"{trustee._id}"}</td>
                                </tr>


                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Party Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>party.[party].[field]</td>
                                    <td  style={{whiteSpace: 'pre-line'}}>Party fields work by pulling in the parties on the case the appointment is associated to. If a party is absent on the case a user is instructed to add that party whenever creating an appointment using this templates.</td>
                                    <td>{'{party.Opposing Attorney.first_name}'}</td>
                                </tr>

                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Role Fields</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                    <td style={{width: 200}} className="text-dark font-weight-bold">Usage</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>role.[role].[field]</td>
                                    <td style={{whiteSpace: 'pre-line'}}>Role fields work by pulling in the roles on the case the appointment is associated to. If a role is absent on the case a user is instructed to add that role whenever creating an appointment using this templates.</td>
                                    <td>{'{role.Attorney.first_name}'}</td>
                                </tr>

                            </tbody>

                        </table>
                    </div>

                    <div className="table-responsive bg-white mb-4">
                        <table className="table mb-0" style={{border: 'solid 1px #ddd'}}>

                            <thead>
                                <tr>
                                    <td style={{width: 150}} className="text-dark font-weight-bold">Custom Data</td>
                                    <td className="text-dark font-weight-bold">Description</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>cd.[field]</td>
                                    <td style={{whiteSpace: 'pre-line'}}>Custom data fields can be pulled in by using the keyword cd.[the custom data field]. Fields are pulled from from custom data on the contact and then from custom data on their most recent case. For example if the contact has assigned a custom data field called Plan Amount you can pull this field by adding {"{cd.Plan Amount}"} into the body of the email. </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>



                    <div className="p-2 px-3 bg-white" style={{border: 'solid 1px #ddd'}}>
                        <h4>Example notification with field usage:</h4>
                        <p className="text-sm mb-0">Hi {"{first_name}"} your this is a reminder about your appointment on {"{appointment.date_range}"}</p>
                    </div>

                </div>

            </Modal>

        )

    }

}

export default Info;
