import React, { memo } from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import A from 'components/markup/links/A'

import keys from 'keys';

const CaseAppointmentSync = ({case_appointment_sync}) => (
    <CardHeader>
        <Row>
            <Col xs={6}>
                <A href="/analytics/cases/sync_case_appointment">
                    <i className="fas fa-door-open mr-2 text-success " />
                </A>
                <span id="archk-case-appointment-sync-toggler" className="cursor-pointer">
                    Case Appt Sync  <i className="fas fa-caret-down text-warning " />
                </span>
            </Col>
            <Col xs={6} className="text-right">
            {case_appointment_sync.length}
            </Col>
        </Row>
        <UncontrolledCollapse toggler="#archk-case-appointment-sync-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th>Missing Appointment</th>
                                <th className="text-right">Date Step Moved</th>
                            </tr>
                        </thead>
                        <tbody>
                            {case_appointment_sync.map((sync) => (
                                <tr key={sync._id}>
                                    <td className="text-capitalize">
                                        <A href={`${keys.APP_URL}/dashboard/cases/view/${sync.case}`}>
                                            {sync.case_name}
                                        </A>
                                    </td>
                                    <td>
                                        {sync.template_appointment_name}
                                    </td>
                                    <td className="text-right">
                                        {moment.unix(sync.current_step_start).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(CaseAppointmentSync);