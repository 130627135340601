/*
Documentation

this file renders the right hand sidebar on all automations
here we can change that automation type and updated individual
tasks within the automation

*/

import ReactSelect from 'components/functional/inputs/ReactSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

class AutoSidebarTypeEmail extends Component {

    state = {
        task: {}
    };

    componentDidMount = () => {

        const { template_emails } = this.props
        const options = []

        template_emails.forEach(email => {
            options.push({value: email._id, label: email.name})
        })

        this.setState({options})

    }

    render() {

        const { task, onChange, template_emails, email_senders } = this.props
        const { options } = this.state

        if(task.type !== 'email') return null

        const foundEmail = template_emails.find(e => e._id === task.template_email)

        return (

            <li>

                <FormGroup>
                    <label className="form-control-label">Template Email</label>
                    <ReactSelect
                        value={foundEmail ? {value: foundEmail._id, label: foundEmail.name} : {}}
                        onChange={values => onChange('task', 'template_email', values.value)}
                        options={options}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Email Sender</label>
                    <Input 
                        type="select"
                        value={task.email_sender || ''}
                        onChange={(e) => onChange('task', 'email_sender', e.target.value)}
                    >
                        {!task.email_sender ? (
                            <option value="false"></option>
                        ) : null}
                        {email_senders.map(sender => (
                            <option key={sender._id} value={sender._id}>{sender.name} - {sender.email}</option>
                        ))}
                    </Input>
                </FormGroup>

               
                <hr />

                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        id="emailAlertCheckbox"
                        type="checkbox"
                        onChange={() => onChange('task', 'create_alerts', task.create_alerts === 'yes' ? 'no' : 'yes')}
                        checked={task.create_alerts === 'yes' ? true : false}
                    />
                    <label className="custom-control-label" htmlFor="emailAlertCheckbox">Create a case and contact alert with this email?</label>
                </div>

            </li>

        )

    }

}

AutoSidebarTypeEmail.propTypes = {
    task    : PropTypes.object.isRequired,
    onChange         : PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        template_emails: state.template_emails.template_emails,
        email_senders: state.email_senders.email_senders,
    };
};

export default connect(mapStateToProps, '')(AutoSidebarTypeEmail);
