
import Chart from "chart.js";
import React, { Component } from 'react';
import { Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import createDefaultOptions from './_createDefaultOptions';
import PropTypes from 'prop-types';
import { formatNumber } from './_utils'

class ChartsLine extends Component {

    state ={
        noData: false
    }

    componentWillMount() {

        if (window.Chart) createDefaultOptions(Chart);

    }

    componentWillReceiveProps = (nextProps) => {

        // try {

        //     const hasData = nextProps.data.datasets.find(d => d.data.find(v => v !== 0))
        //     this.setState({noData: !hasData})

        // } catch(e) {


        //     this.setState({noData: true})

        // }

    }

    render() {

        const { noData, hidden, fullscreen } = this.state

        const { data, title } = this.props

        let shouldRender = false;

        if(data && data.datasets && data.datasets.length && data.datasets.find(d => d.data && d.data.length > 1)) shouldRender = true

        if(!shouldRender) return null

        return (

            <div className={fullscreen ? 'fullscreen graph' : 'graph'}>

                {noData ? ( <div className="no-data"><div className="no-data-content">No Data To Show</div></div> ) : null}

                <Card>

                    <CardHeader>
                        <Row>

                            <Col xs={8} className="align-self-center">
                                <h5 className="h3 mb-0">{title ? title : 'Line Graph'}</h5>
                            </Col>

                            <Col xs={4} className="text-right align-self-center">
                                {!fullscreen ? (
                                    <span className="toggler" onClick={() => this.setState({hidden: !hidden})}>
                                        {hidden ? ( <i className="fas fa-eye" /> ) : ( <i className="fas fa-eye-slash" />)}
                                    </span>
                                ) : null}

                                <span className="toggler" onClick={() => this.setState({fullscreen: !fullscreen, hidden: false})}>
                                    {fullscreen ? ( <i className="fas fa-compress" /> ) : ( <i className="fas fa-expand" />)}
                                </span>
                            </Col>

                        </Row>
                    </CardHeader>

                    <CardBody className={hidden ? 'hidden' : null}>

                        <div className="chart">

                            <Line
                                data={data}
                                options={{
                                    scales: {
                                        offset: true,
                                        yAxes: [
                                            {
                                                ticks: {
                                                    min: 0,
                                                }
                                            }
                                        ]
                                    },
                                    tooltips: {
                                        callbacks: {
                                            label: function(tooltipItem, data) {

                                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                                var currentValue = dataset.data[tooltipItem.index];

                                                let defaultLabel = dataset.label + ' ' + formatNumber(currentValue, false, 0)

                                                if(data.datasets.length >= 3 && tooltipItem.datasetIndex !== 0) {

                                                    let total = 0
                                                    data.datasets.forEach((d, i) => total += i === 0 ? 0 : d.data[tooltipItem.index])

                                                    var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                                    return defaultLabel + ' (' + percentage + '%)';

                                                }

                                                return defaultLabel

                                            },
                                            title: function(tooltipItem, data) {
                                                return data.labels[tooltipItem[0].index];
                                            }
                                        }
                                    }
                                }}
                                className="chart-canvas"
                            />
                            </div>
                    </CardBody>

                </Card>

            </div>

        )

    }

}

ChartsLine.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
}

export default ChartsLine;
