import moment from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { capitalize } from 'utils/text'
import { Card, CardHeader, CardTitle, Row, Col, FormGroup, Input, CardFooter } from 'reactstrap';

import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';

import fileDownloader from 'js-file-download';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import keys from 'keys';
import Axios from 'axios';


const OverviewOverview = ({data, users, startOfDay, downloadMonth}) => {

    const [filter, setFilter] = useState(null);
    const [filterOrder, setFilterOrder] = useState(1);

    const onDownloadOverview = async (user) => {

        // toggleStandardLoader(true);

        Axios({
            responseType:'arraybuffer',
            headers: { authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
            withCredentials: true,
            method: 'post',
            url: keys.API_URL + `/api/v1/analytics/custom_reports/call_center/downloadOverview`,
            data: { 
                startOfDay: moment(startOfDay).format('X')
            },
        })

        toggleAlertBS(false, 'The activity report will show up in a few minutes under a case called "Activity Report')

        // toggleStandardLoader(false);

        // if(result.headers && result.headers['content-type'].includes('application/json')) {
        //     try {
        //         var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
        //         var body = JSON.parse(decodedString);
        //         if(body.message && body.message.length) {
        //             toggleAlertBS(true, `Something's not right, please try again`);
        //             return body.message[0] ? body.message[0] : `Something's not right, please try again`;
        //         }
        //     } catch(e) {
        //         console.log(e)
        //         toggleAlertBS(true, `Something's not right, please try again`);
        //     }
        //     return;
        // }

        // fileDownloader(result.data, `Activity Report.pdf`) 
    }
  
    const onDownload = async (user) => {

        toggleStandardLoader(true);

        const result = await Axios({
            responseType:'arraybuffer',
            headers: { authorization: `Bearer ${keys.SYSTEM_API_KEY}` },
            withCredentials: true,
            method: 'post',
            url: keys.API_URL + `/api/v1/analytics/custom_reports/call_center/downloadUser`,
            data: { 
                downloadMonth,
                user: user._id,
                startOfDay: moment(startOfDay).format('X')
            },
        })
        toggleStandardLoader(false);

        if(result.headers && result.headers['content-type'].includes('application/json')) {
            try {
                var decodedString = String.fromCharCode.apply(null, new Uint8Array(result.data));
                var body = JSON.parse(decodedString);
                if(body.message && body.message.length) {
                    toggleAlertBS(true, `Something's not right, please try again`);
                    return body.message[0] ? body.message[0] : `Something's not right, please try again`;
                }
            } catch(e) {
                console.log(e)
                toggleAlertBS(true, `Something's not right, please try again`);
            }
            return;
        }

        fileDownloader(result.data, `${capitalize(user.given_name)} ${capitalize(user.family_name)} - Activity Report.pdf`) 
    }

    const onSetFilter = (_filter) => {
        if(filter === _filter) {
            return setFilterOrder(-1 * filterOrder)
        }
        setFilterOrder(1);
        setFilter(_filter);
    }



    const UserRow = ({user, metrics}) => (
        <tr>
             <td className="text-capitalize">
               
                <small>
                    <a
                        href={`/blank/screen_watch/${user._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="fas fa-eye mr-2 text-warning mr-2 cursor-pointer" />
                    </a>
                    <i onClick={() => onDownload(user)} className="fas fa-download mr-2 text-success " />
                    {renderName(user)} ({metrics ? metrics.totalTimeBetweenCalls && metrics.totalCalls ? secondsToTime(metrics.totalTimeBetweenCalls / metrics.totalCalls) : 0 : '-'})
                </small>
            </td>
             <td>
                <small>{metrics ? metrics.totalCallsLastHour : '0'} / {metrics ? metrics.timeOnCallLastHour ? secondsToTime(metrics.timeOnCallLastHour) : 0 : '0 Mins'}</small>
            </td>
             <td>
                <small>{metrics ? metrics.totalCalls : '0'} / {metrics ? metrics.timeOnCall ? secondsToTime(metrics.timeOnCall) : 0 : '0 Mins'}</small>
            </td>            
             <td><small>{metrics ? metrics.totalCallsOver2Minutes : '-'}</small></td>
        </tr>
    )

    let sortedMetrics = JSON.parse(JSON.stringify(data.callLogs.metrics));

    // filter object keys to map either all asc or all desc
    if(filter) {
        sortedMetrics = Object.keys(sortedMetrics).sort((a, b) => {
            let valA = sortedMetrics[a][filter]
            let valB = sortedMetrics[b][filter]

            if(filter === 'totalTimeBetweenCalls') {
                valA = valA / sortedMetrics[a]['totalCalls']
                valB = valB / sortedMetrics[b]['totalCalls']
            }
            
            return valA < valB ? filterOrder : -1 * filterOrder
        }).reduce((accumulator, key) => {
            accumulator[key] = sortedMetrics[key];
            return accumulator;
        }, {});
    }

    return (
        <Card style={{height: 'calc(100vh - 298px)', overflow: 'auto'}}>
    
            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">Users</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <FormGroup className="mb-0">
                            <Input 
                                size="sm"
                                type="select"
                                value={filter ? filter : 'Sort'}
                                onChange={(e) => onSetFilter(e.target.value === 'Sort' ? null : e.target.value)}
                            >
                                <option value="Sort">Select Sorting</option>
                                <option value="totalCallsLastHour">Total Calls Last Hour</option>
                                <option value="totalCallsOver2Minutes">Total Calls Over 2 Minutes</option>
                                <option value="timeOnCall">Time On Call</option>
                                <option value="totalCalls">Total Calls</option>
                                <option value="totalTimeBetweenCalls">Total Time Between Calls</option>
                                <option value="timeOnCallLastHour">Time On Call Last Hour</option>
                             
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </CardHeader>
    
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>User (Avg Between)</th>
                            <th className={`curor-pointer ${filter === 'timeOnCallLastHour' ? 'text-success' : null}`} onClick={() => onSetFilter('timeOnCallLastHour')}>
                                Last Hour
                            </th>

                            <th className={`curor-pointer ${filter === 'timeOnCall' ? 'text-success' : null}`} onClick={() => onSetFilter('timeOnCall')}>
                                Total
                            </th>
                            <th className={`curor-pointer ${filter === 'totalCallsOver2Minutes' ? 'text-success' : null}`} onClick={() => onSetFilter('totalCallsOver2Minutes')}>
                                2M+
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(sortedMetrics).map((key, i) => {
                            const user = users.find(u => u._id === key);
                            return (
                                <UserRow key={i} user={user} metrics={sortedMetrics[key]} />
                            )
                        })}
                        {users.map((user, i) => {
    
                            if(sortedMetrics[user._id]) return <></>
    
                            return (
                                <UserRow 
                                    key={i}
                                    user={user} metrics={null}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <CardFooter className="text-right">
                <button onClick={onDownloadOverview} className="btn btn-success">Download Overview</button>
            </CardFooter>
    
        </Card>
    )
}


const mapStateToProps = state => {
    return {
        users : state.users.users,
    };
};

export default connect(mapStateToProps, '')(OverviewOverview);
