/*
Documentation

This file updates a location_hearing

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import React from "react";
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, CardFooter } from "reactstrap";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import _location_hearings from '_functions/location_hearings';
import { toggleAlertBS } from 'store/functions/system/system'

const required_form_fields = [
    'name',
    'county',
]

class LocationHearingsView extends React.Component {

    state = {
        location_hearing: {},
        canSave: true,
    }

    getLocationHearings = () => {

        const location_hearings = this.props.location_hearings.location_hearings

        if(location_hearings && location_hearings.length ) {

            // if we found the location_hearing to updated else redirect to all location_hearings
            let location_hearing = location_hearings.find(c => c._id === this.props.match.params._id)
            if(location_hearing) return this.setState({location_hearing: location_hearing})

        }

        this.setState({shouldRedirect: '/core/locations/location_hearings/all'})

    }

    onUpdate = async () => {

        let newState = Object.assign({}, this.state.location_hearing);
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ location_hearing: newState, canSave: false });

        if(!errors) {

            // update the location_hearing and show success message if it suceeded
            const updated = await _location_hearings.update(this.props.match.params._id, newState)
            if(updated.success) toggleAlertBS(false, 'Location Hearing Updated Successfully.')

        }

        this.setState({canSave: true})

    }

    componentDidMount = () => this.getLocationHearings()

    render() {

        if(!this.state.location_hearing) { return <div> </div> }
        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }

        const location_hearing = this.state.location_hearing

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Hearing Location View</title>
                    <meta name="description" content="Hearing Location View" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Hearing Locations"
                    breadcrumb_2={this.state.location_hearing.name}
                    actionComponent={(
                        <Link to="/core/locations/location_hearings" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>                    
                    )}
                />

                <Card  className="card-color card-warning">

                    <CardHeader>
                        <h3 className="mb-0">View Location Hearing</h3>
                    </CardHeader>

                    <CardBody>

                        <Row>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Name *"
                                />
                            </Col>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="county"
                                    title="County *"
                                />
                            </Col>

                        </Row>

                        <StandardFormGroup
                            obj={location_hearing}
                            objName="location_hearing"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="email"
                            title="Email"
                        />

                        <StandardFormGroup
                            obj={location_hearing}
                            objName="location_hearing"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="phone"
                            title="Phone"
                            type="tel"
                        />

                        <hr />

                        <Row>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_1"
                                    title="Address Line 1 "
                                />
                            </Col>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_2"
                                    title="Address Line 2 "
                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="city"
                                    title="City"
                                />
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="state"
                                    title="State"
                                    type="select"
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </StandardFormGroup>
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="postal_code"
                                    title="Postal code "
                                />
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-right">
                        <Button
                            color="success"
                            onClick={this.onUpdate}
                            disabled={this.state.canSave ? false : true}
                        >
                            Update Location Hearing
                        </Button>
                    </CardFooter>

                </Card>

            </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        location_hearings: state.location_hearings
    };
};

export default connect(mapStateToProps, '')(LocationHearingsView);
