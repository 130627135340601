import React from 'react';
import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input } from "reactstrap";

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import _analytics from '_functions/analytics';

import ReactQuill from "react-quill";

const ByStepText = ({showModal, toggleModal, data, recurrings}) => {

    const [state, setState] = useState({
        body: '',
        subject: '',
    });
    const [errors, setErrors] = useState([]);

    const onSetState = (field, e) => {
        const newState = JSON.parse(JSON.stringify(state));
        const value = e.target.value;
        newState[field] = value;
        setState(newState);
    }

    const onSend = useCallback(async (state) => {

        const errs = [];

        if(!state.body) errs.push('body');
        if(!state.subject) errs.push('body');
        if(state.body.length > 2000) return toggleAlertBS(true, 'Email must be under 2000 characters.')

        setErrors(errs);

        if(errs.length) return;

        toggleStandardLoader(true);

        const sent = await _analytics.payments.email({recurrings, body: state.body, subject: state.subject})
        if(!sent.success) {
            toggleAlertBS(true, `Internal Error`)
        } else {
            toggleAlertBS(false, `Mass Email Success, Sent: ${sent.data.passed}, Failed: ${sent.data.failed}, No Phone: ${sent.data.noEmail}`)
        }

        setState({body: '', subject: ''})
        toggleModal()

        toggleStandardLoader(false);


    }, [recurrings, state.body, state.subject])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="lg"
        >

            <div className="modal-header">
                <h5 className="modal-title">Mass Email</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body bg-secondary">
                <p className="text-sm mb-0">This email will go out to {recurrings ? recurrings.length : 0} contacts.</p>
            </div>
            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Subject*</label>
                    <Input 
                        type="text"
                        value={state.subject}
                        onChange={(e) => onSetState('subject', e)}
                        invalid={errors.includes('subject')}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Body*</label>
                    {/* <Input 
                        style={{minHeight: 200}}
                        type="textarea"
                        value={state.body}
                        onChange={(e) => onSetState('body', e)}
                        invalid={errors.includes('body')}
                    /> */}
                    <ReactQuill
                        value={state.body}
                        onChange={(value) => onSetState('body', {target: {value}})}
                        theme="snow"
                        modules={{
                            toolbar: [
                                ["bold", "italic"],
                                ["link",],
                                [
                                    { list: "ordered" },
                                    { list: "bullet" }
                                ]
                            ]
                        }}
                    />
                </FormGroup>

            </div>

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={() => onSend(state)}>
                    <i className="far fa-paper-plane mr-2" />
                    Send
                </button>
            </div>

        </Modal>

    )

}

ByStepText.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ByStepText
