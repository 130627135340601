import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { architeckCall } from 'database';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import Dots from 'components/markup/loading/Dots'

class DailyReport extends Component {


    state = {
        loading: true
    };


    // a is the number we want the percent increase or decrease of compared to b
    // if negativeIsGood === true we invert the color scheme
    getPercent = (a, b, negativeIsGood) => {

        if(!a && !b) return '+0.00%'
        if(!a || !b) return '-'

        let color;

        if(a >= b) {

            if(negativeIsGood) {
                color = 'text-danger'
            } else {
                color = 'text-success'
            }

        } else {

            if(negativeIsGood) {
                color = 'text-success'
            } else {
                color = 'text-danger'
            }

        }

        const percentSign = (a >= b) ? '+' : ''
        const percent =( ((a - b) / b) * 100 ).toFixed(2)

        return <span className={color}>{percentSign}{percent}%</span>

    }

    componentDidMount = async () => {

        const report = await architeckCall({
            method: 'get',
            url: `/api/v1/analytics/custom_reports/payments`
        })

        try {

            this.setState({payments: report.data.payments, recurrings: report.data.recurrings, loading: false})

        } catch(e) {

            console.log(e)

        }

    }

    render() {

        const { payments, recurrings, loading } = this.state

        if(loading) return (
            <div className="py-6 text-center"><h2>Loading Payment Data<Dots /></h2></div>
        )


        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                    breadcrumb_1="Daily Reports"
                    breadcrumb_2="View"
                />

                <Card>

                    <CardHeader className="bg-dark text-white">
                            <Row>

                                <Col md={6}>
                                    <CardTitle className="mb-0">Today's <b className="text-underline">Total</b> Payments </CardTitle>
                                </Col>

                                <Col md={6} className="text-right">
                                    <CardTitle className="mb-0">
                                        Total:{' '}
                                        <span className="text-success font-weight-bold">
                                            {formatMoney(payments.today.succeeded.total - payments.today.refunded.total - payments.today.chargeback.total)}
                                        </span>
                                    </CardTitle>
                                </Col>
                            </Row>
                    </CardHeader>

                    <CardBody>
                        <Row>

                            <Col lg={3}>
                                <h3><i className="fas fa-check mr-1 text-success" /> Succeeded</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.succeeded.total)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total, payments.weeks4Ago.succeeded.total)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total, payments.weeks52Ago.succeeded.total)} from 52 weeks ago</small></div>

                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times mr-1 text-warning" /> Failed</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.failed.total)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total, payments.weeks4Ago.failed.total, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total, payments.weeks52Ago.failed.total, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-hand-holding-usd mr-1 text-warning" /> Refunded</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.refunded.total)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total, payments.weeks4Ago.refunded.total, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total, payments.weeks52Ago.refunded.total, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times-circle text-danger mr-1" /> Charged Back</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.chargeback.total)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total, payments.weeks4Ago.chargeback.total, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total, payments.weeks52Ago.chargeback.total, true)} from 52 weeks ago</small></div>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardHeader className="bg-dark text-white" style={{borderTop: 'solid 1px #f6f9fc'}}>
                            <Row>

                                <Col md={6}>
                                    <CardTitle className="mb-0">Today's <b className="text-underline">STANDARD</b> Payments </CardTitle>
                                </Col>

                                <Col md={6} className="text-right">
                                    <CardTitle className="mb-0">
                                        Total:{' '}
                                        <span className="text-success font-weight-bold">
                                            {formatMoney(payments.today.succeeded.total_standard - payments.today.refunded.total_standard - payments.today.chargeback.total_standard)}
                                        </span>
                                    </CardTitle>
                                </Col>
                            </Row>
                    </CardHeader>

                    <CardBody>
                        <Row>

                            <Col lg={3}>
                                <h3><i className="fas fa-check mr-1 text-success" /> Succeeded</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.succeeded.total_standard)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total_standard, payments.weeks4Ago.succeeded.total_standard)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total_standard, payments.weeks52Ago.succeeded.total_standard)} from 52 weeks ago</small></div>

                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times mr-1 text-warning" /> Failed</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.failed.total_standard)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total_standard, payments.weeks4Ago.failed.total_standard, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total_standard, payments.weeks52Ago.failed.total_standard, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-hand-holding-usd mr-1 text-warning" /> Refunded</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.refunded.total_standard)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total_standard, payments.weeks4Ago.refunded.total_standard, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total_standard, payments.weeks52Ago.refunded.total_standard, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times-circle text-danger mr-1" /> Charged Back</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.chargeback.total_standard)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total_standard, payments.weeks4Ago.chargeback.total_standard, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total_standard, payments.weeks52Ago.chargeback.total_standard, true)} from 52 weeks ago</small></div>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardHeader className="bg-dark text-white" style={{borderTop: 'solid 1px #f6f9fc'}}>
                            <Row>

                                <Col md={6}>
                                    <CardTitle className="mb-0">Today's <b className="text-underline">TRUST</b> Payments </CardTitle>
                                </Col>

                                <Col md={6} className="text-right">
                                    <CardTitle className="mb-0">
                                        Total:{' '}
                                        <span className="text-success font-weight-bold">
                                            {formatMoney(payments.today.succeeded.total_trust - payments.today.refunded.total_trust - payments.today.chargeback.total_trust)}
                                        </span>
                                    </CardTitle>
                                </Col>
                            </Row>
                    </CardHeader>

                    <CardBody>
                        <Row>

                            <Col lg={3}>
                                <h3><i className="fas fa-check mr-1 text-success" /> Succeeded</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.succeeded.total_trust)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total_trust, payments.weeks4Ago.succeeded.total_trust)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.succeeded.total_trust, payments.weeks52Ago.succeeded.total_trust)} from 52 weeks ago</small></div>

                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times mr-1 text-warning" /> Failed</h3>
                                <h2 className="font-weight-bold">
                                    {formatMoney(payments.today.failed.total_trust)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total_trust, payments.weeks4Ago.failed.total_trust, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.failed.total_trust, payments.weeks52Ago.failed.total_trust, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-hand-holding-usd mr-1 text-warning" /> Refunded</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.refunded.total_trust)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total_trust, payments.weeks4Ago.refunded.total_trust, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.refunded.total_trust, payments.weeks52Ago.refunded.total_trust, true)} from 52 weeks ago</small></div>
                            </Col>

                            <Col lg={3} className="">
                                <h3><i className="fas fa-times-circle text-danger mr-1" /> Charged Back</h3>
                                <h2 className="font-weight-bold ">
                                    {formatMoney(payments.today.chargeback.total_trust)}
                                </h2>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total_trust, payments.weeks4Ago.chargeback.total_trust, true)} from 4 weeks ago</small></div>
                                <div><small className="text-dark"> {this.getPercent(payments.today.chargeback.total_trust, payments.weeks52Ago.chargeback.total_trust, true)} from 52 weeks ago</small></div>
                            </Col>
                        </Row>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Expected Recurring Payments</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <Row>
                                    <Col lg={6}>

                                        <h3>Today</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.today.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.today.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(recurrings.today.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Next 7 Days</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.next7Days.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.next7Days.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(recurrings.next7Days.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>
                                    </Col>
                                    <Col lg={6} >

                                        <h3>Tomorrow</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.tomorrow.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.tomorrow.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(recurrings.tomorrow.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Next 30 Days</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.next30Days.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(recurrings.next30Days.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(recurrings.next30Days.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>

                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Yesterdays Payments</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <Row>
                                    <Col lg={6}>

                                        <h3>Succeeded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.succeeded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.succeeded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(payments.yesterday.succeeded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Failed</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.failed.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.failed.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.yesterday.failed.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                    <Col lg={6} className="">

                                        <h3>Refunded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.refunded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.refunded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.yesterday.refunded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Chargeback</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.chargeback.total)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.yesterday.chargeback.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.yesterday.chargeback.total_trust)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>

                </Row>

                <Row>

                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Last 7 Days</CardTitle>
                            </CardHeader>

                            <CardBody>
                                <Row>
                                    <Col lg={6}>

                                        <h3>Succeeded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.succeeded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.succeeded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(payments.last7Days.succeeded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Failed</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.failed.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.failed.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last7Days.failed.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                    <Col lg={6} className="">

                                        <h3>Refunded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.refunded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.refunded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last7Days.refunded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Chargeback</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.chargeback.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last7Days.chargeback.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last7Days.chargeback.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>

                    <Col lg={6}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0">Last 30 Days</CardTitle>
                            </CardHeader>

                             <CardBody>
                                <Row>
                                    <Col lg={6}>

                                        <h3>Succeeded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.succeeded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.succeeded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-success">{formatMoney(payments.last30Days.succeeded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Failed</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.failed.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.failed.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last30Days.failed.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                    <Col lg={6} className="">

                                        <h3>Refunded</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.refunded.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.refunded.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last30Days.refunded.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                        <h3>Chargeback</h3>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Standard:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.chargeback.total_standard)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <h5 className="font-weight-bold mb-0">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Trust:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    {formatMoney(payments.last30Days.chargeback.total_trust)}
                                                </Col>
                                            </Row>
                                        </h5>
                                        <hr className="my-2" />
                                        <h5 className="font-weight-bold mb-4">
                                            <Row>
                                                <Col xs={5}>
                                                    <b>Total:</b>
                                                </Col>
                                                <Col xs={7} className="text-right">
                                                    <b className="text-danger">{formatMoney(payments.last30Days.chargeback.total)}</b>
                                                </Col>
                                            </Row>
                                        </h5>

                                    </Col>
                                </Row>
                            </CardBody>


                        </Card>
                    </Col>

                </Row>


            </Container>
            </>

        )

    }

}

export default DailyReport;
