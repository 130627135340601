import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import { architeckCall } from 'database';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, FormGroup, Input, Row } from 'reactstrap';
import { formatMoney } from 'utils/currency';

const PaymentProjections = ({offices, divisions}) => {

    const [data, setDate] = useState();
    const [division, setDivision] = useState();
    const [ loading, setLoading ] = useState(false);

    const [start, setStart] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [end, setEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    const runQuery = async () => {

        setLoading(true);

        const startUnix = parseInt(moment(start).format('X'))
        const endUnix = parseInt(moment(end).format('X'))

        let filter = `?start=${startUnix}&end=${endUnix}`;

        if(division && division !== 'false') {
            filter += `&division=${division}`;
        }

        const data = await architeckCall({
            method: 'get',
            url: `/api/v1/analytics/custom_reports/payments/fees_expenses${filter}`
        })

        setDate(data.data)
        setLoading(false);

    }

    const getOfficeName = (office) => {
        if(!office) return 'All Offices';

        const foundOffice = offices.find(o => o._id === office);
        return foundOffice ? foundOffice.name : 'NOT FOUND'
    }

    useEffect(() => {
        runQuery({})
    }, [])


    if(loading) return <div className="py-6"><Circle /></div>

    return (

        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                breadcrumb_1="Fees / Expenses"
                breadcrumb_2="View"
            />

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Payment Fees / Expenses</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <label className="form-control-label">Division</label>
                        <Input 
                            type="select"
                            value={division || 'false'}
                            onChange={(e) => setDivision(e.target.value)}
                        >
                            <option value="false">All Divisions (No Filter)</option>
                            {divisions.map((division, i) => (
                                <option key={i} value={division._id}>{division.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    
                    <Row>
                        <Col lg={6}>
                            <FormGroup>
                                <Input 
                                    type="date"
                                    onChange={(e) => setStart(e.target.value)}
                                    value={start}
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <Input 
                                    type="date"
                                    onChange={(e) => setEnd(e.target.value)}
                                    value={end}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>

                <CardFooter className="text-right">
                    <button className="btn btn-success" onClick={runQuery}>Get Results</button>
                </CardFooter>

            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Breakdown By Office</CardTitle>
                </CardHeader>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Office</th>
                                <th>Expenses</th>
                                <th>Fees</th>
                                <th className="text-right">Total</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data && data.length ? data.map((row, i) => (
                                <tr key={i}>
                                    <td>{getOfficeName(row.office)}</td>
                                    <td>{formatMoney(row.expense_amount)}</td>
                                    <td>{formatMoney(row.fee_amount)}</td>
                                    <td className="text-right">{formatMoney(row.total)}</td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No Results Found</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
            </Card>

        </Container>

    )

}


const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(PaymentProjections);
