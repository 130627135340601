import { architeckCall } from 'database';

import * as actionTypes from 'store/actions';
import store from 'store';

import {io} from 'sockets';
import * as socketEvents from 'sockets/events';
import { add, remove, update } from 'store/functions/_crud';

import { setCallCenter } from 'store/functions/call_center/call_center'

const dashboard = async (query) => {
    return new Promise (async resolve => {
    
        const { loaded } = await architeckCall({
            method: 'get',
            url: `/api/v1/load/dashboard${query}`
        })
    
        if(loaded.company) {
            store.dispatch({type: actionTypes.SET_COMPANY, payload: loaded.company});
    
            io.on(socketEvents.companies_updated, async(data) => {
                if (loaded.company.data && data.data._id === loaded.company.data._id) {                    
                    store.dispatch({type: actionTypes.SET_COMPANY, payload: data});
                }
            })
        }
    
        if(loaded.missing_docs) {
            store.dispatch({
                type: actionTypes.SET_MISSING_DOCS,
                payload: loaded.missing_docs
            });
    
            io.on(socketEvents.missing_docs_added, async (data) => {
                add(data.data, 'SET_MISSING_DOCS', ['missing_docs', 'missing_docs']);
            })
        
            io.on(socketEvents.missing_docs_deleted, async (data) => {
                remove(data.data._id, 'SET_MISSING_DOCS', ['missing_docs', 'missing_docs']);
            })
        
            io.on(socketEvents.missing_docs_updated, async (data) => {
                update(data.data, 'SET_MISSING_DOCS', ['missing_docs', 'missing_docs']);
            })
        }
    
        if(loaded.location_courts) {

            store.dispatch({
                type: actionTypes.SET_LOCATION_COURTS,
                payload: loaded.location_courts
            });
    
            io.on(socketEvents.location_courts_added, async (data) => {
                add(data.data, 'SET_LOCATION_COURTS', ['location_courts', 'location_courts']);
            })
        
            io.on(socketEvents.location_courts_deleted, async (data) => {
                remove(data.data._id, 'SET_LOCATION_COURTS', ['location_courts', 'location_courts']);
            })
        
            io.on(socketEvents.location_courts_updated, async (data) => {
                update(data.data, 'SET_LOCATION_COURTS', ['location_courts', 'location_courts']);
            })
        }
    
        if(loaded.users) {
            store.dispatch({
                type: actionTypes.SET_USERS,
                payload: loaded.users
            });
    
            io.on(socketEvents.users_added, async (data) => {
                add(data.data, 'SET_USERS', ['users', 'users']);
            })
        
            io.on(socketEvents.users_deleted, async (data) => {
                remove(data.data._id, 'SET_USERS', ['users', 'users']);
            })
        
            io.on(socketEvents.users_updated, async (data) => {
                update(data.data, 'SET_USERS', ['users', 'users']);
            })
        }
    
    
        if(loaded.workflows) {
            store.dispatch({
                type: actionTypes.SET_WORKFLOWS,
                payload: loaded.workflows
            });
            
            io.on(socketEvents.workflows_added, async (data) => {
                add(data.data, 'SET_WORKFLOWS', ['workflows', 'workflows']);
            })
        
            io.on(socketEvents.workflows_deleted, async (data) => {
                remove(data.data.deleted_workflow_id, 'SET_WORKFLOWS', ['workflows', 'workflows']);
            })
        
            io.on(socketEvents.workflows_updated, async (data) => {
                update(data.data, 'SET_WORKFLOWS', ['workflows', 'workflows']);
            })
        }
    
        if(loaded.case_templates) {
            store.dispatch({
                type: actionTypes.SET_CASE_TEMPLATES,
                payload: loaded.case_templates
            });
    
            io.on(socketEvents.case_templates_added, async (data) => {
                add(data.data, 'SET_CASE_TEMPLATES', ['case_templates', 'case_templates']);
            })
        
            io.on(socketEvents.case_templates_deleted, async (data) => {
                remove(data.data._id, 'SET_CASE_TEMPLATES', ['case_templates', 'case_templates']);
            })
        }
    
        if(loaded.case_alert_templates) {
            store.dispatch({
                type: actionTypes.SET_CASE_ALERT_TEMPLATES,
                payload: loaded.case_alert_templates
            });
    
            io.on(socketEvents.case_alert_templates_added, async (data) => {
                add(data.data, 'SET_CASE_ALERT_TEMPLATES', ['case_alert_templates', 'case_alert_templates']);
            })
        
            io.on(socketEvents.case_alert_templates_deleted, async (data) => {
                remove(data.data._id, 'SET_CASE_ALERT_TEMPLATES', ['case_alert_templates', 'case_alert_templates']);
            })
        }
    
        if(loaded.forms) {
            store.dispatch({
                type: actionTypes.SET_FORMS,
                payload: loaded.forms
            });
    
            io.on(socketEvents.forms_added, async (data) => {
                add(data.data, 'SET_FORMS', ['forms', 'forms']);
            })
        
            io.on(socketEvents.forms_deleted, async (data) => {
                remove(data.data._id, 'SET_FORMS', ['forms', 'forms']);
            })
        
            io.on(socketEvents.forms_updated, async (data) => {
                update(data.data, 'SET_FORMS', ['forms', 'forms']);
            })
        }
    
        if(loaded.lead_sources) {
            store.dispatch({
                type: actionTypes.SET_LEAD_SOURCES,
                payload: loaded.lead_sources
            });
    
            io.on(socketEvents.lead_sources_added, async (data) => {
                add(data.data, 'SET_LEAD_SOURCES', ['lead_sources', 'lead_sources']);
            })
    
            io.on(socketEvents.lead_sources_deleted, async (data) => {
                remove(data.data._id, 'SET_LEAD_SOURCES', ['lead_sources', 'lead_sources']);
            })
    
            io.on(socketEvents.lead_sources_updated, async (data) => {
                update(data.data, 'SET_LEAD_SOURCES', ['lead_sources', 'lead_sources']);
            })
        }
    
        if(loaded.location_hearings) {
            store.dispatch({
                type: actionTypes.SET_LOCATION_HEARINGS,
                payload: loaded.location_hearings
            });
    
            io.on(socketEvents.location_hearings_added, async (data) => {
                add(data.data, 'SET_LOCATION_HEARINGS', ['location_hearings', 'location_hearings']);
            })
    
            io.on(socketEvents.location_hearings_deleted, async (data) => {
                remove(data.data._id, 'SET_LOCATION_HEARINGS', ['location_hearings', 'location_hearings']);
            })
    
            io.on(socketEvents.location_hearings_updated, async (data) => {
                update(data.data, 'SET_LOCATION_HEARINGS', ['location_hearings', 'location_hearings']);
            })
        }
    
        if(loaded.template_appointments) {
            store.dispatch({
                type: actionTypes.SET_TEMPLATE_APPOINTMENTS,
                payload: loaded.template_appointments
            });
    
            io.on(socketEvents.template_appointments_added, async (data) => {
                add(data.data, 'SET_TEMPLATE_APPOINTMENTS', ['template_appointments', 'template_appointments']);
            })
        
            io.on(socketEvents.template_appointments_deleted, async (data) => {
                remove(data.data._id, 'SET_TEMPLATE_APPOINTMENTS', ['template_appointments', 'template_appointments']);
            })
        
            io.on(socketEvents.template_appointments_updated, async (data) => {
                update(data.data, 'SET_TEMPLATE_APPOINTMENTS', ['template_appointments', 'template_appointments']);
            })
        }
    
        if(loaded.offices) {

            store.dispatch({
                type: actionTypes.SET_OFFICES,
                payload: loaded.offices
            });
    
            io.on(socketEvents.offices_added, async (data) => {
                add(data.data, 'SET_OFFICES', ['offices', 'offices']);
            })
        
            io.on(socketEvents.offices_deleted, async (data) => {
                remove(data.data._id, 'SET_OFFICES', ['offices', 'offices']);
            })
        
            io.on(socketEvents.offices_updated, async (data) => {
                update(data.data, 'SET_OFFICES', ['offices', 'offices']);
            })    
        }
    
        if(loaded.tags) {
            store.dispatch({
                type: actionTypes.SET_TAGS,
                payload: loaded.tags
            });
    
            io.on(socketEvents.tags_added, async (data) => {
                add(data.data, 'SET_TAGS', ['tags', 'tags']);
            })
    
            io.on(socketEvents.tags_deleted, async (data) => {
                remove(data.data._id, 'SET_TAGS', ['tags', 'tags']);
            })
    
            io.on(socketEvents.tags_updated, async (data) => {
                update(data.data, 'SET_TAGS', ['tags', 'tags']);
            })
        }
    
        if(loaded.analytics_data_types) {

            store.dispatch({
                type: actionTypes.SET_ANALYTICS_DATA_TYPES,
                payload: loaded.analytics_data_types
            });
    
            io.on(socketEvents.analytics_data_types_added, async (data) => {
                add(data.data, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
            })
    
            io.on(socketEvents.analytics_data_types_deleted, async (data) => {
                remove(data.data._id, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
            })
    
            io.on(socketEvents.analytics_data_types_updated, async (data) => {
                update(data.data, 'SET_ANALYTICS_DATA_TYPES', ['analytics_data_types', 'analytics_data_types']);
            })
        }
    
        if(loaded.objection_numbers) {
            store.dispatch({
                type: actionTypes.SET_OBJECTION_NUMBERS,
                payload: loaded.objection_numbers
            });
    
            io.on(socketEvents.objection_numbers_added, async (data) => {
                add(data.data, 'SET_OBJECTION_NUMBERS', ['objection_numbers', 'objection_numbers']);
            })
        
            io.on(socketEvents.objection_numbers_deleted, async (data) => {
                remove(data.data._id, 'SET_OBJECTION_NUMBERS', ['objection_numbers', 'objection_numbers']);
            })
        
            io.on(socketEvents.objection_numbers_updated, async (data) => {
                update(data.data, 'SET_OBJECTION_NUMBERS', ['objection_numbers', 'objection_numbers']);
            })    
        }
    
        if(loaded.template_emails) {
            store.dispatch({
                type: actionTypes.SET_TEMPLATE_EMAILS,
                payload: loaded.template_emails
            });
    
            io.on(socketEvents.template_emails_added, async (data) => {
                add(data.data, 'SET_TEMPLATE_EMAILS', ['template_emails', 'template_emails']);
            })
        
            io.on(socketEvents.template_emails_deleted, async (data) => {
                remove(data.data._id, 'SET_TEMPLATE_EMAILS', ['template_emails', 'template_emails']);
            })
        
            io.on(socketEvents.template_emails_updated, async (data) => {
                update(data.data, 'SET_TEMPLATE_EMAILS', ['template_emails', 'template_emails']);
            })
        }
    
        if(loaded.template_texts) {
            store.dispatch({
                type: actionTypes.SET_TEMPLATE_TEXTS,
                payload: loaded.template_texts
            });
    
            io.on(socketEvents.template_texts_added, async (data) => {
                add(data.data, 'SET_TEMPLATE_TEXTS', ['template_texts', 'template_texts']);
            })
        
            io.on(socketEvents.template_texts_deleted, async (data) => {
                remove(data.data._id, 'SET_TEMPLATE_TEXTS', ['template_texts', 'template_texts']);
            })
        
            io.on(socketEvents.template_texts_updated, async (data) => {
                update(data.data, 'SET_TEMPLATE_TEXTS', ['template_texts', 'template_texts']);
            })
        }
    
        if(loaded.template_docs) {
            store.dispatch({
                type: actionTypes.SET_TEMPLATE_DOCS,
                payload: loaded.template_docs
            });
    
            io.on(socketEvents.template_docs_added, async (data) => {
                add(data.data, 'SET_TEMPLATE_DOCS', ['template_docs', 'template_docs']);
            })
        
            io.on(socketEvents.template_docs_deleted, async (data) => {
                remove(data.data._id, 'SET_TEMPLATE_DOCS', ['template_docs', 'template_docs']);
            })
        
            io.on(socketEvents.template_docs_updated, async (data) => {
                update(data.data, 'SET_TEMPLATE_DOCS', ['template_docs', 'template_docs']);
            })
        }
    
        if(loaded.template_word_docs) {
            store.dispatch({
                type: actionTypes.SET_TEMPLATE_WORD_DOCS,
                payload: loaded.template_word_docs
            });
    
            io.on(socketEvents.template_word_docs_added, async (data) => {
                add(data.data, 'SET_TEMPLATE_WORD_DOCS', ['template_word_docs', 'template_word_docs']);
            })
    
            io.on(socketEvents.template_word_docs_deleted, async (data) => {
                remove(data.data._id, 'SET_TEMPLATE_WORD_DOCS', ['template_word_docs', 'template_word_docs']);
            })
    
            io.on(socketEvents.template_word_docs_updated, async (data) => {
                update(data.data, 'SET_TEMPLATE_WORD_DOCS', ['template_word_docs', 'template_word_docs']);
            })
        }
    
        if(loaded.automations) {
            store.dispatch({
                type: actionTypes.SET_AUTOMATIONS,
                payload: loaded.automations
            });
    
            io.on(socketEvents.automations_added, async (data) => {
                add(data.data, 'SET_AUTOMATIONS', ['automations', 'automations']);
            })
        
            io.on(socketEvents.automations_deleted, async (data) => {
                remove(data.data._id, 'SET_AUTOMATIONS', ['automations', 'automations']);
            })
        
            io.on(socketEvents.automations_updated, async (data) => {
                update(data.data, 'SET_AUTOMATIONS', ['automations', 'automations']);
            })
        }
    
        if(loaded.user_statuses) {
            store.dispatch({
                type: actionTypes.SET_USER_STATUSES,
                payload: loaded.user_statuses
            });
                
            io.on(socketEvents.user_statuses_added, async (data) => {
                add(data.data, 'SET_USER_STATUSES', ['user_statuses', 'user_statuses']);
            })
    
            io.on(socketEvents.user_statuses_deleted, async (data) => {
                remove(data.data._id, 'SET_USER_STATUSES', ['user_statuses', 'user_statuses']);
            })
    
            io.on(socketEvents.user_statuses_updated, async (data) => {
                update(data.data, 'SET_USER_STATUSES', ['user_statuses', 'user_statuses']);
            })
        }
    
        if(loaded.groupings) {
            store.dispatch({
                type: actionTypes.SET_GROUPINGS,
                payload: loaded.groupings
            });
    
            io.on(socketEvents.groupings_added, async (data) => {
                add(data.data, 'SET_GROUPINGS', ['groupings', 'groupings']);
            })
        
            io.on(socketEvents.groupings_deleted, async (data) => {
                remove(data.data._id, 'SET_GROUPINGS', ['groupings', 'groupings']);
            })
        
            io.on(socketEvents.groupings_updated, async (data) => {
                update(data.data, 'SET_GROUPINGS', ['groupings', 'groupings']);
            })
        }

        if(loaded.divisions) {
            store.dispatch({
                type: actionTypes.SET_DIVISIONS,
                payload: loaded.divisions
            });
        }
        
        if(loaded.phone_numbers) {
            store.dispatch({
                type: actionTypes.SET_PHONE_NUMBERS,
                payload: loaded.phone_numbers
            });
        }

        if(loaded.questionnaires) {
            store.dispatch({
                type: actionTypes.SET_QUESTIONNAIRES,
                payload: loaded.questionnaires
            });
    
            io.on(socketEvents.questionnaires_added, async (data) => {
                add(data.data, 'SET_QUESTIONNAIRES', ['questionnaires', 'questionnaires']);
            })
        
            io.on(socketEvents.questionnaires_updated, async (data) => {
                update(data.data, 'SET_QUESTIONNAIRES', ['questionnaires', 'questionnaires']);
            })
        }

        if(loaded.email_senders) {
            store.dispatch({
                type: actionTypes.SET_EMAIL_SENDERS,
                payload: loaded.email_senders
            });
    
            io.on(socketEvents.email_senders_added, async (data) => {
                add(data.data, actionTypes.SET_EMAIL_SENDERS, ['email_senders', 'email_senders']);
            })
        
            io.on(socketEvents.email_senders_updated, async (data) => {
                update(data.data, actionTypes.SET_EMAIL_SENDERS, ['email_senders', 'email_senders']);
            })

            io.on(socketEvents.email_senders_deleted, async (data) => {
                remove(data.data._id, actionTypes.SET_EMAIL_SENDERS, ['email_senders', 'email_senders']);
            })
        }

        if(loaded.SETTINGS) {
            store.dispatch({
                type: actionTypes.SET_SETTINGS,
                payload: loaded.SETTINGS
            });
        }
    
        setCallCenter()

        return resolve();
    
    })
}

const moduleExports = {
    dashboard
}

export default moduleExports;