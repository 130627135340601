import apiWorker from '../apiWorker'

const collections = {

    search              : (data)                => apiWorker('post',    `/api/v1/billing/collections/search`, data),
    setCalled           : (collection_id)       => apiWorker('patch',   `/api/v1/billing/collections/setCalled/${collection_id}`, null, true),
    sendToCollections   : (collection_id)       => apiWorker('patch',   `/api/v1/billing/collections/sendToCollections/${collection_id}`),
    setNextCall         : (collection_id, data) => apiWorker('patch',   `/api/v1/billing/collections/setNextCall/${collection_id}`, data, true),
    findByContact       : (contact_id)          => apiWorker('get',     `/api/v1/billing/collections/contact/${contact_id}`, null, true),
    failedToday         : ()                    => apiWorker('get',     `/api/v1/billing/collections/failedToday`, null, true),
}

export default collections;