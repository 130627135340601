import apiWorker from '../apiWorker'

const objection_numbers = {

    create:   (data)                    =>  apiWorker('post',  `/api/v1/core/objection_numbers/create`, data),
    update:   (objection_number, data)  =>  apiWorker('patch', `/api/v1/core/objection_numbers/update/${objection_number}`, data),
    delete:   (objection_number)        =>  apiWorker('patch', `/api/v1/core/objection_numbers/delete/${objection_number}`),
    find:   (query)                     =>  apiWorker('get',    `/api/v1/core/objection_numbers/find${query}`),

}

export default objection_numbers;