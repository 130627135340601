
// findUnmovedStepsByAppointments

import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, FormGroup, Input } from 'reactstrap';
import renderName from 'utils/renderName';
import _analytics from '_functions/analytics';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle'
import A from 'components/markup/links/A'

import { formatPhone } from 'utils/text';
import { secondsToTime } from 'utils/time';

import keys from 'keys';

class StatsToday extends Component {


    state = {
        data: null,
        loading: false,
        loaded: false,
    };

    onQuery = async () => {

        this.setState({loading: true})

        const { user } = this.state;
        const data = await _analytics.users.callLogs(user);

        if(data.data) this.setState({data: data.data, loaded: true, loading: false})

    }

    getTime = (log) => {

        const  m = moment.unix(log.created_at)

        return (
            <span>
                <div>{m.format('MMM Do, YYYY')}</div>
                <div>{m.subtract('seconds', log.CallDuration ? log.CallDuration : log.duration ).format('h:mm') + ' - ' + m.add('seconds', log.CallDuration ? log.CallDuration : log.duration ).format('h:mm A') }</div>
            </span>
        )
    }



    render() {

        const { data, loaded, loading } = this.state
        const { users } = this.props;

        return (

            <>
            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Users"
                    breadcrumb_2="User Stats"
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">User Call Logs</CardTitle>
                    </CardHeader>

                    <CardHeader>

                        <FormGroup>
                            <label className="form-control-label">User</label>
                            <Input 
                                type="select"
                                value={this.state.user || ''}
                                onChange={(e) => this.setState({user: e.target.value})}
                            >
                                <option></option>
                                {users.map(user => (
                                    <option value={user._id} key={user._id}>{renderName(user)}</option>
                                ))}
                            </Input>
                        </FormGroup>

                    </CardHeader>

                    <CardHeader className="text-right">
                        <button className="btn btn-success" onClick={this.onQuery}>Get Stats</button>
                    </CardHeader>

                    {data && data.length >= 250 ? (
                        <Container fluid>
                            <div className="alert alert-info"><i className="fas fa-info-circle mr-2 " /> Showing the most recent 250 call logs.</div>
                        </Container>
                    ) : null}

                        {loading ? <Circle /> : data ? (
                            <div className="table-responsive">
                            <table className="table">
    
                                <thead>
                                    <tr>
                                        <th>Called By</th>
                                        <th>Contact</th>
                                        <th>Date</th>
                                        <th>Duration</th>
                                        <th className="text-right">Actions</th>
                                        <th className="text-right" style={{width: 300}}>Audio</th>
                                    </tr>
                                </thead>
    
                                <tbody>
                                {data && data.length ? data.map(log => (
                                    <tr key={log._id}>
                                        <td className="text-capitalize">
                                            <div>{renderName(log.user)}</div>
                                            <div>{log.user ? formatPhone(log.user.phone) : '-'}</div>
                                        </td>
                                        <td className="text-capitalize">
                                            <div><A href={`${keys.APP_URL}/dashboard/contacts/view/${log.contact._id}?nav=1`}><i className="fas fa-door-open mr-1 text-info-original " /> {renderName(log.contact)}</A></div>
                                            <div>{log.contact ? formatPhone(log.contact.phone) : '-'}</div>
                                        </td>
                                        <td>{this.getTime(log)}</td>
                                        <td>{secondsToTime(log.CallDuration)}</td>
                                        <td className="text-right">
                                            {log.deleted ? (
                                                '-'
                                            ) : (
                                                <a className="btn btn-success" href={log.RecordingUrl ? log.RecordingUrl : log.recording_url+ '.mp3?Download=true'}>Download</a>
                                            )}
                                        </td>
                                        <td className="text-right">
                                            {log.deleted ? (
                                                'Deleted'
                                            ) : (
                                                <audio style={{width: '100%'}} controls controlsList="nodownload" src={log.RecordingUrl ? log.RecordingUrl : log.recording_url+ '.mp3?Download=false'}>
                                                Your browser does not support the
                                                <code>audio</code> element.
                                            </audio>
                                            )}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>No call logs have been found for this contact.</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                )}
                                </tbody>
    
                            </table>
                        </div>
    
                        ) : loaded ?  (
                            <CardBody>
                                <p className="text-sm mb-0">This user has no recorded call logs</p>
                            </CardBody>
                        ) : null }


                </Card>

            </Container>
            </>

        )

    }

}




const mapStateToProps = state => {
    return {
        users: state.users.users
    };
};

export default connect(mapStateToProps, '')(StatsToday);