import Circle from 'components/markup/loading/Circle'
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";
import renderName from 'utils/renderName'

import _analytics from '_functions/analytics';
import { formatPhone } from 'utils/text'
import AvatarImage from 'components/functional/images/AvatarImage';

import keys from 'keys';

const { SearchBar } = Search;

class ShowEntries extends React.Component {

    state = {
        sizePerPage: 25
    }

    columns = [
        {
            dataField: "friendly_name",
            text: "Contact",
            filterValue: (cell,row) => row.contact ? renderName(row.contact) : 'DELETED CONTACT',
            formatter: (cell, row) => (
                row.contact ? (
                    <a className="text-capitalize" target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}>{renderName(row.contact)}</a>
                ) : (
                    'DELETED CONTACT'
                )
            )
        },
        {
            dataField: "email",
            text: "Contact Info",
            formatter: (cell, row) => {

                if(!row.contact) return '-';

                let markup = []

                if(row.contact.email) {
                    markup.push((
                        <div key={row.contact._id}><i className="text-muted fas fa-envelope mr-2" /> {row.contact.email}</div>
                    ))
                }

                if(row.contact.phone) {
                    markup.push((
                        <div 
                            key={row.contact._id + row.contact.created_at}
                        >
                            <i className="text-muted fas fa-phone mr-3" /> {formatPhone(row.contact.phone)}
                        </div>
                    ))
                }

                return markup.length ? markup : ''

            }
        },
        {
            dataField: "assigned_user",
            text: "Assigned User",
            formatter: (cell, row) => (
                row.contact && row.contact.assigned_user ? (
                <div className="text-capitalize">
                    <AvatarImage src={row.contact.assigned_user.avatar} className="mr-2" />
                    {renderName(row.contact.assigned_user)}
                </div>
                ) : (
                    '-'
                )
            )
        },
        {
            dataField: "status",
            text: "Status",
        },

        {
            dataField: "created_at",
            text: "Date Uploaded",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => (
                <div className="text-right">{moment.unix(row.created_at).format("M/D/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")}</div>
            )
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPage: this.state.sizePerPage,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    componentDidMount = async () => {

        const { start_date, end_date, office } = this.props;

        let query = `?start_date=${start_date}&end_date=${end_date}`

        if(office) {
            query += `&office=${office}`
        }
        
        const documents = await _analytics.lead_source_entries.findBySource(this.props.lead_source, query)
        if(documents.success) this.setState({documents: documents.data})

    }

    render() {

        const { documents } = this.state
        if(!documents) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">Leads</CardTitle>
                    </CardHeader>

                    {documents ? (

                        <ToolkitProvider
                            data={documents}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            <p className="text-sm mb-0">There are leads to view for this source</p>
                        </CardBody>

                    )}
                </Card>

            </Container>

        );
    }
}

export default ShowEntries

