import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';
import { formatMoney } from 'utils/currency'

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import { getUrlParameter } from 'utils/urls'

import keys from 'keys';

const QueryPayments = ({offices, workflows, divisions, tags}) => {

    const type = getUrlParameter('type') || 'created_at'

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    console.log(type)

    return (
        <QueryCreator 
            title="Case Reports"
            searchPlaceholder="Name or ID"
            actionComponent={(
                <FormGroup className="mb-0">
                    <Input
                        value={type}
                        onChange={e => window.location.href = `/analytics/cases/reports?type=${e.target.value}`}
                        style={{maxWidth: 300, marginLeft: 'auto'}}
                        type="select"
                    >
                        <option value="created_at">Sort By Creation Date</option>
                        <option value="filed_on">Sort By Filed Date</option>
                        <option value="sent_for_prep_on">Sort By Sent To Prep Date</option>
                        <option value="ch_13_post_filing_on">Sort By CH13 Post Filing Date</option>
                        <option value="ch_13_post_confirmation_on">Sort By CH13 Post Confirmation Date</option>
                    </Input>
                </FormGroup>
            )}
            runQuery={async (query, cb) => new Promise (async resolve => {

                if(!query.hasRun && type === 'filed_on') {
                    query.filter.filed_on = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'sent_for_prep_on') {
                    query.filter.sent_for_prep_on = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'ch_13_post_filing_on') {
                    query.filter.ch_13_post_filing_on = {
                        $gt: 0
                    }
                }
                if(!query.hasRun && type === 'ch_13_post_confirmation_on') {
                    query.filter.ch_13_post_confirmation_on = {
                        $gt: 0
                    }
                }
                query.sort = type;
                const result = await _analytics.query.cases(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: type, label: '', rangeTypes: [
                    {name: 'Creation Date', value: 'created_at'},
                    {name: 'Filed Date', value: 'filed_on'},
                    {name: 'Sent To Prep Date', value: 'sent_for_prep_on'},
                    {name: 'CH13 Post Filing Date', value: 'ch_13_post_filing_on'},
                    {name: 'CH13 Post Confirmation Date', value: 'ch_13_post_confirmation_on'},
                ] },
                {
                    type: 'ArchkSelectSimple', field: 'original_workflow', label: 'Workflow',
                    data: workflows.map(w => { return {value: w._id, text: w.type} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'division', label: 'Division',
                    data: divisions.map(i => { return {value: i._id, text: i.name} })
                },
                {
                    type: 'ArchkInArray', field: 'office', label: 'Offices', query: 'in',
                    data: offices.map(o => { return {value: o._id, text: o.name} })
                },
                {
                    type: 'ArchkInArray', field: 'tags', label: 'Tags', query: 'in',
                    data: tags.map(t => { return {value: t._id, text: t.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'chapter', label: 'Chapter',
                    data: [
                        {value: 'Chapter 7 Full', text: 'Chapter 7 Upfront'},
                        {value: 'Chapter 7 Emergency', text: 'Chapter 7 Zero Down'},
                        {value: 'Chapter 13', text: 'Chapter 13'},
                    ]
                },
                { type: 'ArchkGreaterThan0', field: 'filed_on', label: 'Filed', defaultValue: type === 'filed_on' ? 'yes' : '' },
                { type: 'ArchkGreaterThan0', field: 'sent_for_prep_on', label: 'Sent For Prep', defaultValue: type === 'sent_for_prep_on' ? 'yes' : '' },
                { type: 'ArchkGreaterThan0', field: 'ch_13_post_filing_on', label: 'CH13 Post Filing', defaultValue: type === 'ch_13_post_filing_on' ? 'yes' : '' },
                { type: 'ArchkGreaterThan0', field: 'ch_13_post_confirmation_on', label: 'CH13 Post Confirmation', defaultValue: type === 'ch_13_post_confirmation_on' ? 'yes' : '' },
                { type: 'ArchkNull', field: 'finished_by', label: 'Finished', },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'deleted', label: 'Deleted', },
                { type: 'ArchkNumber',  field: 'current_step_start', label: 'Step Start (UNIX Time)', },
                { type: 'ArchkNumber',  field: 'billing_paid', label: 'Billing Paid', },
                { type: 'ArchkNumber',  field: 'billing_total', label: 'Billing Total', },
               
            ]}
            columns={[
                {
                    dataField: "case name",
                    text: "Name",
                    formatter: (cell, row) => (
                        <div>
                            <A 
                                className="text-capitalize text-primary font-weight-bold" 
                                href={`${keys.APP_URL}/dashboard/cases/view/${row._id}`}
                            >
                                {row.name} 
                            </A>
                            {row.case_number ? (
                                <div className="text-capitalize">#: {row.case_number}</div>
                            ) : null}
                        </div>
                    ),
                },
                {
                    dataField: "contact",
                    text: "Contact",
                    formatter: (cell, row) => (
                        <div>
                            {row.contact ? (
                                 <A 
                                    className="text-capitalize text-primary font-weight-bold" 
                                    HREF={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`}
                                > 
                                    {renderName(row.contact)} 
                                </A>
                            ) : 'NOT FOUND'}
                            <div className="text-capitalize">Type: {row.type}</div>
                        </div>
                    ),
                },
                {
                    dataField: "current_step",
                    text: "Step",
                    formatter: (cell, row) => {

                        const step = row.finished_by ? 'FINISHED' : row.current_step ? row.current_step.name : 'No Assigned Step';
                        return (
                            <div>
                                <div className="font-weight-bold">{step}</div>
                                <div><b className="text-dark">Started:</b> {moment.unix(row.current_step_start).format('M/DD/YYYY - h:mm A')}</div>
                            </div>

                        )

                    }
                },
                {
                    dataField: "billing_total",
                    text: "Financial",
                    formatter: (cell, row) => (
                        row.billing_total ? (
                            <div>
                                <div>PAID: {formatMoney(row.billing_paid)}</div>
                                <div>TOTAL: {formatMoney(row.billing_total)}</div>
                            </div>
                        ) : '-'
                    )
                },
                {
                    dataField: type,
                    text: type.replace(/_/g, ' '),
                    headerStyle: {textAlign: 'right'},

                    formatter: (cell, row) => (
                        <div>
                            <div className="text-right">{moment.unix(row[type]).format('MMM Do, YYYY')}</div>
                        </div>
                    )
                },
                {
                    dataField: "_id",
                    text: "created",
                    headerStyle: {textAlign: 'right'},

                    formatter: (cell, row) => (
                        <div>
                            <div className="text-right">{moment.unix(row.created_at).format('MMM Do, YYYY')}</div>
                        </div>
                    )
                },

            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        workflows: state.workflows.workflows,
        divisions: state.divisions.divisions,
        tags: state.tags.tags,
    };
};

export default connect(mapStateToProps, '')(QueryPayments);