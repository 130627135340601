import apiWorker from '../apiWorker'

const lead_sources = {

    create:   (data)                    =>  apiWorker('post',  `/api/v1/analytics/lead_sources/create`, data),
    update:   (lead_source_id, data)    =>  apiWorker('patch', `/api/v1/analytics/lead_sources/update/${lead_source_id}`, data),
    search:   (data)                    =>  apiWorker('post',  `/api/v1/analytics/lead_sources/search`, data),
    find:     ()                        =>  apiWorker('get',   `/api/v1/analytics/lead_sources/find`),
    analyze:  (data)                    =>  apiWorker('post',  `/api/v1/analytics/lead_sources/analyze`, data),

    entry: {
        findByContact:  (contact_id)        =>  apiWorker('get', `/api/v1/analytics/lead_source_entries/contact/${contact_id}`),
        setCallStatus:  (contact_id, data)  =>  apiWorker('post', `/api/v1/analytics/lead_source_entries/set_call_status/${contact_id}`, data),
    }

}


export default lead_sources;