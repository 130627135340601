/*
Documentation

This file shows a "review style" component that shows the options added for a user when they are being created.

*/

import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { formatMoney } from 'utils/currency';
import { formatPhone } from 'utils/text'

class Review extends Component {

    getPrivilege = (p) => {

        switch (p) {

            case 'can_view_admin_dashboard'             : return 'Can view admin dashboard' ;
            
            case 'can_moderate_divisions'               : return 'Can moderate divisions' ;

            case 'can_moderate_archives'                : return 'Can moderate system archives' ;

            case 'can_moderate_analytics'               : return 'Can moderate and view all system analytics and reports' ;

            case 'can_moderate_appointments'            : return 'Can create update or delete appointments' ;
            case 'can_moderate_appointment_templates'   : return 'Can moderate appointment templates' ;

            case 'can_moderate_automations'             : return 'Can moderate and view all system automations' ;

            case 'can_moderate_case_templates'          : return 'Can moderate case templates' ;
            case 'can_moderate_case_alert_templates'    : return 'Can moderate case alert templates' ;

            case 'can_delete_cases'                     : return 'Can delete cases' ;
            case 'can_view_cases'                       : return 'Can view all cases' ;
            case 'can_update_cases'                     : return 'Can create and update cases' ;

            case 'can_moderate_company_settings'        : return 'Can update company settings' ;

            case 'can_delete_contacts'                  : return 'Can delete contacts' ;
            case 'can_update_contacts'                  : return 'Can update all contacts' ;

            case 'can_moderate_locations'                : return 'Can moderate locations' ;

            case 'can_moderate_forms'                   : return 'Can moderate appointment forms' ;

            case 'can_update_finances'                  : return 'Can update invoices and billables within this system' ;
            case 'can_view_finances'                    : return 'Can view financial records and invoices within this system' ;
            case 'can_adjust_trust'                     : return 'Can adjust trust values' ;
            case 'can_view_admin_finances'              : return 'Can view admin finances' ;

            case 'can_moderate_missing_docs'            : return 'Can update missing document types within the system' ;

            case 'can_moderate_objection_numbers'       : return 'Can update objection numbers' ;

            case 'can_moderate_staff'                   : return 'Can moderate other staff member' ;
            case 'can_screen_watch'                     : return 'Can view other staff members screens' ;

            case 'can_moderate_tags'                    : return 'Can moderate system tags and automations assigned to them' ;

            case 'can_moderate_offices'                 : return 'Can moderate and update office locations' ;

            case 'can_moderate_template_docs'           : return 'Has full access to update and create template documents' ;

            case 'can_moderate_users_statuses'          : return 'Has full access to moderate user statuses' ;

            case 'can_moderate_workflows'               : return 'Can moderate all workflows' ;

            case 'can_moderate_user_types'              : return 'Can moderate user types' ;

            case 'can_moderate_all_unfinished_tasks'    : return 'Can view all unfinished tasks in the system' ;
            case 'can_login_as_contact'                 : return 'Can login as a client and view their portal' ;

            case 'can_view_all_email_addresses'         : return 'Can see email address besides the company address and their own' ;

            case 'developer'                            : return 'Can access developer logs and data' ;

            case 'can_view_admin_communication'         : return 'Can view admin communication' ;
            case 'can_view_admin_call_center'           : return 'Can view that admin call center view' ;
            case 'calls_new_contacts'                   : return 'Can take calls by NEW contacts' ;
            case 'calls_business'                       : return 'Can take calls categorized as "business"';
            case 'calls_appointments'                   : return 'Can take calls by appointment' ;
            case 'calls_contacts'                       : return 'Can take calls by contacts' ;
            case 'calls_after_sent_for_prep'            : return 'Can take calls by contacts on or after prep' ;
            case 'can_call_on_mobile'                   : return 'Can create calls view a mobile device' ;
            
            case 'can_jump_steps'                       : return 'Can Jump steps' ;
            case 'can_get_random_texts'                       : return 'Can Get Assigned Random Texts' ;

            default:
                console.log('invalid privilege sent when reviewing create employee: ' + p)
                break;

        }

    }

    render() {

        const user = this.props.user;

        return (

            <div>

                {/* --------------------------------------------------------------------- */}
                <h3>General</h3>

                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Name: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.given_name} {user.family_name}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Email: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.email}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Phone: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{formatPhone(user.phone)}</p>
                    </Col>
                </Row>

                {/* --------------------------------------------------------------------- */}

                <hr />
                <h3>Address</h3>

                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Address Line 1: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.address_line_1}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Address Line 2:</p>
                    </Col>
                    <Col xs={9}>
                    <p className="text-sm mb-0">{user.address_line_2}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">City: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.city}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">State: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.state}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Postal Code: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.postal_code}</p>
                    </Col>
                </Row>
                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Country: </p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{user.country}</p>
                    </Col>
                </Row>

                {/* --------------------------------------------------------------------- */}

                <hr />
                <h3>Billing</h3>

                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Hourly Rate (Internal)</p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{formatMoney(user.hourly_rate / 100)}</p>
                    </Col>
                </Row>

                <Row className="mb-0">
                    <Col xs={3}>
                        <p className="text-sm mb-0">Billable Rate (External)</p>
                    </Col>
                    <Col xs={9}>
                        <p className="text-sm mb-0">{formatMoney(user.billable_rate / 100)}</p>
                    </Col>
                </Row>

                 {/* --------------------------------------------------------------------- */}

                <hr />
                <h3>Privileges</h3>

                {user.privileges && user.privileges.length ? user.privileges.map((p, i) => (
                    <p key={i} className="text-sm mb-0">
                        <i className="fas fa-check text-success mr-2" />
                        {this.getPrivilege(p)}
                    </p>
                )) : (
                    <p className="text-sm mb-0">NO PRIVILEGES ASSIGNED</p>
                )}

            </div>

        )

    }

}

Review.propTypes = {
    user: PropTypes.object.isRequired,
}

export default Review;
