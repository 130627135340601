import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import _collections from '_functions/collections';
import Circle from 'components/markup/loading/Circle';
import * as _ from 'underscore';

import ConfirmationModal from 'components/functional/modals/Confirmation.js'

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

import { formatMoney } from "utils/currency";

class Delinquents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            sizePerPage: 5,
            total_documents: 0,
            marked_for_collections: false,
            data: null,
            collectionToSend: false,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    queryTableData = async (searchText, setPage1) => {

        this.setState({refreshing: true});
        if(setPage1) this.setState({page: 1})

        const collections = await _collections.search({
            marked_for_collections: this.props.marked_for_collections,
            search: searchText,
            limit: this.state.sizePerPage,
            skip: (this.state.page - 1) * this.state.sizePerPage
        })

        this.setState({
            data: collections.data,
            total_documents: collections.total_documents,
            collection_total: collections.collection_total,
            canRun: false,
            refreshing: false,

        })

    }

    toggleMarkedForCollection = () => {
        this.setState({marked_for_collections: !this.props.marked_for_collections}, () => {
            this.queryTableData('', true)
        })
    }

    onTableChange = async (type, newState) => {

        const { searchText } = newState

        if(type === 'search') {

            this.queryTableDataThrottled(searchText, true)

        } else {

            this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
                this.queryTableDataThrottled(searchText)
            })

        }

    }

    sendToCollection = async (collection) => {
        toggleStandardLoader(true)
        const sent = await _collections.sendToCollections(collection._id);
        toggleStandardLoader(false)
        if(sent.success) {
            toggleAlertBS(false, 'Marked as sent to collections')
            this.queryTableData('', this.state.page)
        } else {
            toggleStandardLoader(true, `Something's not right, please try again.`)
        }

    }

    componentDidMount = async () => {

        const collections = await _collections.search({
            marked_for_collections: this.props.marked_for_collections,
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: collections.data, total_documents: collections.total_documents, collection_total: collections.collection_total})

    }


    render() {

        const { data, searchText, collectionToSend, collection_total } = this.state;

        return (
            <>

            <ConfirmationModal
                showModal={collectionToSend ? true : false}
                toggleModal={() => this.setState({collectionToSend: false})}
                title="Send To Collections"
                body={(
                    <span>This action should be used after a contact has been sent to collections. It will remove them from being called again in the delinquent dashboard.</span>
                )}
                onConfirmation={() => this.sendToCollection(collectionToSend)}
            />

            <Card className="card-color card-primary">

                <CardHeader>
                    <Row>
                        <Col lg={6}>
                            <CardTitle className="mb-0">All Delinquents</CardTitle>
                        </Col>
                        <Col lg={6} className="text-right">
                            <CardTitle className="mb-0">Total: {formatMoney(collection_total)}</CardTitle>
                        </Col>
                    </Row>
                    
                </CardHeader>

                {data ? (
                    data.length || searchText !== undefined ? (
                        <ToolkitProvider
                            data={data}
                            keyField="_id"
                            columns={this.props.columns}
                            search
                        >
                            {props => (
                                <div className="table-not-fixed table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={paginationFactory({
                                            totalSize: this.state.total_documents,
                                            page: this.state.page,
                                            sizePerPage: this.state.sizePerPage,
                                            alwaysShowAllBtns: true,
                                            showTotal: true,
                                            withFirstAndLast: true,
                                            sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                <div className="dataTables_length" id="datatable-basic_length">
                                                    <label>
                                                        Show{" "}
                                                        {
                                                        <select
                                                            value={currSizePerPage}
                                                            name="datatable-basic_length"
                                                            aria-controls="datatable-basic"
                                                            className="form-control form-control-sm"
                                                            onChange={e => onSizePerPageChange(e.target.value)}
                                                        >
                                                            <option value="10">10</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                        </select>
                                                        }{" "}
                                                        entries.
                                                    </label>
                                                </div>
                                            )
                                        })}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                        remote={{
                                            search: true,
                                            pagination: true,
                                            sort: false,
                                            cellEdit: false
                                        }}
                                        onTableChange={this.onTableChange}

                                    />
                                </div>
                            )}
                        </ToolkitProvider>
                    ): (
                        <CardBody>
                            <p className="text-sm mb-0">
                                <i className="fas fa-check mr-2 text-success font-weight-bold " />
                                There are no contacts to be called for payment or marked for collections.
                            </p>
                        </CardBody>
                    )
                ) : (
                    <Circle />
                )}

            </Card>

            </>
        );
    }
}

export default Delinquents
