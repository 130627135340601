import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';

class DevRouter extends React.Component {



    render() {



        return (
        <>

            <Helmet>
                <title>{`Developers Router`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid>

                <DashHeaderOpen
                     title={<span><i className="fas fa-code mr-2 text-success " /> Developers</span>}
                     breadcrumb_1="Router"
                    breadcrumb_2="All"
                />

                <Row>

                    <Col md={4}>
                        <Link to="/developers/logs">
                            <Card className="text-center">
                                <CardHeader>
                                    <CardTitle className="mb-0">System Logs</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <i className="text-success fas fa-code" style={{fontSize: '2rem'}} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>

                    <Col md={4}>
                        <Link to="/developers/requests">
                            <Card className="text-center">
                                <CardHeader>
                                    <CardTitle className="mb-0">Request Logs</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <i className="text-success fas fa-server" style={{fontSize: '2rem'}} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>

                    <Col md={4}>
                        <Link to="/developers/tickets">
                            <Card className="text-center">
                                <CardHeader>
                                    <CardTitle className="mb-0">Dev Tickets</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <i className="text-success fas fa-users" style={{fontSize: '2rem'}} />
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(DevRouter);
