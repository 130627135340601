/*
Documentation

This file creates a location_hearing

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import React from "react";

import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row, CardTitle, CardFooter } from "reactstrap";
import { Link } from 'react-router-dom'
import _location_hearings from '_functions/location_hearings'
import { toggleAlertBS } from 'store/functions/system/system'

const required_form_fields = [
    'name',
    'county',
]

class LocationHearings extends React.Component {

    state = {
        location_hearing: {},
        canSave: true,
    }

    onSave = async () => {

        let newState = Object.assign({}, this.state.location_hearing);
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ location_hearing: newState, canSave: false });

        if(!errors) {

            const created = await _location_hearings.create(newState)

            //if location_hearing was created successfully, clear location_hearing data and show success message for 2 seconds
            if(created.success) {

                this.setState({ location_hearing: {} });
                toggleAlertBS(false, 'Location Hearing Created Successfully.')

            }

        }

        this.setState({canSave: true})

    }

    render() {

        if(this.state.shouldRedirect) { return <Redirect to={this.state.shouldRedirect} /> }
        const location_hearing = this.state.location_hearing

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>Location Hearing Create</title>
                    <meta name="description" content="Cases Create" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Hearing Locations"
                    breadcrumb_2="Create"
                    actionComponent={(
                        <Link to="/core/locations/location_hearings" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>                    
                    )}
                />

                <Card  className="card-color card-success">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Create A Location Hearing</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <Row>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Name *"
                                />
                            </Col>

                            <Col lg="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="county"
                                    title="County *"
                                />
                            </Col>

                        </Row>

                        <StandardFormGroup
                            obj={location_hearing}
                            objName="location_hearing"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="email"
                            title="Email"
                        />

                        <StandardFormGroup
                            obj={location_hearing}
                            objName="location_hearing"
                            onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                            field="phone"
                            title="Phone"
                            type="tel"
                        />

                        <hr />

                        <Row>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_1"
                                    title="Address Line 1 "
                                />
                            </Col>
                            <Col md="6">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="address_line_2"
                                    title="Address Line 2 "
                                />
                            </Col>
                        </Row>

                        <Row>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="city"
                                    title="City"
                                />
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="state"
                                    title="State"
                                    type="select"
                                >
                                    <option value="">Select State</option>
                                    <States />
                                </StandardFormGroup>
                            </Col>

                            <Col lg="4">
                                <StandardFormGroup
                                    obj={location_hearing}
                                    objName="location_hearing"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="postal_code"
                                    title="Postal code "
                                />
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-right">
                        {this.state.createdSuccessfully ? (
                            <Button
                                color="success"
                            >
                            Successfully Created
                            </Button>
                        ) : (
                            <Button
                                color="success"
                                onClick={this.onSave}
                                disabled={this.state.canSave ? false : true}
                            >
                            Create Location Hearing
                            </Button>
                        )}
                    </CardFooter>

                </Card>

            </Container>
            </>
        );
    }
}

export default LocationHearings
