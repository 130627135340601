import { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { Modal, FormGroup, Input, CardBody } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import _v2_call_center from '_functions/v2_call_center';


const ModalAdd = ({showModal, toggleModal,  division, fetchData, lead_sources}) => {

    const [number, setNumber] = useState('')
    const [leadSource, setLeadSource] = useState(undefined)
    const [errors, setErrors] = useState([]);



    const onSave = useCallback(async (state) => {

        setErrors([])
        toggleStandardLoader(true);
        const data = await _v2_call_center.call_phone_numbers.create({
            number,
            division: division._id,
            lead_source: leadSource && leadSource !== 'false' ? leadSource : undefined
        })


        if(data.success) {
            toggleModal()
            fetchData()
            setNumber('')
            setLeadSource('')
        } else {
            setErrors(data.message)
        }

        toggleStandardLoader(false);

    }, [number, division._id, leadSource, toggleModal, fetchData])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Add Number - {division.name}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">

                <FormGroup>
                    <label className="form-control-label">Phone Number*</label>
                    <Input 
                        type="text"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        invalid={errors.includes('')}
                    />
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label">Lead Source*</label>
                    <Input 
                        type="select"
                        value={leadSource ? leadSource : 'false'}
                        onChange={(e) => setLeadSource(e.target.value)}
                        invalid={errors.includes('')}
                    >
                        <option value="false"></option>
                        {lead_sources.map(l => (
                            <option key={l._id} value={l._id}>{l.name}</option>
                        ))}
                    </Input>
                </FormGroup>

            </div>

            {errors.length ? (
                <CardBody>
                    <p className="text-sm mb-0 text-warning">Make sure to add a valid phone number that does not already exist.</p>
                </CardBody>
            ) : ''}

            <div className="modal-footer text-right">
                <button className="btn btn-success" onClick={onSave}>
                    <i className="fas fa-save mr-2" />
                    Save
                </button>
            </div>

        </Modal>

    )

}

ModalAdd.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources
    };
};

export default connect(mapStateToProps, '')(ModalAdd);

