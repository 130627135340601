/*
Documentation

*/

import React from "react";
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardFooter, FormGroup  } from "reactstrap";
import Circle from 'components/markup/loading/Circle'

import UpdateSourceModal from './UpdateSourceModal'
import { formatMoney } from 'utils/currency';

import _lead_sources from '_functions/lead_sources'
import OfficeSearchInput from 'components/system/migrated/OfficeSearchInput';
import DatePicker from 'react-datepicker';
import ShowEntries from './ShowEntries'

import moment from 'moment'

import { secondsToTime } from 'utils/time'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import { Link } from 'react-router-dom'

const TableRow = (props) => (
    <tr className={props.className ? props.className : ''}>
        <td>{props.title}</td>
        <td className="text-right">{props.value}</td>
    </tr>
)

const Table = (props) => (
    <div className="table-responsive">
        <table className="table bg-white border">

            <thead className="bg-secondary">
                <tr>
                    <th>{props.title}</th>
                    <th></th>
                </tr>
            </thead>
            
            <tbody>
                {props.children}
            </tbody>

        </table>
    </div>
)

class LeadSourcesView extends React.Component {

    state = {
        data          : null,
        lead_source   : null,
        showContacts  : true,
        query         : {},
    }

    fetchLeadSource = async () => {

        const data = await _lead_sources.analyze({
            lead_source: this.props.match.params.lead_source_id
        })

        if(data.success) this.setState({data: data.data})

    }

    createQuery = async () => {

        const {office, start, end} = this.state.query

        // do nothing if no filter is passed in
        if(!office && !start && !end) return

        this.setState({data: null, showContacts: false})

        // build the filter
        let filter = {}
        if(office)  filter.office = office._id
        if(start)   filter.created_at = {$gte: start}
        if(end)     filter.created_at = filter.created_at ? {...filter.created_at, $lte: end } : {$lte: end}

        const start_date = start;
        // const end_date = start_date && !end ? start_date + 86400 : end
        const end_date = end
        this.setState({start: start_date, end: end_date})

        // query the data
        const data = await _lead_sources.analyze({
            lead_source: this.props.match.params.lead_source_id,
            filter
        })

        // show the data
        if(data.success) this.setState({data: data.data, showContacts: true})

    }

    resetQuery = () => {

        this.setState({data: null, query: {}, start: null, end: null})
        this.fetchLeadSource()

    }
  
    componentDidMount = async () => this.fetchLeadSource()

    toggleModal = (lead_source) => this.setState({lead_source})

    render() {

        const { data, query, showContacts, start, end } = this.state;

        if(!data) return <div className="py-6"><Circle /></div>

        const { _id, name, amount_spent } = data.lead_source

        const { total_client_total, total_staff_cost, avg_client_total, avg_staff_cost, avg_staff_time, avg_time_till_hired, avg_time_till_contacted, avg_time_till_hire_later, avg_time_till_potential, avg_time_till_rejected_client, avg_time_till_rejected_us, total_leads, total_staff_time, total_hired, total_contacted, total_hire_later, total_potential, total_rejected_client, total_rejected_us, average_roi_per_lead, average_cost_per_client, profit, percent_not_contacted, percent_potential, percent_hired, percent_hired_later, percent_rejected_client, percent_rejected_us, cost_per_acquisition, status_consulted, status_first_call, payments_collection_percent, payments_collected, avg_appointments_till_hire, avg_calls_till_hire, payments_succeeded, payments_succeeded_percent, payments_failed,     payments_failed_percent, payments_refunded, payments_refunded_percent, payments_charged_back, payments_charged_back_percent, filed, not_filed, filed_percent, average_time_till_filed, avg_time_till_called  } = data.analysis

        const hasQuery = Object.keys(query).length
        
        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Lead Source`}</title>
                    <meta name="description" content="Contacts" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-headset mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Lead Sources"
                    breadcrumb_2={name}
                    actionComponent={(
                        <Link to="/analytics/lead_sources" className="btn btn-outline-warning"><i className="fas fa-arrow-left mr-2" /> Back</Link>
                    )}
                />

                <Card className="mx--4">

                    <CardHeader>
                        <CardTitle className="mb-0">Create Lead Source Query</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <Row>

                            <Col lg={4}>
                                <div className="date-picker-wrapper">
                                    <FormGroup>
                                        <h4>Query Start Date</h4>
                                        <DatePicker
                                            selected={query.dateStart ? query.dateStart : undefined}
                                            onChange={date => {
                                                this.setState({ 
                                                    query: {
                                                        ...Object.assign({}, query), 
                                                        start: parseInt( moment(date).startOf('day').format('X')),
                                                        dateStart: date
                                                    } 
                                                })
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <h4>Query End Date</h4>
                                    <DatePicker
                                        selected={query.dateEnd ? query.dateEnd : undefined}
                                        onChange={date => {
                                            this.setState({ 
                                                query: {
                                                    ...Object.assign({}, query), 
                                                    end: parseInt( moment(date).endOf('day').format('X')),
                                                    dateEnd: date
                                                } 
                                            })
                                        }}                                    
                                    />
                                </FormGroup>
                            </Col>

                            <Col lg={4}>
                                <FormGroup>
                                    <h4>Search By Office</h4>
                                    <OfficeSearchInput
                                        hideTitle={true}
                                        value={query.office}
                                        onSelect={(office) => this.setState({ query: {...Object.assign({}, query), office} } )}
                                    />
                                </FormGroup>
                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter className="text-righ">
                        {hasQuery ? (<button onClick={this.resetQuery} className="btn btn-warning">Clear Query</button>) : null}
                        <button onClick={this.createQuery} disabled={!hasQuery} className="btn btn-success">Run Query</button>
                    </CardFooter>

                </Card>

                <Card key={_id} className="bg-secondary my-4">

                    <CardHeader className="bg-dark">
                        <Row>
                            <Col md={6}>
                                <CardTitle className="mb-0" style={{color: '#eee'}}>Lead Source: <b className="text-white ml-2">{name}</b></CardTitle>
                            </Col>
                            <Col md={6} className="text-right">
                                <button onClick={() => this.toggleModal(data.lead_source)} className="btn btn-outline-success">Update Source</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <Card className="mb-0">
                        <CardBody className="text-center p-0">
                            <Row>

                                <Col lg={2} className="p-3 pt-4">
                                    <h4>Potential</h4>
                                    <h3 className="mb--3">{percent_potential}%</h3>
                                    <p className="text-sm text-muted">{total_potential} Total</p>
                                </Col>

                                <Col lg={2} className="p-3 pt-4">
                                    <h4>Hire Later</h4>
                                    <h3 className="mb--3">{percent_hired_later}%</h3>
                                    <p className="text-sm text-muted">{total_hire_later} Total</p>
                                </Col>

                                <Col lg={2} className="p-3 pt-4">
                                    <h4 className="text-success">Hired</h4>
                                    <h3 className="mb--3">{percent_hired}%</h3>
                                    <p className="text-sm text-muted">{total_hired} Total</p>
                                </Col>

                                <Col lg={2} style={{borderLeft: 'solid 2px #eee'}} className="p-3 pt-4">
                                    <h4 className="text-warning">Not Contacted</h4>
                                    <h3 className="mb--3">{percent_not_contacted}%</h3>
                                    <p className="text-sm text-muted">{total_leads - total_contacted} Total</p>
                                </Col>

                                <Col lg={2} className="p-3 pt-4">
                                    <h4 className="text-danger">Rejected Client</h4>
                                    <h3 className="mb--3">{percent_rejected_client}%</h3>
                                    <p className="text-sm text-muted">{total_rejected_client} Total</p>
                                </Col>

                                <Col lg={2} className="p-3 pt-4">
                                    <h4 className="text-danger">Rejected Us</h4>
                                    <h3 className="mb--3">{percent_rejected_us}%</h3>
                                    <p className="text-sm text-muted">{total_rejected_us} Total</p>
                                </Col>
                                
                            </Row>
                        </CardBody>
                    </Card>
                    
                    <Table title="Overview">
                        <TableRow 
                            title="Total Estimated Profit" 
                            value={(<b className={profit > 0 ? 'text-success font-weight-bold' : 'text-danger font-weight-bold'}>{formatMoney(profit)}</b>)} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Invoice Total" 
                            value={<b className="font-weight-bold text-success">{formatMoney(total_client_total)}</b>} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Collected Payments" 
                            value={<b className="font-weight-bold">{formatMoney(payments_collected)}</b>} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Collection Percent" 
                            value={<b className="font-weight-bold">{payments_collection_percent}%</b>} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Staff Cost"
                            value={<b className="font-weight-bold text-warning">{formatMoney(total_staff_cost)}</b>} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Advertising Cost" 
                            value={<b className="font-weight-bold text-warning">{amount_spent ? formatMoney(amount_spent) : 0}</b>} 
                        />
                        <TableRow 
                            className="bg-secondary" 
                            title="Cost Per Acquisition" 
                            value={<b className="font-weight-bold">{formatMoney(cost_per_acquisition)}</b>} 
                        />
                        <TableRow 
                            className="bg-secondary"
                            title="Average Cost Per Client" 
                            value={(<b className="text-warning font-weight-bold">{average_cost_per_client ? formatMoney(average_cost_per_client) : '-'}</b>)} 
                        />
                        <TableRow 
                            className={average_roi_per_lead > 0 ? 'bg-success text-white' : 'bg-danger text-white'}
                            title="Average ROI Per Lead" 
                            value={(<b className="font-weight-bold">{average_roi_per_lead ? formatMoney(average_roi_per_lead) : '-'}</b>)} 
                        />
                    </Table>
                    
                    <Row>
                        
                        <Col lg={6} className="pr-0">
                            <Table title="Averages Per Lead">
                                <TableRow title="Billables"                 value={`${formatMoney(avg_client_total)}`} />
                                <TableRow title="Staff Cost"                value={`${formatMoney(avg_staff_cost)}`} />
                                <TableRow title="Staff Time"                value={`${secondsToTime(avg_staff_time * 60)}`} />
                                <TableRow title="Time Till Hired"           value={`${secondsToTime(avg_time_till_hired)}`} />
                                <TableRow title="Time Till Contacted"       value={`${secondsToTime(avg_time_till_contacted)}`} />
                                <TableRow title="Time Till Hired Later"     value={`${secondsToTime(avg_time_till_hire_later)}`} />
                                <TableRow title="Time Till Potential"       value={`${secondsToTime(avg_time_till_potential)}`} />
                                <TableRow title="Time Till Rejected Client" value={`${secondsToTime(avg_time_till_rejected_client)}`} />
                                <TableRow title="Time Till Rejected Us"     value={`${secondsToTime(avg_time_till_rejected_us)}`} />
                            </Table>
                        </Col>

                        <Col lg={6} className="pl-0">
                            <Table title="Metric Totals">
                                <TableRow title="Leads"            value={`${total_leads}`} />
                                <TableRow title="Staff Cost"       value={`${formatMoney(total_staff_cost)}`} />
                                <TableRow title="Staff Time"       value={`${secondsToTime(total_staff_time * 60)}`} />
                                <TableRow title="Hired"            value={`${total_hired}`} />
                                <TableRow title="Contacted"        value={`${total_contacted}`} />
                                <TableRow title="Hire Later"       value={`${total_hire_later}`} />
                                <TableRow title="Potential"        value={`${total_potential}`} />
                                <TableRow title="Rejected Client"  value={`${total_rejected_client}`} />
                                <TableRow title="Rejected Us"      value={`${total_rejected_us}`} />
                            </Table>
                        </Col>

                    </Row>

                    <Table title="Consulted Status And Averages Till Hire">
                        <TableRow 
                            title="Had Full Consultation" 
                            value={<span>{(status_consulted / total_leads * 100).toFixed(2)}%</span>}
                        />
                        <TableRow 
                            title="Average Calls Till Hire" 
                            value={avg_calls_till_hire}
                        />
                        <TableRow 
                            title="Average Appointments Till Hire" 
                            value={avg_appointments_till_hire}
                        />
                    </Table>

                    <Table title="Filings">
                        <TableRow 
                            title="Filed" 
                            value={filed}
                        />
                        <TableRow 
                            title="Not Filed" 
                            value={not_filed}
                        />
                        <TableRow 
                            title="Percent Filed" 
                            value={filed_percent}
                        />
                        <TableRow 
                            title="Percent Filed Of Retained" 
                            value={((filed / total_hired) * 100).toFixed(2)}
                        />

                        <TableRow 
                            title="Average Time Till Filed" 
                            value={`${secondsToTime(average_time_till_filed)}`}
                        />
                    </Table>

                    <Table title="Payment Breakdown">
                        <TableRow 
                            title="Payments Succeeded" 
                            value={(
                                <span>
                                    <span className="mr-2">{payments_succeeded_percent}%</span>
                                    <span className="d-inline-block" style={{width: 75}}>{payments_succeeded}</span>
                                </span>
                            )}
                        />
                        <TableRow 
                            title="Payments Failed" 
                            value={(
                                <span>
                                    <span className="mr-2">{payments_failed_percent}%</span>
                                    <span className="d-inline-block" style={{width: 75}}>{payments_failed}</span>
                                </span>
                            )}
                        />
                        <TableRow 
                            title="Payments Refunded" 
                            value={(
                                <span>
                                    <span className="mr-2">{payments_refunded_percent}%</span>
                                    <span className="d-inline-block" style={{width: 75}}>{payments_refunded}</span>
                                </span>
                            )}
                        />
                        <TableRow 
                            title="Payments Charged Back" 
                            value={(
                                <span>
                                    <span className="mr-2">{payments_charged_back_percent}%</span>
                                    <span className="d-inline-block" style={{width: 75}}>{payments_charged_back}</span>
                                </span>
                            )}
                        />
                       
                    </Table>

                    <Table title="First Call Metrics">
                        <TableRow 
                            title="AVG Time Till Called / Connected" 
                            value={(
                                <span>
                                    <span className="mr-2">{secondsToTime(avg_time_till_called)}</span>
                                    <span className="d-inline-block" style={{width: 75}}></span>
                                </span>
                            )}
                        />
                        {Object.keys(status_first_call).map((key, i) => {

                            const value = status_first_call[key] ? status_first_call[key] : 0;

                            return (
                                <TableRow 
                                    key={i}
                                    title={<span className="text-capitalize">{key}</span>} 
                                    value={(
                                        <span>
                                            <span className="mr-2">{((value / total_leads * 100).toFixed(2))}%</span>
                                            <span className="d-inline-block" style={{width: 75}}>{value}</span>
                                        </span>
                                    )} 
                                />
                            )
                        })}

                    </Table>
                    

                </Card>

                <UpdateSourceModal 
                    showModal={this.state.lead_source}
                    toggleModal={() => this.toggleModal(null)}
                    onSuccess={this.fetchLeadSource}
                />

            </Container>

            {showContacts ? (
                <ShowEntries 
                    office={query.office ? query.office._id : null}
                    start_date={start}
                    end_date={end}
                    lead_source={this.props.match.params.lead_source_id}
                />
            ) : null }
            

            </>
        );
    }
}

export default LeadSourcesView
