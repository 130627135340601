import React from "react";
import { Container, Row, Col } from "reactstrap";

import Circle from 'components/markup/loading/Circle'

import CallQueue from './CallQueue';
import Overview from './Overview';
import Users from './Users';
import AdminAlerts from './AdminAlerts';

const CallCenterAdmin = ({data, startOfDay, setStartOfDay}) => {

    if(!data) return <Circle />

    return (

            <>

            <Container fluid>


                <Row>

                    <Col lg={9}>

                        <Overview 
                            data={data} 
                            startOfDay={startOfDay}
                            setStartOfDay={setStartOfDay}
                        />

                        <Row>
                            <Col lg={6} xl={6}>
                                <Users data={data} startOfDay={startOfDay} downloadMonth={true} />
                            </Col>

                            <Col lg={6} xl={6}>
                                <CallQueue data={data} />
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={3}>
                        <AdminAlerts />

                    </Col>

                </Row>

            </Container>

        </>
    )

}

export default CallCenterAdmin