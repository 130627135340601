/*
Documentation

This page shows a list of all contacts
can search by name, email, phone, or _id

*/

import { connect } from 'react-redux';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import A from 'components/markup/links/A.js';
import Circle from 'components/markup/loading/Circle';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container, FormGroup, Input, Row, Col } from "reactstrap";
import * as _ from 'underscore';
import renderName from 'utils/renderName';
import { secondsToTime } from 'utils/time';
import _analytics from '_functions/analytics';
import fetchAllWorkflowSteps from '_functions/workflow_steps/fetchAll';
import role_types from '_settings/role_types';

import { getUrlParameter } from 'utils/urls'


import ByStepText from './ByStepText'
import ByStepEmail from './ByStepEmail'

import keys from 'keys';

class AnalyticsCasesByStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModalText: false,
            showModalEmail: false,
            loaded: false,
            steps: [],
            workflow_step: '',
            error: false,
            searched: false,
            page: 1,
            sizePerPage: 25,
            total_documents: 0,
            data: null,
        }
       this.queryTableDataThrottled = _.throttle(this.queryTableData, 750)
    }

    toggleModalText = () => this.setState({showModalText: !this.state.showModalText})
    toggleModalEmail = () => this.setState({showModalEmail: !this.state.showModalEmail})

    findUser = (user_id) => {
        const foundUser = this.props.users.find(user => user_id === user._id);
        return foundUser ? foundUser : {}
    }

    getFilteredData = (data) => {

        const { role, user } = this.state
        if(role && role !== 'null' && user && user !== 'null' && data && data.length) {
            return data.filter(d => d.roles.find(r => r.role === role && r.user === user));
        }

        return null

    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <div>
                    <div><A href={`${keys.APP_URL}/dashboard/cases/view/${row._id}`} className="text-capitalize">{row.name}</A></div>
                    {row.contact ? <div><A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} className="text-capitalize">{renderName(row.contact)}</A></div> : '-'}
                </div>
            ),
        },
        {
            dataField: "current_step",
            text: "Current Step",
            formatter: (cell, row) => (
                row.current_step ? (
                    row.current_step.name
                ) : (
                    '-'
                )
            )
        },
        {
            dataField: "current_step_start",
            text: "Time On Step",
            formatter: (cell, row) => {
                return secondsToTime(Math.floor(new Date() / 1000) - row.current_step_start)
            }
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <A href={`${keys.APP_URL}/dashboard/cases/view/${row._id}`} className="text-capitalize">
                            <i className="fas fa-edit text-success mr-2" />
                        </A>
                    </div>
                )
            }
        },
        {
            dataField: "roles",
            text: "Roles",
            formatter: (cell, row) => (
                <div>
                     {row.roles && row.roles.length ? row.roles.map((role, i) => (
                         <span>{i > 0 ? ', ' : ''} {renderName(this.findUser(role.user))} {`(${role.role})`} </span>
                     )) : '-'}
                </div>
               
            )
        },
    ]

    onTableChange = async (type, newState) => {
        this.setState({ page: newState.page, sizePerPage: newState.sizePerPage }, () => {
            this.queryTableDataThrottled()
        })
    }

    queryTableData = async (setPage1) => {

        this.setState({error: false})
        const workflow_step = this.state.workflow_step;
        if(!workflow_step || workflow_step === 'null') return this.setState({error: 'A step category must be selected'})

        let page = this.state.page
        if(setPage1) {
            this.setState({page: 1})
            page = 1
        }

        const skip = (page - 1) * this.state.sizePerPage;

        const cases = await _analytics.cases.byStep({skip, limit: this.state.sizePerPage, workflow_step})
        if(cases.data) {
            this.setState({ data: cases.data, total_documents: cases.total_documents, searched: true })
        } else {
            this.setState({error: cases.message});
        }

    }

    componentDidMount = async () => {
        const steps = await fetchAllWorkflowSteps()
        if(steps) this.setState({steps, loaded: true})

        const step = getUrlParameter('step');

        if(step) {
            this.setState({workflow_step: step}, () => {
                this.queryTableDataThrottled(true)
            })
        }

    }

    render() {

        const { data, searched, error, loaded, steps, workflow_step, showModalText, showModalEmail, role, user, total_documents } = this.state;
        const { cardTitle } = this.props;

        const filteredData = this.getFilteredData(data)

        if(!loaded) return <Circle />
        if(!steps.length) return <div className="alert alert-warning">At least 1 workflow and workflow step must be created before viewing this page.</div>

        return (
            <>

            <ByStepText 
                showModal={showModalText}
                toggleModal={this.toggleModalText}
                data={data}
                workflow_step={workflow_step}
                total_documents={this.state.total_documents}
            />

            <ByStepEmail 
                showModal={showModalEmail}
                toggleModal={this.toggleModalEmail}
                data={data}
                workflow_step={workflow_step}
                total_documents={this.state.total_documents}
            />

            <Container fluid>

                <Helmet>
                    <title>{`Cases By Step`}</title>
                    <meta name="description" content="Cases By Step" />
                </Helmet>

                <DashHeaderOpen         
                    title={<span><i className="fas fa-chart-pie mr-2 text-success " />Analytics</span>}
                    breadcrumb_1="Cases"
                    breadcrumb_2="By Step"
                    actionComponent={(
                        data && data.length ? (
                            <div>
                            <button className="btn btn-warning" onClick={this.toggleModalText}>
                                Text
                            </button>
                            <button className="btn btn-info" onClick={this.toggleModalEmail}>
                                Email
                            </button>
                        </div>
                        ) : null
                    )}
                />
               
                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Filters</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <FormGroup >
                            <label className="form-control-label">Select Step</label>
                            <Input 
                                type="select"
                                value={workflow_step || ''}
                                onChange={(e) => this.setState({workflow_step: e.target.value})}
                            >
                                <option value="null"></option>
                                {steps.map((workflow, i) => (
                                    <React.Fragment key={workflow.workflow._id}>
                                    <option value="null" disabled>Workflow: {workflow.workflow.type}</option>
                                    {workflow.steps.map((s, ii) => (
                                        <option key={s._id} value={s._id}>{s.name}</option>
                                    ))}
                                    </React.Fragment>
                                ))}
                            </Input>
                        </FormGroup>

                        <Row>
                            <Col lg={6} className="align-self-center">
                                <FormGroup className="mb-0">
                                    <label className="form-control-label">Select Role</label>
                                    <Input 
                                        type="select"
                                        value={role}
                                        onChange={(e) => this.setState({role: e.target.value})}
                                    >
                                        <option value="null"></option>
                                        {role_types.map((role, i) => (
                                            <option value={role}>{role}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg={6} className="align-self-center">
                                <FormGroup className="mb-0">
                                    <label className="form-control-label">Select User</label>
                                    <Input 
                                        type="select"
                                        value={user}
                                        onChange={(e) => this.setState({user: e.target.value})}
                                    >
                                        <option value="null"></option>
                                        {this.props.users.map((user, i) => (
                                           <option value={user._id}>{renderName(user)}</option>
                                        ))}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        
                    </CardBody>

                    
                        {data && filteredData && data.length && filteredData.length  ?(
                            <CardFooter >
                                Percentage Of Cases: {((filteredData.length / data.length) * 100).toFixed(2)}%
                            </CardFooter>
                        ) : null}
                    <CardFooter className="text-right">
                        <button 
                            onClick={() => this.queryTableDataThrottled(true)} 
                            className="btn btn-success btn-block mt-3 mt-md-0"
                        >
                            Search
                        </button>
                    </CardFooter>

                    {error ? (
                        <CardFooter>
                            <p className="text-sm text-danger font-weight-bold mb-0">{error}</p>
                        </CardFooter>
                    ) : null}

                </Card>

                {searched ? (
                    <Card>

                        <CardHeader>
                            <CardTitle className="mb-0 float-left">
                                {cardTitle ? cardTitle : 'Cases By Step'}
                            </CardTitle>
                        </CardHeader>

                        {data ? (
                            <ToolkitProvider
                                data={data.length ? filteredData ? filteredData : data : []}
                                keyField="_id"
                                columns={this.columns}
                                search
                            >
                                {props => (
                                    <>

                                        <div className="table-not-fixed table-responsive table-vertical-align">
                                            <BootstrapTable
                                                pagination={paginationFactory({
                                                    totalSize: total_documents ? total_documents : 0,
                                                    page: this.state.page,
                                                    sizePerPage: this.state.sizePerPage,
                                                    alwaysShowAllBtns: true,
                                                    showTotal: true,
                                                    withFirstAndLast: true,
                                                    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
                                                        <div className="dataTables_length" id="datatable-basic_length">
                                                            <label>
                                                                Show{" "}
                                                                {
                                                                <select
                                                                    value={currSizePerPage}
                                                                    name="datatable-basic_length"
                                                                    aria-controls="datatable-basic"
                                                                    className="form-control form-control-sm"
                                                                    onChange={e => onSizePerPageChange(e.target.value)}
                                                                >
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                                }{" "}
                                                                entries.
                                                            </label>
                                                        </div>
                                                    )
                                                })}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                                remote={{
                                                    search: true,
                                                    pagination: true,
                                                    sort: false,
                                                    cellEdit: false
                                                }}
                                                onTableChange={this.onTableChange}
                                                rowClasses={(row, rowIndex) => {
                                                    if(row.blacklisted) {
                                                        return 'bg-secondary' 
                                                    }
                                                    return '';
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </ToolkitProvider>
                        ) : (
                        <Circle />
                        )}

                    </Card>
                ) : null}

            </Container>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(AnalyticsCasesByStep);

