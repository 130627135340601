/*
Documentation

This file renders the right hand side pop up drawer that renders inputs to update
a workflow step as well as assigned/update tasks on all associated steps

TODO: this should not un-toggle if any changes are not saved without consent

*/

import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, CardTitle, UncontrolledTooltip, Input, FormGroup } from 'reactstrap';

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';

import AssignAutomations from 'views/admin/pages/automations/exports/AssignAutomations'
import AssignTags from 'views/admin/pages/automations/exports/AssignTags'

import Tasks from './Tasks';
// import CallCenterOptions from './CallCenterOptions';
import CaseAlerts from './CaseAlerts';
import TemplateDoc from './TemplateDoc';
import RecommendedNextStep from './RecommendedNextStep';
import RequiresAppointment from './RequiresAppointment';

import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system'
import _workflow_steps from '_functions/workflow_steps'

const required_form_fields = [
    'name',
]

const day = 86400;

class StepDrawer extends Component {


    state = {
        step: {},
        automations: [],
        call_center_options: [],
        case_alerts: [],
    };

    onTemplateDocMarkerSelect = (template_doc_markers) => {

        const step = Object.assign({}, this.state.step)
        this.setState({step: {...step, template_doc_markers}})

    }

    onTemplateDocSelect = (template_doc) => new Promise (async resolve => {
    
        const step = Object.assign({}, this.state.step)
        this.setState({step: {...step, template_doc}}, resolve)
    
    })

    onRecommendedStepChange = (values) => {

        const step = Object.assign({}, this.state.step)
        this.setState({step: {...step, recommended_next_step: values ? values.value : null}})

    }

    onRequiredAppointmentChange = (values) => {

        const step = Object.assign({}, this.state.step)
        this.setState({step: {...step, requires_appointment: values ? values.value : null}})

    }

    toggleSidebarDrawer = () => {

        this.props.toggleSidebarDrawer();
        this.setState({step: {}, call_center_options: [], case_alerts: [] })

    }

    onOptionDeleted = (option) => {

        const { step } = this.state;

        if(step) {

            let call_center_options = step.call_center_options ? step.call_center_options : []
            call_center_options = call_center_options.filter(o => o.name !== option.name);

            this.setState({step: { ...Object.assign({}, step), call_center_options }})

        }

    }

    onOptionCreated = (option) => {

        const { step } = this.state;

        if(step) {

            let call_center_options = step.call_center_options ? step.call_center_options : []
            call_center_options.push(option)

            this.setState({step: { ...Object.assign({}, step), call_center_options }})

        }

    }

    onDeleteStep = async () => {

        const step = this.state.step;

        let action = await _workflow_steps.delete(step._id)
        if(action.success) this.props.onDeleteStep(action)

        this.toggleSidebarDrawer()

    }

    onSave = async () => {

        this.setState({canSave: false, error_message: true})
        //copy state
        let newState = Object.assign({}, this.state.step);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({ step: newState });

        if(!errors) {

            if(newState.increment_trust) {

                if(Number.isNaN(parseInt(newState.increment_trust))) {
                    return this.setState({step: { ...newState, increment_trustState: 'invalid' }})
                }

                if(newState.increment_trust < 0) {
                    return this.setState({step: { ...newState, increment_trustState: 'invalid' }})
                }

            } else {
                newState.increment_trust = 0
            }

            toggleStandardLoader(true);

            let set_client_status = undefined

            if(newState.set_client_status ) {
                set_client_status = newState.set_client_status
            }

            let action = await _workflow_steps.update(newState._id, {
                ...newState,
                set_client_status,
                is_commitment: newState.is_commitment === 'true' ? true : false,
                automations   : this.state.automations,
                tags          : this.state.tags,
                case_alerts   : this.state.case_alerts,
                tasks         : undefined
            })

            if(action.success) toggleAlertBS(false, 'Step Updated Successfully')

            this.props.onStepSave(action)
            toggleStandardLoader(false);

        }

        this.setState({canSave: true})

    }


    componentWillReceiveProps = async (nextProps) => {

        const currentStepId = this.state.step._id;
        const nextStepId = nextProps.stepToEdit ? nextProps.stepToEdit._id : null;

        if(nextStepId && nextStepId !== currentStepId) {
            this.setState({
                step: Object.assign({}, nextProps.stepToEdit),
                automations: nextProps.stepToEdit.automations,
                call_center_options: nextProps.stepToEdit.call_center_options,
                case_alerts: nextProps.stepToEdit.case_alerts,
            })
        }

    }

    render() {

        const { showDrawer, workflow, selectOptions, templateAppointmentOptions, templateDocOptions } = this.props
        const { step } = this.state

        return (

            <div className={showDrawer ? 'step-drawer show' : 'step-drawer'}>

                <div onClick={showDrawer ? this.toggleSidebarDrawer : null} className="backdrop"></div>

                <Card className="content z-depth-5" >

                    <CardHeader>
                        <Row>

                            <Col lg={6}>
                                <CardTitle className="mb-0">{step.name}</CardTitle>
                            </Col>

                            <Col lg={6} className="text-right">
                                <button className="btn btn-warning" onClick={this.toggleSidebarDrawer}><i className="fas fa-times mr-2" /> Close Editor</button>
                            </Col>

                        </Row>
                    </CardHeader>

                    <CardBody style={{flex: 'inherit'}}>

                        <Row>

                            <Col lg={8}>

                                <Tasks
                                    workflow={workflow}
                                    step={step}
                                    templateAppointmentOptions={templateAppointmentOptions}
                                    templateDocOptions={templateDocOptions}
                                />

                                <hr />

                                <RecommendedNextStep 
                                    step={step}
                                    selectOptions={selectOptions}
                                    onRecommendedStepChange={this.onRecommendedStepChange}
                                />

                                <hr />

                                <RequiresAppointment 
                                    step={step}
                                    templateAppointmentOptions={templateAppointmentOptions}
                                    onRequiredAppointmentChange={this.onRequiredAppointmentChange}
                                />

                                <hr />

                                <TemplateDoc 
                                    workflow={workflow}
                                    step={step}
                                    templateDocOptions={templateDocOptions}
                                    onTemplateDocSelect={this.onTemplateDocSelect}
                                    onTemplateDocMarkerSelect={this.onTemplateDocMarkerSelect}
                                />

                                <hr />
{/* 
                                <CallCenterOptions
                                    step={step}
                                    selectOptions={selectOptions}
                                    templateAppointmentOptions={templateAppointmentOptions}
                                    onOptionDeleted={this.onOptionDeleted}
                                    onOptionCreated={this.onOptionCreated}
                                /> */}

                            </Col>

                            <Col lg={4}  style={{borderLeft: 'solid 1px #eee'}}>

                                <StandardFormGroup
                                    inputId="modal-step-card-first-input"
                                    obj={step}
                                    objName='step'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="text"
                                    field="name"
                                    title="Step Name"
                                />

                                <StandardFormGroup
                                    obj={step}
                                    objName='step'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="category"
                                    title="Step Category"
                                >
                                    <option value="unassigned">Unassigned</option>
                                    <option value="pre-filing">Pre Filing</option>
                                    <option value="post-filing">Post Filing</option>
                                </StandardFormGroup>

                                <FormGroup>
                                    <label className="form-control-label">Is Verbal Commitment</label>
                                    <Input 
                                        type="select"
                                        value={step.is_commitment === true || step.is_commitment === 'true' ? 'true' : 'false'}
                                        onChange={(e) => this.setState({step: {...step, is_commitment: e.target.value}})}
                                    >
                                        <option value="false">no</option>
                                        <option value="true">Yes</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <label className="form-control-label">Monitoring Interval</label>
                                    <Input 
                                        type="select"
                                        value={step.monitoring_interval ? step.monitoring_interval.toString() : '0'}
                                        onChange={(e) => this.setState({step: {...step, monitoring_interval: parseInt(e.target.value)}})}
                                    >
                                        <option value="0">0</option>
                                        <option value={day * 1}>1 Day</option>
                                        <option value={day * 2}>2 Days</option>
                                        <option value={day * 3}>3 Days</option>
                                        <option value={day * 4}>4 Days</option>
                                        <option value={day * 5}>5 Days</option>
                                        <option value={day * 6}>6 Days</option>
                                        <option value={day * 7}>7 Days</option>
                                        <option value={day * 10}>10 Days</option>
                                        <option value={day * 14}>14 Days</option>
                                        <option value={day * 15}>15 Days</option>
                                        <option value={day * 21}>21 Days</option>
                                        <option value={day * 30}>30 Days</option>
                                        <option value={day * 45}>45 Days</option>
                                        <option value={day * 60}>60 Days</option>
                                        <option value={day * 75}>75 Days</option>
                                        <option value={day * 90}>90 Days</option>
                                        <option value={day * 120}>120 Days</option>
                                        <option value={day * 150}>150 Days</option>
                                        <option value={day * 180}>180 Days</option>
                                        <option value={day * 270}>270 Days</option>
                                        <option value={day * 365}>365 Days</option>
                                    </Input>
                                </FormGroup>



                                <StandardFormGroup
                                    obj={step}
                                    objName='step'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="set_client_status"
                                    title="Set Client Status"
                                >
                                    <option value="No Status">No Status</option>
                                    <option value="not contacted">Not Contacted</option>
                                    <option value="potential">Potential</option>
                                    <option value="hire">Hired</option>
                                    <option value="rejected client">rejected client</option>
                                    <option value="rejected us">Rejected Us</option>
                                    <option value="hire later">Hire Later</option>
                                </StandardFormGroup>

                                <StandardFormGroup
                                    obj={step}
                                    objName='step'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="number"
                                    field="increment_trust"
                                    title="Increment Case Trust Value"
                                />

                                <StandardFormGroup
                                    obj={step}
                                    objName='step'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="send_missing_docs_message"
                                    title="Send Missing Document Reminder When Moved To"
                                >
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </StandardFormGroup>

                                {step.is_first ? (
                                    <>
                                    <StandardFormGroup
                                        obj={step}
                                        objName='step'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v === 'true' ? true : false, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="start_on_step"
                                        title="Start All Cases On This Step"
                                    >
                                        <option value="false">False</option>
                                        <option value="true">True</option>
                                    </StandardFormGroup>
                                    <StandardFormGroup
                                        obj={step}
                                        objName='step'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v === 'true' ? true : false, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="select"
                                        field="hide_on_start"
                                        title="Hide Step On Start"
                                    >
                                        <option value="false">False</option>
                                        <option value="true">True</option>
                                    </StandardFormGroup>
                                    </>
                                ) : null }



                                <hr />

                                <AssignTags
                                    keyValue={step._id}
                                    title={(
                                        <span>
                                            <span id="tooltip-case-tags-info">Step Tags <i className="fas fa-info-circle text-info-original" /></span>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="bottom"
                                                target="tooltip-case-tags-info"
                                            >
                                                Any tags below will automatically be assigned to the associated contact when a case is moved to this step.
                                            </UncontrolledTooltip>

                                        </span>
                                    )}
                                    descriptionEmpty="No tags have been set for this step"
                                    onChange={(values) => this.setState({tags: values.tag_ids})}
                                    stateTags={step.tags ? [...step.tags] : []}
                                />

                                <hr />

                                <AssignAutomations
                                    keyValue={step._id}
                                    title={(
                                        <span>
                                            <span id="tooltip-case-automations-info">Step Automations <i className="fas fa-info-circle text-info-original" /></span>
                                            <UncontrolledTooltip
                                                delay={0}
                                                placement="bottom"
                                                target="tooltip-case-automations-info"
                                            >
                                                Any automations below will automatically start when a case is moved to this step.
                                            </UncontrolledTooltip>

                                        </span>
                                    )}
                                    descriptionEmpty="No Automations have been set for this step"
                                    onChange={(values) => this.setState({automations: values.automations_ids})}
                                    stateAutomations={step.automations ? [...step.automations] : []}
                                />

                                <hr />

                                <CaseAlerts
                                    onChange={(case_alerts) => this.setState({case_alerts})}
                                    step={step}
                                />

                            </Col>

                        </Row>

                    </CardBody>

                    <CardFooter>
                        <Row>

                            <Col xs={6}>
                                <button onClick={this.onDeleteStep} className="btn btn-outline-danger"><i className="fas fa-trash mr-2" /> Delete Step</button>
                            </Col>

                            <Col xs={6} className="text-right">
                                <button onClick={this.onSave} className="btn btn-success"><i className="fas fa-save mr-2" /> Save Step</button>
                            </Col>

                        </Row>
                    </CardFooter>

                </Card>

            </div>

        )

    }

}

StepDrawer.propTypes = {
    onStepSave                    : PropTypes.func.isRequired,
    toggleSidebarDrawer           : PropTypes.func.isRequired,
    onDeleteStep                  : PropTypes.func.isRequired,
    showDrawer                    : PropTypes.bool.isRequired,
    selectOptions                 : PropTypes.array.isRequired,
    templateAppointmentOptions    : PropTypes.array.isRequired,
    workflow                      : PropTypes.object.isRequired,
    stepToEdit                    : PropTypes.object,
    allSteps                      : PropTypes.array,
}

export default StepDrawer;
