import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import ConfirmationModal from 'components/functional/modals/Confirmation.js'
import Circle from 'components/markup/loading/Circle'
import Dots from 'components/markup/loading/Dots'

import moment from 'moment';
import _microsoft from '_functions/microsoft';
import { io } from 'sockets';
import * as socketEvents from 'sockets/events';

const CompanyEmailSync = () => {

    const [ state, setState ] = useState({});
    const [ showModal, setShowModal ] = useState(false);
    const [ error, setError ] = useState(false);

    const onStartSync = () => {
        _microsoft.sync();
        toggleAlertBS('info', <span>Sync has started<Dots /></span>);
    }

    const fetchInfo = async (data) => {

        const info = await _microsoft.info();
        if(info.data) {
            setState({info: info.data})
            if(data && data.data) toggleAlertBS(false, 'Email Sync Has Finished');
        } else {
            setError(true)
        }

    }

    // load company data into the state
    useEffect(() => {
        
        fetchInfo()
        
        io.on(socketEvents.microsoft_email_sync, fetchInfo)
        return () => io.off(socketEvents.microsoft_email_sync, fetchInfo)

    }, [])


    if(error) return <div className="alert alert-danger">An error occurred, please check back later.</div>;
    if(!state.info) return <Circle />;

    return (
        <Card>

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">Email Sync</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <button onClick={() => setShowModal(true)} className="btn btn-outline-warning"><i className="fas fa-sync mr-2 " /> Run Sync</button>
                    </Col>
                </Row>
            </CardHeader>

            {state.info.microsoft_subscriptions.length !== state.info.user_email_subscriptions.length ? (
                <CardBody>
                    <div className="alert alert-warning mb-0">Subscriptions found in graph not shown here. Check sync data via azure dashboard or try running the sync again.</div>
                </CardBody>
            ) : null}

            <div className="table-responsive">
                <table className="table">

                    <thead>
                        <tr>
                            <th>Email</th>  
                            <th className="text-center">Running</th>  
                            <th className="text-center">Confirmed Via Graph</th>  
                            <th className="text-right">Last Sync Date</th>  
                            <th className="text-right">Next SynC Date</th>  
                        </tr>
                    </thead>

                    <tbody>
                        {state.info.user_email_subscriptions.length ? state.info.user_email_subscriptions.map((sub, i) => {

                            const match = state.info.microsoft_subscriptions.find(s => s.id === sub.subscription_id)

                            return (
                                <tr key={i}>
                                    <td>{sub.email}</td>
                                    <td className="text-center">{sub.active ? 'yes' : 'no'}</td>
                                    <td className="text-center">{match ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger " />}</td>
                                    <td className="text-right">
                                        {sub.date ? moment.unix(sub.date).format('M/DD/YYYY - h:mm A') : '-'}
                                    </td>
                                    <td className="text-right">
                                        {sub.expires ? 
                                            moment.unix(sub.expires).format('M/DD/YYYY - h:mm A') :
                                            moment.unix(sub.date).add('1', 'days').format('M/DD/YYYY - h:mm A')
                                        }
                                    </td>
                                </tr>
                            )

                        }) : (
                            <tr>
                                <td>No Emails Set For Sync</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <ConfirmationModal
                showModal={showModal}
                toggleModal={() => setShowModal(false)}
                title="Confirm Email Sync"
                body={<span>This will re-sync the above emails with the Microsoft Graph and will result in a short amount of time incoming emails will not be logged (usually 5-10 seconds).</span>}
                onConfirmation={onStartSync}
            />

        </Card>
    )

}

export default CompanyEmailSync


