import Sidebar from 'components/layout/Sidebar';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';

import CompanyDetails from './Details';
import CompanyEmails from './Emails';
import CompanyEmailSync from './EmailSync';
import CompanyOutlook from './Outlook';
import CompanyStepMonitoring from './StepMonitoring';
import CompanyRecurringMonitoring from './RecurringMonitoring';
import CompanyCallCenter from './CallCenter';
import CompanyPayments from './Payments';
import CompanyApiKeys from './ApiKeys';

const CompanySettings = () => (
    <>
        <Helmet>
            <title>Company Settings</title>
            <meta name="description" content="Settings" />
        </Helmet>

        <Sidebar 
            header={(
                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Company"
                    breadcrumb_2="Settings"
                />
            )}
            tabs={[
                { title: 'Details',                 component: <CompanyDetails />               },
                { title: 'Outlook',                 component: <CompanyOutlook />               },
                { title: 'Email Sync',              component: <CompanyEmailSync />             },
                { title: 'Email Reports',           component: <CompanyEmails />                },
                { title: 'Step Monitoring',         component: <CompanyStepMonitoring />        },
                { title: 'Recurring Monitoring',    component: <CompanyRecurringMonitoring />   },
                { title: 'Call Center',             component: <CompanyCallCenter />            },
                { title: 'Payments',                component: <CompanyPayments />              },
                { title: 'API Keys',                component: <CompanyApiKeys />              },
            ]}
        />

    </>
)

export default CompanySettings
