import React, { useCallback } from 'react';
import keys from 'keys';

import { Card, CardHeader, CardTitle, Col, Row, CardFooter } from 'reactstrap';

import { formatMoney } from 'utils/currency';
import A from 'components/markup/links/A'

import { secondsToTime } from 'utils/time';

const Box = ({value, title, text, link, classFunction}) => (
    <Col xl={3} lg={6}>
        <A href={link}>
            <Card className={classFunction ? classFunction(value) : ''}>
                <CardHeader className={classFunction ? classFunction(value) : ''}>
                    <CardTitle className="mb-0">{title}</CardTitle>
                </CardHeader>
                <CardFooter className={classFunction ? classFunction(value) : ''}>
                    {text ? text : value}
                </CardFooter>
            </Card>
        </A>
    </Col>
)

const MasterReport = ({data, invoiceTotal}) => {

    const paymentsSucceeded = data.payments.aggregation ? data.payments.aggregation.total_payments_succeeded : 0;
    // const avgCallbackSeconds = data.queue_times.callback.count && data.queue_times.callback.time ? Math.floor(data.queue_times.callback.time / data.queue_times.callback.count) : null;
    // const avgOnHoldSeconds = data.queue_times.hold.count && data.queue_times.hold.time ? Math.floor(data.queue_times.hold.time / data.queue_times.hold.count) : null;
    const avgCallbackSeconds = 0;
    const avgOnHoldSeconds = 0
    
    const getQueueTimeToCallClasses = useCallback((val) => {
        // terrible after 2 minutes
        if(val >= 180) {
            return 'bg-gradient-danger text-white';
        // bad after 45 seconds
        } else if (val > 45) {
            return 'bg-gradient-warning text-white';
        }

        return 'bg-gradient-success text-white'
    }, [])
  
    const getQueueCardClasses = useCallback((num, type) => {
        if(type === 'new') {
            if(num >= 3)  return 'bg-gradient-danger text-white';
            if(num >= 1)  return 'bg-gradient-warning text-white';
        } else if (type === 'queue') {
            if(num >= 5)  return 'bg-gradient-danger text-white';
            if(num >= 1)  return 'bg-gradient-warning text-white';
        } else if (type === 'appointments') {
            if(num >= 20)  return 'bg-gradient-danger text-white';
            if(num >= 10)  return 'bg-gradient-warning text-white';
        } else if (type === 'predicted') {
            if(num >= 30)  return 'bg-gradient-danger text-white';
            if(num >= 15)  return 'bg-gradient-warning text-white';
        }

        return 'bg-gradient-success text-white'
    }, [])

    const getHiredClassNames = useCallback((num) => {
        if(num >= 10)  return 'bg-gradient-success text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
    
    const getSentToPrepClassNames = useCallback((num) => {
        if(num >= 7)  return 'bg-gradient-success text-white';
        if(num >= 3)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
   
    const getFiledClassNames = useCallback((num) => {
        if(num >= 5)  return 'bg-gradient-success text-white';
        if(num >= 2)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
   
    const getNewCasesClassNames = useCallback((num) => {
        if(num >= 30)  return 'bg-gradient-success text-white';
        if(num >= 15)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
  
    const getPaymentClassNames = useCallback((num) => {
        if(num >= 4000)  return 'bg-gradient-success text-white';
        if(num >= 1000)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])
   
    const getCollectionsClassNames = useCallback((num) => {
        if(num >= 15)  return 'bg-gradient-danger text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-success text-white'
    }, [])
    const getSendForCollectionsClassNames = useCallback((num) => {
        if(num >= 10)  return 'bg-gradient-danger text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-success text-white'
    }, [])
 
    const getSyncClassNames = useCallback((num) => {
        if(num >= 15)  return 'bg-gradient-danger text-white';
        if(num >= 5)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-success text-white'
    }, [])

    const getInvoiceClassNames = useCallback((num) => {
        if(num >= 15000)  return 'bg-gradient-success text-white';
        if(num >= 7500)  return 'bg-gradient-warning text-white';
        return 'bg-gradient-danger text-white'
    }, [])

    return (
        <>
            <h3>Daily Metrics</h3>
            <Row className="text-center">
                <Box 
                    title="New Leads"
                    link="/analytics/cases/reports"
                    classFunction={getNewCasesClassNames}
                    value={data.cases.cases.length}
                />
                <Box 
                    title="Hires"
                    link="/analytics/users/hired_logs"
                    classFunction={getHiredClassNames}
                    value={data.hired_logs.length}
                />
                <Box 
                    title="Sent To Prep"
                    link="/analytics/cases/reports?type=sent_for_prep_on"
                    classFunction={getSentToPrepClassNames}
                    value={data.cases.sentForPrep}
                />
                <Box 
                    title="Filed"
                    link="/analytics/cases/reports?type=filed_on"
                    classFunction={getFiledClassNames}
                    value={data.cases.filed}
                />
                    
                <Box 
                    title="New Payments"
                    link="/analytics/payments/reports"
                    classFunction={getPaymentClassNames}
                    value={paymentsSucceeded}
                    text={formatMoney(paymentsSucceeded)}
                    
                />

                <Box 
                    title="New Recurrings"
                    link="/analytics/payments/reports"
                    value={data.recurrings_added}
                    text={formatMoney(data.recurrings_added)}
                    
                />

                <Box 
                    title="New Invoices"
                    link={`${keys.APP_URL}/dashboard/collections`}
                    classFunction={getInvoiceClassNames}
                    value={invoiceTotal}
                    text={formatMoney(invoiceTotal)}
                /> 
                <Box 
                    title="Collection Calls"
                    link={`${keys.APP_URL}/dashboard/collections`}
                    classFunction={getCollectionsClassNames}
                    value={data.collections}
                /> 
            </Row>

            <h3>Call Center</h3>
            <Row className="text-center">
                <Box 
                    title="New"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.newContacts, 'new')}
                    value={data.queue.newContacts}
                /> 
                <Box 
                    title="Queue"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.business + data.queue.callQueue, 'queue')}
                    value={data.queue.business + data.queue.callQueue}
                /> 
                <Box 
                    title="Prep +"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.afterPrep, 'queue')}
                    value={data.queue.afterPrep}
                /> 
                {console.log(data.queue)}
                <Box 
                    title="Open"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.appointments, 'appointments')}
                    value={data.queue.appointments}
                /> 
                <Box 
                    title="Influx"
                    link="/communication/overview"
                    classFunction={() => getQueueCardClasses(data.queue.predicted, 'predicted')}
                    value={data.queue.predicted}
                    text={'+' + data.queue.predicted}
                /> 
               
                <Box 
                    title="Hold Time"
                    link="/communication/call_logs_incoming"
                    value={avgOnHoldSeconds}
                    classFunction={() => getQueueTimeToCallClasses(avgOnHoldSeconds)}
                    text={avgOnHoldSeconds ? secondsToTime(avgOnHoldSeconds) : '-'}

                /> 
                <Box 
                    title="CB Time"
                    link="/communication/call_logs_incoming"
                    value={avgCallbackSeconds}
                    classFunction={() => getQueueTimeToCallClasses(avgCallbackSeconds)}
                    text={avgCallbackSeconds ? secondsToTime(avgCallbackSeconds) : '-'}

                /> 

            </Row>

            <h3>Firm Wide Metrics</h3>
            <Row className="text-center">
                <Box 
                    title="Total Recurrings"
                    link="/analytics/payments/projections"
                    value={data.payment_projections.payments_over_180_days.total}
                    text={formatMoney(data.payment_projections.payments_over_180_days.total)}
                />
                <Box 
                    title="11 Month Total"
                    link="/analytics/payments/projections"
                    value={data.filteredInvoiceTotal.filed_cases_without_payment + data.filteredInvoiceTotal.invoices_with_payment}
                    text={formatMoney(data.filteredInvoiceTotal.filed_cases_without_payment + data.filteredInvoiceTotal.invoices_with_payment)}
                />
               
                <Box 
                    title="For Collection"
                    link={`/analytics/payments/delinquents`}
                    classFunction={getSendForCollectionsClassNames}
                    value={data.collections_12_months}
                /> 
                <Box 
                    title="Mistakes"
                    link="/analytics/cases/sync_case_appointment"
                    classFunction={getSyncClassNames}
                    value={data.case_appointment_sync.length}
                /> 
                <Box 
                    title="Overdue Steps"
                    link="/analytics/cases/sync_step_times"
                    classFunction={getSyncClassNames}
                    value={data.sync_case_step_times.length}
                /> 
            </Row>


            <h3>Pipeline</h3>
            <Row className="text-center">
                <Box 
                    title="ICs"
                    link="/analytics/cases/step_overview"
                    value={data.step_overview.initialContacts}
                /> 
                <Box 
                    title="Needs Time"
                    link="/analytics/cases/step_overview"
                    value={data.step_overview.needsMoreTime}
                /> 
                <Box 
                    title="Needs Docs"
                    link="/analytics/cases/step_overview"
                    value={data.step_overview.docsNeeded}
                /> 
                <Box 
                    title="Prep"
                    link="/analytics/cases/step_overview"
                    value={data.step_overview.petitionPrep}
                /> 
            </Row>

            <h3>CH 13</h3>
            <Row className="text-center">
                <Box 
                    title="Ch 13 Post File / M"
                    link="/analytics/cases/reports?type=ch_13_post_filing_on"
                    value={`${data.ch_13.monthly_post.added} / ${formatMoney(data.ch_13.monthly_post.receivables)}`}
                /> 
                <Box 
                    title="Ch 13 Post File"
                    link="/analytics/cases/reports?type=ch_13_post_filing_on"
                    value={`${data.ch_13.total_post.added} / ${formatMoney(data.ch_13.total_post.receivables)}`}
                /> 
                <Box 
                    title="Ch 13 Post Conf / M"
                    link="/analytics/cases/reports?type=ch_13_post_confirmation_on"
                    value={`${data.ch_13.monthly_confirmation.added} / ${formatMoney(data.ch_13.monthly_confirmation.receivables)}`}
                /> 
                <Box 
                    title="Ch 13 Post Conf"
                    link="/analytics/cases/reports?type=ch_13_post_confirmation_on"
                    value={`${data.ch_13.total_confirmation.added} / ${formatMoney(data.ch_13.total_confirmation.receivables)}`}
                /> 
            </Row>

        </>
    )

}

export default MasterReport;