/*
Documentation

this file renders all location_hearings within the system

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container, CardTitle } from "reactstrap";

const { SearchBar } = Search;

class LocationHearingsAll extends React.Component {

    state = {}

    columns = [
        {
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => (
                <Link to={`/core/locations/location_hearings/view/${row._id}`}>
                    {row.name}
                </Link>
            )
        },
        {
            dataField: "county",
            text: "County",
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "phone",
            text: "Phone",
        },
        {
            dataField: "_id",
            text: "Created At",

            formatter: (cell, row) => moment.unix(row.created_at).format("M/D/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")
        },
        {
            dataField: "x",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <Link to={`/core/locations/location_hearings/view/${row._id}`}><i className="fas fa-edit text-success mr-2"></i></Link>
                        <i onClick={() => this.setState({hearingToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: false,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    render() {

        const location_hearings = this.props.location_hearings;
        const hearingLength = this.props.location_hearings.location_hearings.length;
        const hearingToDelete = this.state.hearingToDelete;

        return (
            <>

            {hearingToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/location_hearings/delete/${hearingToDelete._id}`}
                    confirmBtnText="Delete Hearing Location"
                    title={`Delete ${hearingToDelete.name}`}
                    text="Deleting this hearing location will not affect appointments already assigned to it."
                    onClose={() => this.setState({hearingToDelete: null})}
                    onSuccess={() => {} }
                />
            )}

            <Container fluid>

                <Helmet>
                    <title>{`Hearing Locations (${hearingLength}) `}</title>
                    <meta name="description" content="Hearing Locations" />
                </Helmet>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Hearing Locations"
                    breadcrumb_2="All"
                    actionComponent={(
                        <Link  to="/core/locations/location_hearings/create" className="btn btn-success"><i className="fas fa-plus mr-2 " />Add New</Link>
                    )}
                />

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0">All Hearing Locations</CardTitle>
                    </CardHeader>

                    {location_hearings.total ? (

                        <ToolkitProvider
                            data={location_hearings.location_hearings}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                    <div className="dataTables_filter px-4 pb pt-3 pb-2" >
                                        <label>
                                            Search:
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder="Name, Email, Phone"
                                                {...props.searchProps}
                                            />
                                        </label>
                                    </div>

                                    <div className=" table-responsive table-vertical-align">
                                        <BootstrapTable
                                            pagination={this.pagination}
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            bordered={false}
                                        />
                                    </div>
                                </>
                            )}
                        </ToolkitProvider>

                    ) : (

                        <CardBody>

                            No hearing locations have been created yet.

                            <Link to="/core/locations/location_hearings/create">
                                <b className="text-warning"> Click here to create one.</b>
                            </Link>

                        </CardBody>

                    )}
                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        location_hearings: state.location_hearings
    };
};

export default connect(mapStateToProps, '')(LocationHearingsAll);

