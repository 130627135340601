import React from 'react';
import { Dropdown, Item, StandAlone } from './_components';

import * as privileges from '_functions/users/privileges'

const SidebarAnalytics = (props) => (
              
    <ul className="mb-0">

        <StandAlone route="/analytics/master" name="Master" icon="fas fa-chart-line" {...props} />

        <Dropdown route="/analytics/users" name="Users" icon="fas fa-users" {...props}>
            <Item  route="/analytics/users/hired_logs" name="Contacts Hired" {...props}/>
            <Item  route="/analytics/users/send_for_prep" name="Sent For Prep" {...props}/>
            <Item  route="/analytics/users/step_logs" name="Step Logs" {...props}/>
            <Item  route="/analytics/users/stats_today" name="Users Stats" {...props}/>
            <Item  route="/analytics/users/all_stats_today" name="All Stats" {...props}/>
            <Item  route="/analytics/users/events" name="Events" {...props}/>
            <Item  route="/analytics/users/call_logs" name="Call Logs" {...props}/>
            <Item  route="/analytics/calls_under_90/query" name="Calls Under 90" {...props}/>
        </Dropdown>

        <StandAlone route="/analytics/alerts" name="Alerts" icon="fas fa-exclamation-triangle" {...props} />

        <Dropdown route="/analytics/appointments" name="Appointments" icon="fas fa-calendar" {...props}>
            <Item  route="/analytics/appointments/breakdown" name="Breakdown" {...props}/>
            <Item  route="/analytics/appointments/by_step" name="By Step" {...props}/>
            <Item  route="/analytics/appointments/query" name="Reports" {...props}/>
            <Item  route="/analytics/appointments/finished/query" name="Finished" {...props}/>
        </Dropdown>

        <Dropdown route="/analytics/contacts" name="Contacts" icon="fas fa-users" {...props}>
            <Item  route="/analytics/contacts/graphs" name="Graphs" {...props}/>
            <Item  route="/analytics/contacts/reports" name="Reports" {...props}/>
            <Item  route="/analytics/contacts/hired" name="Hired" {...props}/>
            <Item  route="/analytics/contacts/data_types/update" name="Custom Data" {...props}/>
        </Dropdown>

        <Dropdown route="/documents/signed" name="Documents" icon="fas fa-file" {...props}>
            <Item  route="/analytics/documents/signed" name="Signed" {...props}/>
        </Dropdown>

        <StandAlone route="/analytics/contact_submissions/all" name="Submissions" icon="fab fa-wpforms" {...props} />

        <Dropdown route="/analytics/cases" name="Cases" icon="fas fa-gavel" {...props}>
            <Item  route="/analytics/cases/graphs" name="Graphs" {...props}/>
            <Item  route="/analytics/cases/reports" name="Reports" {...props}/>
            <Item  route="/analytics/cases/filing_times" name="Filing Times" {...props}/>
            <Item  route="/analytics/cases/sync_case_appointment" name="Appt Sync" {...props}/>
            <Item  route="/analytics/cases/sync_step_times" name="Step Time Sync" {...props}/>
            <Item  route="/analytics/cases/trust" name="Trust" {...props}/>
            <Item  route="/analytics/cases/by_step" name="By Step" {...props}/>
            <Item  route="/analytics/cases/step_overview" name="Step Overview" {...props}/>
            <Item  route="/analytics/cases/data_types/update" name="Custom Data" {...props}/>
        </Dropdown>

        {privileges.canViewAdminFinances() ? (
        <Dropdown route="/analytics/payments" name="Payments" icon="fas fa-dollar-sign" {...props}>
            <Item  route="/analytics/payments/daily_reports" name="Daily Reports" {...props}/>
            <Item  route="/analytics/payments/graphs" name="Graphs" {...props}/>
            <Item  route="/analytics/payments/reports" name="Reports" {...props}/>
            <Item  route="/analytics/payments/projections" name="Projections" {...props}/>
            <Item  route="/analytics/payments/overdue" name="Overdue" {...props}/>
            <Item  route="/analytics/payments/fees_expenses" name="Fees / Expenses" {...props}/>
            <Item  route="/analytics/payments/collections" name="Collections" {...props}/>
            <Item  route="/analytics/payments/delinquents" name="Delinquents" {...props}/>
        </Dropdown>
        ) : null}
       

        <StandAlone route="/analytics/lead_source_entries/query" name="Time To Contact" icon="fas fa-mobile" {...props} />
        <StandAlone route="/analytics/lead_sources" name="Lead Sources" icon="fas fa-project-diagram" {...props} />
        <StandAlone route="/analytics/groupings/all" name="Groupings" icon="fas fa-layer-group" {...props} />
        <StandAlone route="/analytics/data_types/router" name="Custom Data" icon="fas fa-server" {...props} />

    </ul>
)

export default SidebarAnalytics;
