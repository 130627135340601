import React, { memo } from 'react';

import { CardHeader, Col, Row } from 'reactstrap';
import { addSpaceBeforeCapital } from 'utils/text';

import A from 'components/markup/links/A'

const StepOverview = ({step_overview}) => (
    <CardHeader>
        <div >
            <Row>
                <Col xs={6}>
                    <A href="/analytics/cases/step_overview">
                        <i className="fas fa-door-open mr-2 text-success " />
                    </A>
                    <span>
                        Step Overview
                    </span>
                </Col>
            </Row>

            {Object.keys(step_overview).map((key, i) => (
                <Row key={i}>
                     <Col xs={9}>
                         <span className="pl-4 text-capitalize">- {addSpaceBeforeCapital(key)}</span>
                     </Col>
                     <Col xs={3} className="text-right">
                        {step_overview[key]}
                     </Col>
                 </Row>
            ))}
            
        </div>
    </CardHeader>
)

export default memo(StepOverview);