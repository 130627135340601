/*
Documentation

this file holds all of the types of privileges for this application

*/

export const can_view_admin_dashboard             = 'can_view_admin_dashboard';

export const can_moderate_archives                = 'can_moderate_archives';

export const can_moderate_analytics               = 'can_moderate_analytics';

export const can_moderate_appointments            = 'can_moderate_appointments';
export const can_moderate_appointment_templates   = 'can_moderate_appointment_templates';

export const can_moderate_automations             = 'can_moderate_automations';

export const can_moderate_case_templates          = 'can_moderate_case_templates';
export const can_moderate_case_alert_templates    = 'can_moderate_case_alert_templates';

export const can_delete_cases                     = 'can_delete_cases';
export const can_view_cases                       = 'can_view_cases';
export const can_update_cases                     = 'can_update_cases';

export const can_moderate_divisions               = 'can_moderate_divisions';

export const can_moderate_company_settings        = 'can_moderate_company_settings';

export const can_delete_contacts                  = 'can_delete_contacts';
export const can_update_contacts                  = 'can_update_contacts';

export const can_moderate_locations               = 'can_moderate_locations';

export const can_moderate_forms                   = 'can_moderate_forms';

export const can_update_finances                  = 'can_update_finances';
export const can_view_finances                    = 'can_view_finances';
export const can_adjust_trust                     = 'can_adjust_trust';
export const can_view_admin_finances              = 'can_view_admin_finances';

export const can_moderate_missing_docs            = 'can_moderate_missing_docs';

export const can_moderate_objection_numbers       = 'can_moderate_objection_numbers';

export const can_moderate_offices                 = 'can_moderate_offices';

export const can_screen_watch                     = 'can_screen_watch';

export const can_moderate_staff                   = 'can_moderate_staff';

export const can_moderate_tags                    = 'can_moderate_tags';

export const can_moderate_template_docs           = 'can_moderate_template_docs';

export const can_moderate_users_statuses          = 'can_moderate_users_statuses';

export const can_moderate_user_types              = 'can_moderate_user_types'; // should depreciated

export const can_moderate_workflows               = 'can_moderate_workflows';

export const can_moderate_all_unfinished_tasks    = 'can_moderate_all_unfinished_tasks';
export const can_login_as_contact                 = 'can_login_as_contact';

export const can_view_all_email_addresses         = 'can_view_all_email_addresses';

export const developer                            = 'developer';

export const can_view_admin_communication         = 'can_view_admin_communication';
export const can_view_admin_call_center           = 'can_view_admin_call_center';
export const calls_new_contacts                   = 'calls_new_contacts';
export const calls_business                       = 'calls_business';
export const calls_appointments                   = 'calls_appointments';
export const calls_contacts                       = 'calls_contacts';
export const can_call_on_mobile                   = 'can_call_on_mobile';
export const calls_after_sent_for_prep            = 'calls_after_sent_for_prep';

export const can_jump_steps                       = 'can_jump_steps';

export const can_get_random_texts                       = 'can_get_random_texts';
