import React, { memo } from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'
import { secondsToTime } from 'utils/time';

import keys from 'keys';

const CallLogsIncoming = ({call_logs_incoming}) => (
    <CardHeader>
        <Row >
            <Col xs={6}>
                <A href="/communication/call_logs_incoming">
                    <i className="fas fa-door-open mr-2 text-success " />
                </A>
                <span id="archk-call-logs-incoming-toggler" className="cursor-pointer">
                    Call Logs Incoming  <i className="fas fa-caret-down text-warning " />
                </span>
            </Col>
            <Col xs={6} className="text-right">
            {call_logs_incoming.length}
            </Col>
        </Row>
        <UncontrolledCollapse toggler="#archk-call-logs-incoming-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contact</th>
                                <th>Duration</th>
                                <th className="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {call_logs_incoming.map((log) => (
                                <tr key={log._id}>
                                    <td className="text-capitalize">
                                        {log.contact ? (
                                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${log.contact._id}`}>
                                                {renderName(log.contact)}
                                            </A>
                                        ) : 'contact not found'}
                                    </td>
                                    <td>
                                        {secondsToTime(log.duration)}
                                    </td>
                                    <td className="text-right">
                                        {moment.unix(log.start).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(CallLogsIncoming);