/*
Documentation

controls the global settings for automations
Two main features here are:

1) start/stop all automations
2) clear all automations


*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Badge, Card, CardBody, CardFooter, CardHeader, CardTitle, Container } from 'reactstrap';
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';
import _automations from '_functions/automations';
import _companies from '_functions/companies';

class AutomationsAll extends React.Component {

    state = {
        settings: null
    }

    // allows new automations to be created
    startAutomations = async () => {

        toggleStandardLoader(true)

        await _companies.update(this.props.company._id, { run_automations: true })

        toggleStandardLoader(false)

    }

    // stops new automations from being created
    stopAutomations = async () => {

        toggleStandardLoader(true)

        await _companies.update(this.props.company._id, { run_automations: false })

        toggleStandardLoader(false)

    }

    // clears all automations currently assigned
    onClear = async () => {

        let automationsCleared = 0
        const { automations } = this.props

        if(automations && automations.length) {

            automations.forEach(async automation => {

                toggleStandardLoader(true)

                await _automations.assigned.clear(automation._id)
                automationsCleared++

                if(automationsCleared === automations.length) {

                    toggleStandardLoader(false)
                    toggleAlertBS(false, 'All current automations have been cleared.')

                }

            })

        } else {

            toggleAlertBS(false, 'There are no automations to clear.')

        }

    }

    render() {

        const { company } = this.props

        return (
        <>

            <Helmet>
                <title>{`Contacts In Automation`}</title>
                <meta name="description" content="Tags" />
            </Helmet>

            <Container fluid >

                <DashHeaderOpen
                    title={<span><i className="fas fa-magic mr-2 text-success " /> Automations</span>}
                    breadcrumb_1="Settings"
                    breadcrumb_2="All"
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">
                        Automation Status: {' '}
                        {company.run_automations ? (
                            <Badge color="success" ><i className="fas fa-check mr-2" /> Running</Badge>
                        ) : (
                            <Badge color="danger"><i className="fas fa-exclamation-triangle" /> Stopped</Badge>
                        )}

                        </CardTitle>
                    </CardHeader>

                    <CardBody>

                        <p className="text-sm mb-0">Automation status determines whether or not new automations will be assigned to users. In general this should always be set to running however if a system error occurs or you wish to prevent any new automations from running you may disable its functionality here. In essence this is like pulling the plug on all new automations.</p>

                    </CardBody>

                    <CardFooter className="text-right">
                        {company.run_automations ? (
                            <button onClick={this.stopAutomations} style={{width: 200}} className="btn btn-danger" >
                                <i className="fas toggle-off mr-2" /><i className="fas fa-exclamation-triangle mr-2" /> Turn Off
                            </button>
                        ) : (
                            <button onClick={this.startAutomations} style={{width: 200}} className="btn btn-success">
                                <i className="fas fa-toggle-on mr-2" /> Turn On
                            </button>
                        )}
                    </CardFooter>

                </Card>

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">Clear Automations</CardTitle>
                    </CardHeader>

                    <CardBody>

                        <p className="text-sm mb-0">Should you wish to clear all automations you may do so here. This will not prevent new automations from being created however it will delete all automations currently in progress. This is usually used with the above option of turning off all automations should an error occur, infinite automation loops created, or you simply wish to start all automations from scratch</p>

                    </CardBody>

                    <CardFooter className="text-right">
                        <button onClick={this.onClear} style={{width: 200}} className="btn btn-danger"><i className="fas fa-exclamation-triangle  mr-2" /> Clear Automations</button>
                    </CardFooter>

                </Card>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        company: state.companies.company,
        automations: state.automations.automations,
    };
};

export default connect(mapStateToProps, '')(AutomationsAll);
