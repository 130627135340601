import React, { memo } from 'react';

import { CardHeader, Col, Row } from 'reactstrap';

import { formatMoney } from 'utils/currency';
import A from 'components/markup/links/A'

const PaymentProjections = ({payment_projections}) => (
    <CardHeader>
        <div >
            <Row>
                <Col xs={6}>
                    <A href="/analytics/payments/projections">
                        <i className="fas fa-door-open mr-2 text-success " />
                    </A>
                    <span>
                        Payment Projections
                    </span>
                </Col>
                <Col xs={6} className="text-right">
                    {formatMoney(payment_projections.total_to_collect)}
                </Col>
            </Row>

            <Row >
                <Col xs={9}>
                    <span className="pl-4 text-capitalize">- Next 15 Days</span>
                </Col>
                <Col xs={3} className="text-right">
                   {formatMoney(payment_projections.payments_15_days.total)}
                </Col>
            </Row>
            <Row >
                <Col xs={9}>
                    <span className="pl-4 text-capitalize">- Next 30 Days</span>
                </Col>
                <Col xs={3} className="text-right">
                   {formatMoney(payment_projections.payments_30_days.total)}
                </Col>
            </Row>
            <Row >
                <Col xs={9}>
                    <span className="pl-4 text-capitalize">- Next 90 Days</span>
                </Col>
                <Col xs={3} className="text-right">
                   {formatMoney(payment_projections.payments_90_days.total)}
                </Col>
            </Row>
            <Row >
                <Col xs={9}>
                    <span className="pl-4 text-capitalize">- Next 180 Days</span>
                </Col>
                <Col xs={3} className="text-right">
                   {formatMoney(payment_projections.payments_180_days.total)}
                </Col>
            </Row>
            <Row >
                <Col xs={9}>
                    <span className="pl-4 text-capitalize">- 180 Days +</span>
                </Col>
                <Col xs={3} className="text-right">
                   {formatMoney(payment_projections.payments_over_180_days.total)}
                </Col>
            </Row>
        </div>
    </CardHeader>
)

export default memo(PaymentProjections);