import React, { useState, useCallback, useEffect } from 'react';

import { Card, CardHeader, CardTitle, Col, Container, Row, FormGroup, CardFooter } from 'reactstrap';

import DatePicker from 'react-datepicker';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';

import _analytics from '_functions/analytics';

import moment from 'moment';

import Appointments from './Appointments';
import FinishedAppointments from './FinishedAppointments';
import Cases from './Cases';
import Contacts from './Contacts';
import HiredLogs from './HiredLogs';
import Invoices from './Invoices';
import SignedDocs from './SignedDocs';
import Payments from './Payments';
import PaymentProjections from './PaymentProjections';
import CallLogs from './CallLogs';
import CallLogsIncoming from './CallLogsIncoming';
import CaseAppointmentSync from './CaseAppointmentSync';
import LeadSources from './LeadSources';
import StepOverview from './StepOverview';
import OverviewBoxes from './OverviewBoxes';

import UserCallMetrics from './UserCallMetrics';

const MasterReport = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [start, setStart] = useState(moment().startOf('day').toDate());
    const [err, setErr] = useState(null);
    
    const [appointmentOutcomes, setAppointmentOutcomes] = useState({});
    const [caseTypes, setCaseTypes] = useState({});
    const [hiredTypes, setHiredTypes] = useState({});
    const [invoiceTypes, setInvoiceTypes] = useState({});
    const [callLogTypes, setCallLogTypes] = useState({});
    const [leadSourceTypes, setLeadSourceTypes] = useState({});
    
    const [invoiceTotal, setInvoiceTotal] = useState({});

    const onSetAppointmentOutcomes = useCallback((finished_appointments) => {
        let outcomes = {};
        finished_appointments.forEach(appointment => {
            if(outcomes[appointment.outcome]) {
                outcomes[appointment.outcome] += 1;
            } else {
                outcomes[appointment.outcome] = 1;
            }
        })

        setAppointmentOutcomes(outcomes)
    }, [])

    const onSetCaseTypes = useCallback((cases) => {
        let outcomes = {};
        cases.forEach(_case => {
            if(_case.workflow) {
                if(outcomes[_case.workflow.type]) {
                    outcomes[_case.workflow.type] += 1;
                } else {
                    outcomes[_case.workflow.type] = 1;
                }
            }
          
        })

        setCaseTypes(outcomes)
    }, [])

    const onSetHiredTypes = useCallback((logs) => {
        let outcomes = {};
        logs.forEach(_case => {
            if(_case.workflow) {
                if(outcomes[_case.workflow.type]) {
                    outcomes[_case.workflow.type] += 1;
                } else {
                    outcomes[_case.workflow.type] = 1;
                }
            }
          
        })

        setHiredTypes(outcomes)
    }, [])
   
    const onSetInvoiceTypes = useCallback((invoices) => {
        let outcomes = {};
        let _invoiceTotal = 0
        invoices.forEach(invoice => {
            _invoiceTotal += invoice.total;
            if(invoice.case && invoice.case.workflow) {
                if(outcomes[invoice.case.workflow.type]) {
                    outcomes[invoice.case.workflow.type] += 1;
                } else {
                    outcomes[invoice.case.workflow.type] = 1;
                }
            }
          
        })

        setInvoiceTypes(outcomes);
        setInvoiceTotal(_invoiceTotal)
    }, [])

    const onSetCallLogs = useCallback((callLogs) => {
        let outcomes = {};
        callLogs.forEach(callLog => {
            if(callLog.user) {
                if(outcomes[callLog.user]) {
                    outcomes[callLog.user] += 1;
                } else {
                    outcomes[callLog.user] = 1;
                }
            }
        })

        // sort by most call first
        let userArray = []
        let finalObj = {}
        Object.keys(outcomes).forEach((a, b) => {
            if(outcomes[a] < outcomes[b]) {
                userArray.push(a)
            } else {
                userArray.unshift(a)
            }
        })

        userArray.forEach((user) => {
            finalObj[user] = outcomes[user]
        })
        setCallLogTypes(finalObj);
    }, [])

    const onSetLeadSourceTypes = useCallback((lead_sources) => {
        let outcomes = {};
        lead_sources.forEach(source => {
            if(source.status) {
                if(outcomes[source.status]) {
                    outcomes[source.status] += 1;
                } else {
                    outcomes[source.status] = 1;
                }
            }
          
        })

        setLeadSourceTypes(outcomes)
    }, [])

    const fetchData = useCallback(async (hideLoad) => {

        const startDate = moment(start).format('X')
        const endDate = moment(start).add(1, 'days').format('X')

        if(!hideLoad) setLoading(true)
        const data = await _analytics.master.getReport(`?start=${startDate}&end=${endDate}`);
        if(!hideLoad) setLoading(false);

        if(data.data) {
            setData(data.data);
            onSetAppointmentOutcomes(data.data.finished_appointments)
            onSetCaseTypes(data.data.cases.cases)
            onSetHiredTypes(data.data.hired_logs)
            onSetInvoiceTypes(data.data.invoices)
            onSetCallLogs(data.data.call_logs)
            onSetLeadSourceTypes(data.data.lead_sources.documents)
        } else {
            setErr(true)
        }
    }, [start, onSetCallLogs, onSetAppointmentOutcomes, onSetCaseTypes, onSetHiredTypes, onSetInvoiceTypes, onSetLeadSourceTypes])


    useEffect(() => {
        fetchData()

        const refreshInterval = setInterval(() => {
            fetchData(true);
        }, 30 * 1000)
        return () => {
            clearInterval(refreshInterval)
        }
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!data) return <div className="py-6"><Circle /></div>
    if(loading) return <div className="py-6"><Circle /></div>

    return (

        <Container className="collections" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Master</span>}
                breadcrumb_1="Reports"
                breadcrumb_2="View"
            />

            <Row>

                <Col lg={5}>

                    <Card>
                        <CardHeader>
                            <FormGroup className="mb-0">
                                <label className="form-control-label">Select Day</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={start ? start : undefined}
                                        onChange={date => setStart(date)}
                                    />
                                </div>
                            </FormGroup>       
                        </CardHeader>
                    </Card>

                    <UserCallMetrics 
                        startOfDay={start}
                        metrics={data.metrics}
                    />

                    <HiredLogs 
                        data={data.hired_logs}
                        hiredTypes={hiredTypes}
                    />

                </Col>

                <Col lg={7}>

                    <OverviewBoxes 
                        data={data}
                        invoiceTotal={invoiceTotal}
                    />

                    <h3>Data</h3>

                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Data</CardTitle>
                        </CardHeader>


                        <LeadSources
                            breakdown={data.lead_sources.breakdown}
                            lead_sources={data.lead_sources.documents}
                            leadSourceTypes={leadSourceTypes}
                        />
                    
                        {/* <Appointments 
                            appointments={data.appointments}
                        /> */}

                        {/* <FinishedAppointments 
                            finished_appointments={data.finished_appointments}
                            appointmentOutcomes={appointmentOutcomes}
                        /> */}

                        <Cases 
                            caseTypes={caseTypes}
                            cases={data.cases.cases}
                        />

                        {/* <Contacts 
                            contacts={data.contacts}
                        /> */}
                        
                        <Invoices 
                            invoices={data.invoices}
                            invoiceTypes={invoiceTypes}
                            invoiceTotal={invoiceTotal}
                        />
                    
                        {/* <SignedDocs 
                            signed_docs={data.signed_docs}
                        /> */}
                    
                        <Payments 
                            aggregation={data.payments.aggregation}
                            payments={data.payments.documents}
                        />
                        <PaymentProjections 
                            payment_projections={data.payment_projections}
                        />

                        <CallLogs 
                            callLogTypes={callLogTypes}
                            call_logs={data.call_logs}
                        />

                        <CallLogsIncoming
                            call_logs_incoming={data.call_logs_incoming}
                        />

                        <CaseAppointmentSync
                            case_appointment_sync={data.case_appointment_sync}
                        />
                    
                        <StepOverview
                            step_overview={data.step_overview}
                        />


                    </Card>

                </Col>

            </Row>
            

        </Container>

    )

}

export default MasterReport;