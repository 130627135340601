import apiWorker from '../apiWorker'

const location_hearings = {

    create:   (data)            =>  apiWorker('post',  `/api/v1/core/location_hearings/create`, data),
    update:   (location_hearing, data)  =>  apiWorker('patch', `/api/v1/core/location_hearings/update/${location_hearing}`, data),
    find:     (query)           =>  apiWorker('get',   `/api/v1/core/location_hearings/find${query}`),

}

export default location_hearings;