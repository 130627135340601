import React, {memo} from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'

import keys from 'keys';

const Cases = ({cases, caseTypes}) => (
    <CardHeader>
        <div>
            <Row>
                <Col xs={6}>
                    <A href="/analytics/cases/reports">
                        <i className="fas fa-door-open mr-2 text-success " />
                    </A>
                    <span id="archk-cases-toggler" className="cursor-pointer">
                        Cases Created  <i className="fas fa-caret-down text-warning " />
                    </span>
                </Col>
                <Col xs={6} className="text-right">
                {cases.length}
                </Col>
            </Row>

            {Object.keys(caseTypes).map((key, i) => (
                <Row key={i}>
                    <Col xs={9}>
                        <span className="pl-4">- {key}</span>
                    </Col>
                    <Col xs={3} className="text-right">
                        ({
                        ((caseTypes[key] / cases.length) 
                        * 100).toFixed(2)}%)
                        {' '}
                        {caseTypes[key]}
                    </Col>
                </Row>
            ))}
        </div>

        <UncontrolledCollapse toggler="#archk-cases-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Case</th>
                                <th>Type</th>
                                <th>Contact</th>
                                <th className="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cases.map((_case) => (
                                <tr key={_case._id}>
                                    <td>{_case.name}</td>
                                    <td>{_case.workflow ? _case.workflow.type : '-'}</td>
                                    <td className="text-capitalize">
                                        {_case.contact ? (
                                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${_case.contact._id}`}>
                                                {renderName(_case.contact)}
                                            </A>
                                        ) : 'contact not found'}
                                    </td>
                                    <td className="text-right">
                                        {moment.unix(_case.created_at).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(Cases);