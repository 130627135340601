// api/v1/core/user_statuses


import apiWorker from '../apiWorker'

const user_statuses = {

    find:         (query)       => apiWorker('get',    `/api/v1/core/user_statuses/find${query}`),
    update:       (_id, data)   => apiWorker('patch',  `/api/v1/core/user_statuses/update/${_id}`, data),
    delete:       (_id)         => apiWorker('delete', `/api/v1/core/user_statuses/delete/${_id}`),
    create:       (data)        => apiWorker('post',   `/api/v1/core/user_statuses/create/`, data),

}

export default user_statuses;