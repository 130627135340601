import Chart from "chart.js";
import React, {Component} from 'react';
import {Pie} from "react-chartjs-2";
import {Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import createDefaultOptions from './_createDefaultOptions';
import PropTypes from 'prop-types';
import { formatNumber } from './_utils'

class ChartsPie extends Component {

    state ={
        noData: false
    }

    componentWillMount() {

        if (window.Chart) createDefaultOptions(Chart);

    }

    componentWillReceiveProps = (nextProps) => {

        try {

            const hasData = nextProps.data.datasets.find(d => d.data.find(v => v !== 0))
            this.setState({noData: !hasData})

        } catch(e) {

            this.setState({noData: true})

        }

    }

    render() {

        const { noData, hidden, fullscreen } = this.state

        const {data, title, className, styles } = this.props

        if (!data)  return null

        return (

            <div className={fullscreen ? 'fullscreen graph' : 'graph'}>

            {noData ? ( <div className="no-data"><div className="no-data-content">No Data To Show</div></div> ) : null}

            <Card className={className} style={styles ? styles : {}}>

                <CardHeader>
                    <Row>

                        <Col xs={8} className="align-self-center">
                            <h5 className="h3 mb-0">{title ? title : 'Pie Graph'}</h5>
                        </Col>

                        <Col xs={4} className="text-right align-self-center">
                            {!fullscreen ? (
                                <span className="toggler" onClick={() => this.setState({hidden: !hidden})}>
                                    {hidden ? ( <i className="fas fa-eye" /> ) : ( <i className="fas fa-eye-slash" />)}
                                </span>
                            ) : null}

                            <span className="toggler" onClick={() => this.setState({fullscreen: !fullscreen, hidden: false})}>
                                {fullscreen ? ( <i className="fas fa-compress" /> ) : ( <i className="fas fa-expand" />)}
                            </span>
                        </Col>

                    </Row>
                </CardHeader>

                <CardBody className={hidden ? 'hidden' : null}>
                    <div className="chart">

                        <Pie
                            data={data}
                            options={{
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            var dataset = data.datasets[tooltipItem.datasetIndex];
                                            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                            var total = meta.total;
                                            var currentValue = dataset.data[tooltipItem.index];
                                            var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                            return formatNumber(currentValue) + ' (' + percentage + '%)';
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[tooltipItem[0].index];
                                        }
                                    }
                                }
                            }}
                            className="chart-canvas"
                        />
                    </div>
                </CardBody>

            </Card>

        </div>

        )

    }

}

ChartsPie.propTypes = {
    title: PropTypes.string,
    data: PropTypes.object,
}

export default ChartsPie;
