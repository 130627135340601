/*
Documentation

This is the master component render the view to update a workflow
it gives found options:  1) roles 2) workflow 3) documents 4) settings

*/

import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Container } from "reactstrap";

import { getUrlParameter } from 'utils/urls'
import { Link, Redirect } from 'react-router-dom';

import Circle from 'components/markup/loading/Circle'

import _workflows from '_functions/workflows';
import Flow from './components/Flow';
import Router from './components/Router';
import Settings from './components/Settings';
import Roles from './components/Roles';
import MissingDocs from './components/MissingDocs';

class View extends Component {

    state = {
        workflow: null,
        newStep: {},
        canSave: true,
        view: 'router'
    };

    setView = (value) => {

        this.setState({view: value})
        //when value changes set the view as a url parameter
        window.history.pushState(null, null, `${window.location.pathname}?view=${value}`);
        window.scrollTo({top: 0, left: 0})

    }

    setViewOnPopState = () => {

        const value =  getUrlParameter('view')

        if(value) {
            this.setState({view: value})
            window.history.pushState(null, null, `${window.location.pathname}?view=${value}`);
            window.scrollTo({top: 0, left: 0})
        }

    }

    findWorkflow = async () => {

        const foundWorkflow = await _workflows.findById(this.props.match.params._id);

        if(foundWorkflow.success && foundWorkflow.data) {
            this.setState({workflow: foundWorkflow.data})
        } else {
            this.setState({shouldRedirect: '/core/workflows/all'})
        }

    }

    setViewAndListeners = () => {

        const view = getUrlParameter('view')

        if(view === 'router' || view === 'automations' || view === 'settings' || view === 'roles' || view === 'documents' ){
            this.setState({ view })
        }

        window.addEventListener('popstate', this.setViewOnPopState)

    }

    // this function is what updates the screen after any call to change the owrkflow
    componentWillReceiveProps = (nextProps) => {

        if(nextProps.workflows) {
            const foundWorkflow = nextProps.workflows.find(workflow => workflow._id === this.props.match.params._id)
            if(foundWorkflow) this.setState({workflow: foundWorkflow})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('popstate', this.setViewOnPopState)
    }

    componentDidMount = async () => {

        this.findWorkflow()
        this.setViewAndListeners()

    }

    render() {

        if(this.state.shouldRedirect) return <Redirect to={this.state.shouldRedirect} />

        const { workflow, view } = this.state;

        if(!workflow) return <div className="py-6"><Circle /></div>

        return (

        <>

        <Container className="page_workflows_view" fluid>

            <Helmet>
                <title>{`Workflow: ${workflow.type} `}</title>
                <meta name="description" content="Workflows" />
            </Helmet>

            <DashHeaderOpen
                title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                breadcrumb_1="Workflows"
                breadcrumb_2={workflow.type}
                actionComponent={
                    view !== 'router' ? (
                        <a id="workflow-view-back" href="/" onClick={(e) => {e.preventDefault(); this.setView('router')}} className="btn btn-outline-warning">
                            <i className="fas fa-arrow-left" /> Overview
                        </a>
                    ) : (
                        <Link  to="/core/workflows/all" className="btn btn-outline-warning"><i className="fas fa-arrow-left" /> Back</Link>
                    )

                }
            />

            {view === 'router' ? (
                <Router
                    setView={(value) => this.setView(value)}
                    workflow={workflow}
                />
            ) : null}

            {view === 'automations' ? (
                <Flow
                    setView={(value) => this.setView(value)}
                    workflow={workflow}
                />
            ) : null}

            {view === 'settings' ? (
                <Settings
                    workflow={workflow}
                />
            ) : null}

            {view === 'roles' ? (
                <Roles
                    workflow={workflow}
                />
            ) : null}

            {view === 'documents' ? (

                <MissingDocs
                    workflow={workflow}
                />

            ) : null}

        </Container>

        </>

        )

    }

}

const mapStateToProps = state => {
    return {
        workflows: state.workflows.workflows,
    };
};

export default connect(mapStateToProps, '')(View);
