import React, { memo } from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row, Badge } from 'reactstrap';
import { formatMoney } from 'utils/currency';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'

import keys from 'keys';

const getPaymentBadge = (status) => {
    if(status === 'succeeded') return   <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="success">Succeeded</Badge>
    if(status === 'failed') return      <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Failed</Badge>
    if(status === 'refunded') return    <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Refunded</Badge>
    if(status === 'chargeback') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="danger">Charged Back</Badge>
    if(status === 'voided') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="danger">Voided</Badge>
    if(status === 'authorized') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Authorized</Badge>
}

const Payments = ({aggregation, payments}) => (
    <CardHeader>
        <div>
            <Row>
                <Col xs={6}>
                    <A href="/analytics/payments/reports">
                        <i className="fas fa-door-open mr-2 text-success " />
                    </A>
                    <span id="archk-payments-toggler" className="cursor-pointer">Payments <i className="fas fa-caret-down text-warning " /></span>
                </Col>
                <Col xs={6} className="text-right">
                    {aggregation ? aggregation.total : '-'}
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Total Payments</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments) : '-'}
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Succeeded</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_succeeded) : '-'}
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Failed</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_failed) : '-'}
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Percent Succeeded</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? ((aggregation.total_payments_succeeded / aggregation.total_payments) * 100).toFixed(2) : '-'}%
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Percent Failed</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? ((aggregation.total_payments_failed / aggregation.total_payments) * 100).toFixed(2) : '-'}%
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Standard</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_standard) : '-'} 
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Trust</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_trust) : '-'} 
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Authorized</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_authorized) : '-'} 
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Refunded</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_refunded) : '-'} 
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Voided</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_voided) : '-'} 
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <span className="pl-4">- Amount Charged Back</span>
                </Col>
                <Col xs={3} className="text-right">
                    {aggregation ? formatMoney(aggregation.total_payments_chargeback) : '-'} 
                </Col>
            </Row>
        </div>
        
        <UncontrolledCollapse toggler="#archk-payments-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contact</th>
                                <th>Case</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Status</th>
                                <th className="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.map((payment) => (
                                <tr key={payment._id}>
                                    <td className="text-capitalize">
                                        {payment.contact ? (
                                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${payment.contact._id}`}>
                                                {renderName(payment.contact)}
                                            </A>
                                        ) : 'contact not found'}
                                    </td>
                                    <td className="text-capitalize">
                                        {payment.case ? (
                                            <A href={`${keys.APP_URL}/dashboard/cases/view/${payment.case._id}`}>
                                                {payment.case.name}
                                            </A>
                                        ) : 'case not found'}
                                    </td>
                                    <td className="text-right">{formatMoney(payment.amount)}</td>
                                    <td className="text-right">{getPaymentBadge(payment.status)}</td>

                                    <td className="text-right">
                                        {moment.unix(payment.created_at).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(Payments);