import { connect } from 'react-redux';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, FormGroup, CardFooter, Input } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import _analytics from '_functions/analytics';

const Collections = ({divisions}) => {

    const [chapter, setChapter] = useState('false');
    const [division, setDivision] = useState(null);
    const [data, setData] = useState(null);
    const [start, setStart] = useState();
    const [caseFiled, setCaseFiled] = useState('false');
    const [end, setEnd] = useState();
    const [err, setErr] = useState(null);

    const fetchData = useCallback(async () => {

        let startDate = parseInt(moment().startOf('month').format('X'));
        let endDate = Math.floor(new Date() / 1000)

        if(start) startDate = parseInt(moment(start).format('X'))
        if(end) endDate = parseInt(moment(end).format('X'))

        const data = await _analytics.payments.collections({
            start: startDate,
            end: endDate,
            chapter: chapter && chapter !== 'false' ? chapter : undefined,
            division: division && division !== 'false' ? division : undefined,
            case_filed: caseFiled === 'yes' ? true : caseFiled === 'no' ? false : undefined
        });
        if(data.data) {
            setData(data.data);
        } else {
            setErr(true)
        }
    }, [start, end, chapter, division, caseFiled])

    const averageCollection = useCallback((offices) => {

        let succeeded = 0;
        let total = 0;
        let percent = 0

        if(!offices || !offices.length) return { succeeded, total, percent }

        offices.forEach(office => {
            succeeded += office.data.total_payments_succeeded;
            total += office.data.total_payments;
        })

        percent = (succeeded / total) * 100;
        percent = percent.toFixed(2);

        return { succeeded, total, percent }

    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    if(err) return (
        <div className="alert alert-danger">Something's not right, please try again.</div>
    )

    if(!data) return <div className="py-6"><Circle /></div>

    const collection = averageCollection(data.offices);

    return (

        <Container className="collections" fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                breadcrumb_1="Collections"
                breadcrumb_2="View"
            />

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Select Dates</CardTitle>
                </CardHeader>

                <CardBody>
                    <Row className="mb-0">
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Select Start</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={start ? start : undefined}
                                        onChange={date => setStart(date)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg={6}>
                            <FormGroup>
                                <label className="form-control-label">Select End</label>
                                <div className="date-picker-wrapper">
                                    <DatePicker
                                        selected={end ? end : undefined}
                                        onChange={date => setEnd(date)}
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <label className="form-control-label">Division</label>
                        <Input 
                            type="select"
                            value={division || 'false'}
                            onChange={(e) => setDivision(e.target.value)}
                        >
                            <option value="false">All Divisions (No Filter)</option>
                            {divisions.map((division, i) => (
                                <option key={i} value={division._id}>{division.name}</option>
                            ))}
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Chapter</label>
                        <Input 
                            type="select"
                            value={chapter}
                            onChange={(e) => setChapter(e.target.value)}
                        >
                            <option value="false">All Chapters (No Filter)</option>
                            <option value="Chapter 7 Full">Chapter 7 Upfront</option>
                            <option value="Chapter 7 Emergency">Chapter 7 Zero Down</option>
                            <option value="Chapter 13">Chapter 13</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <label className="form-control-label">Case Filed</label>
                        <Input 
                            type="select"
                            value={caseFiled}
                            onChange={(e) => setCaseFiled(e.target.value)}
                        >
                            <option value="false">No Filter</option>
                            <option value="yes">Case Was Filed</option>
                            <option value="no">Case Not Filed</option>
                        </Input>
                    </FormGroup>

                </CardBody>

                <CardFooter>
                    <p className="text-sm mb-0">** Dates default to the start and end of the current month.</p>
                </CardFooter>

            </Card>

            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Offices</CardTitle>
                </CardHeader>
               
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Office</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collection Percent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.offices.map((office, i) => (
                                <tr key={i}>
                                    <td>{office.office_name}</td>
                                    <td className="text-right">{formatMoney(office.data.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(office.data.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            office.data.total_payments_succeeded && office.data.total_payments ? 
                                            ((office.data.total_payments_succeeded / office.data.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="font-weight-bold text-dark">TOTALS</td>
                                <td className="text-right font-weight-bold text-dark">{formatMoney(collection.succeeded)}</td>
                                <td className="text-right font-weight-bold text-dark">{formatMoney(collection.total)}</td>
                                <td className="text-right font-weight-bold text-dark">{collection.percent}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="text-sm mb-0">** "Collection Percent" represents percent of total succeeded payments against to total payments that were tried for capture</p>
                </CardFooter>

            </Card>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Automated</CardTitle>
                </CardHeader>
               
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Type</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collection Percent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.automated.map((automated, i) => (
                                <tr key={i}>
                                    <td className="text-capitalize">{automated.type}</td>
                                    <td className="text-right">{formatMoney(automated.data.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(automated.data.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            automated.data.total_payments_succeeded && automated.data.total_payments ? 
                                            ((automated.data.total_payments_succeeded / automated.data.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="text-sm mb-0">** "Collection Percent" represents percent of total succeeded payments against to total payments that were tried for capture</p>
                </CardFooter>

            </Card>
            
            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Fee Split</CardTitle>
                </CardHeader>
               
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Type</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collection Percent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.fees.map((fees, i) => (
                                <tr key={i}>
                                    <td className="text-capitalize">{fees.type.replace(/_/g, ' ')}</td>
                                    <td className="text-right">{formatMoney(fees.data.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(fees.data.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            fees.data.total_payments_succeeded && fees.data.total_payments ? 
                                            ((fees.data.total_payments_succeeded / fees.data.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="text-sm mb-0">** "Collection Percent" represents percent of total succeeded payments against to total payments that were tried for capture</p>
                </CardFooter>
            </Card>
            
            <Card>
                <CardHeader>
                    <CardTitle className="mb-0">Filed Cases</CardTitle>
                </CardHeader>
               
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Total Filed</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collected / Invoiced</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filed.map((filed, i) => (
                                <tr key={i}>
                                    <td className="text-capitalize">{filed.count}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            filed.total_payments_succeeded && filed.total_payments ? 
                                            ((filed.total_payments_succeeded / filed.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Total Filed 6 Months Preceding Range</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collected / Invoiced</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filed6.map((filed, i) => (
                                <tr key={i}>
                                    <td className="text-capitalize">{filed.count}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            filed.total_payments_succeeded && filed.total_payments ? 
                                            ((filed.total_payments_succeeded / filed.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <th style={{width: 150}}>Total Filed 12 Months Preceding Range</th>
                                <th className="text-right">Succeeded</th>
                                <th className="text-right">Total</th>
                                <th className="text-right">Collected / Invoiced</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filed12.map((filed, i) => (
                                <tr key={i}>
                                    <td className="text-capitalize">{filed.count}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments_succeeded)}</td>
                                    <td className="text-right">{formatMoney(filed.total_payments)}</td>
                                    <td className="text-right">
                                        {
                                            filed.total_payments_succeeded && filed.total_payments ? 
                                            ((filed.total_payments_succeeded / filed.total_payments) * 100).toFixed(2) + '%' : 
                                            '-'
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <CardFooter>
                    <p className="text-sm mb-0">** "Collected / Invoiced" represents how much has been paid on all filed cases against the total amount invoiced for all filed cases</p>
                </CardFooter>

            </Card>

        </Container>

    )

}


const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(Collections);   