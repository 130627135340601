import React, { memo } from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'
import { formatMoney } from 'utils/currency';

import keys from 'keys';

const Invoices = ({invoiceTypes, invoices, invoiceTotal}) => (
    <CardHeader>
        <div id="archk-invoices-toggler">
            <Row>
                <Col xs={6}>
                    Invoices Sent <i className="fas fa-caret-down text-warning " />
                </Col>
                <Col xs={6} className="text-right">
                    ({formatMoney(invoiceTotal)}){' '}
                    {invoices.length}
                </Col>
            </Row>

            {Object.keys(invoiceTypes).map((key, i) => (
                <Row key={i}>
                    <Col xs={9}>
                        <span className="pl-4">- {key}</span>
                    </Col>
                    <Col xs={3} className="text-right">
                        ({
                        ((invoiceTypes[key] / invoices.length) 
                        * 100).toFixed(2)}%)
                        {' '}
                        {invoiceTypes[key]}
                    </Col>
                </Row>
            ))}
        </div>
        
        <UncontrolledCollapse toggler="#archk-invoices-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Contact</th>
                                <th>Workflow</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Hired At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.map((log) => (
                                <tr key={log._id}>
                                    <td className="text-capitalize">
                                        {log.contact ? (
                                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${log.contact._id}`}>
                                                {renderName(log.contact)}
                                            </A>
                                        ) : 'contact not found'}
                                    </td>
                                    <td>{log.case && log.case.workflow ? log.case.workflow.type : '-'}</td>
                                    <td className="text-capitalize text-right">
                                        {formatMoney(log.total)}
                                    </td>

                                    <td className="text-right">
                                        {moment.unix(log.date).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(Invoices);