import React, { memo } from 'react';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'

import keys from 'keys';

const LeadSources = ({leadSourceTypes, lead_sources, breakdown}) => (
    <CardHeader>
        <div>
            <Row>
                <Col xs={6}>
                    <A href="/analytics/lead_sources">
                        <i className="fas fa-door-open mr-2 text-success " />
                    </A>
                    <span id="archk-lead-sources-toggler" className="cursor-pointer">
                        Lead Sources <i className="fas fa-caret-down text-warning " />
                    </span>
                </Col>
                <Col xs={6} className="text-right">
                    {lead_sources.length}
                </Col>
            </Row>

            {Object.keys(leadSourceTypes).map((key, i) => (
                <Row key={i}>
                    <Col xs={9}>
                        <span className="pl-4 text-capitalize">- {key}</span>
                    </Col>
                    <Col xs={3} className="text-right">
                        ({
                        ((leadSourceTypes[key] / lead_sources.length) 
                        * 100).toFixed(2)}%)
                        {' '}
                        {leadSourceTypes[key]}
                    </Col>
                </Row>
            ))}
        </div>

        <h4 id="archk-lead-sources-breakdown-toggler" className="mt-3 mb-0 cursor-pointer">Total Leads From Paid Sources - {breakdown.totalNonOthers}</h4>
        
        <UncontrolledCollapse toggler="#archk-lead-sources-breakdown-toggler">
            {breakdown.sources.length ? breakdown.sources.map((source, i) => (
                <div key={i}>
                    <div className="pl-4 text-capitalize text-dark font-weight-bold">{source.source} - {source.data.hired} Hired</div>
                    {source.data.leads_hired.length ? source.data.leads_hired.map((lead, ii) => (
                        <div key={ii} className="pl-6 text-success text-capitalize">
                            {' '}- HIRE:{' '}
                            <span className="cursor-pointer">
                                <A href={`${keys.APP_URL}contacts/view/${lead.contact ? lead.contact._id : 'notfound'}`}>
                                    {lead.contact ? renderName(lead.contact) : 'Not Found'}
                                </A>
                            </span>
                        </div>
                    )) : null}
                    {source.data.leads.length ? source.data.leads.map((lead, ii) => (
                        <div key={ii} className="pl-6"> - LEAD: {lead.contact ? renderName(lead.contact) : 'Not Found'} </div>
                    )) : null}
                </div>
            )) : null}
        </UncontrolledCollapse>

        
        <UncontrolledCollapse toggler="#archk-lead-sources-toggler">
            <Card>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Contact</th>
                                <th>Status</th>
                                <th className="text-right">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lead_sources.map((source) => (
                                <tr key={source._id}>
                                    <td>
                                        {source.lead_source ? (
                                            <A className="text-info" href={`/analytics/lead_sources/view/${source.lead_source._id}`}>
                                                {source.lead_source.name }
                                            </A>
                                        ): 'Source Not Found'}
                                    </td>
                                    <td className="text-capitalize">
                                        {source.contact ? (
                                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${source.contact._id}`}>
                                                {renderName(source.contact)}
                                            </A>
                                        ) : 'contact not found'}
                                    </td>
                                    <td>{source.status}</td>

                                    <td className="text-right">
                                        {moment.unix(source.created_at).format('MMM Do, YYYY h:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </UncontrolledCollapse>
    </CardHeader>
)

export default memo(LeadSources);