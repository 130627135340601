import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from "reactstrap";
import { toggleAlertBS } from 'store/functions/system/system';
import ConfirmationModal from 'components/functional/modals/Confirmation.js'
import Circle from 'components/markup/loading/Circle'

import moment from 'moment';
import _api_keys from '_functions/api_keys';

import ModalCreate from './ModalCreate'

const CompanyApiKeys = () => {

    const [ apiKeys, setApiKeys ] = useState(null);
    const [ keyToDelete, setKeyToDelete ] = useState(null); 
    const [ showModal, toggleModal ] = useState(false);
    const [ error, setError ] = useState(false);

    const onDeleteKey = async () => {
        const deleted = await _api_keys.delete(keyToDelete._id);
        if(deleted.success) {
            fetchKeys();
            setKeyToDelete(null)
            toggleAlertBS(false, 'Key deleted successfully');
        } else {
            toggleAlertBS(true, 'Something went wrong');
        }
    }

    const fetchKeys = async () => {

        const data = await _api_keys.find();

        if(data.data) {
            setApiKeys(data.data)
        } else {
            setError(data.message)
        }

    }

    // load company data into the state
    useEffect(() => {        
        fetchKeys();
    }, [])


    if(error) return <div className="alert alert-danger">An error occurred, please check back later.</div>;
    if(!apiKeys) return <Circle />;

    return (

        <>

        <Card>
            <CardHeader>
                <CardTitle className="mb-0">API Key Usage</CardTitle>
            </CardHeader>
            <CardBody>
                <p className="text-sm">To use the below keys you must include them in the headers of any request to system endpoints in the following manner:</p>
                <pre>
{`{
    "api-key":    "{api key id}",
    "api-secret": "{api secret}",
}`}
                </pre>
            </CardBody>
        </Card>

        <Card>

            <CardHeader>
                <Row>
                    <Col lg={6} className="align-self-center">
                        <CardTitle className="mb-0">API Keys</CardTitle>
                    </Col>
                    <Col lg={6} className="align-self-center text-right">
                        <button onClick={() => toggleModal(true)} className="btn btn-outline-success"><i className="fas fa-plus mr-2 " /> Add Key</button>
                    </Col>
                </Row>
            </CardHeader>

            <div className="table-responsive" style={{tableLayout: 'fixed'}}>
                <table className="table">

                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>ID</th> 
                            <th>Secret</th> 
                            <th style={{width: 300}}>Privileges</th>
                            <th className="text-right">Actions</th>  
                        </tr>
                    </thead>

                    <tbody>
                        {apiKeys.length ? apiKeys.map((key, i) => {
                            return (
                                <tr key={i}>
                                    <td>
                                        <div className="font-weight-bold">{key.name}</div>
                                        {moment.unix(key.created_at).format('MMM, Do YYYY')}
                                    </td>
                                    <td>{key._id}</td>
                                    <td>...{key.last_six}</td>
                                    <td style={{whiteSpace: 'pre-line'}}>{key.privileges.map((p, i) => i === 0 ? p : ', ' + p)}</td>
                                    <td className="text-right">
                                        <button onClick={() => setKeyToDelete(key)} className="btn btn-outline-danger"><i className="fas fa-trash mr-2 " /> Delete</button>
                                    </td>
                                </tr>
                            )
                        }) : (
                            <tr>
                                <td>No Keys Have Been Created</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <ConfirmationModal
                showModal={keyToDelete ? true : false}
                toggleModal={() => setKeyToDelete(false)}
                title="Delete API Key"
                body={<span>This will instantly prevent anyone with this key from accessing your system. Make sure you have a new key ready to go if you are trying to roll this key.</span>}
                onConfirmation={onDeleteKey}
            />

            <ModalCreate 
                showModal={showModal}
                toggleModal={toggleModal}
                onCreate={fetchKeys}
            />

        </Card>

        </>
    )

}

export default CompanyApiKeys


