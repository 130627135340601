import { connect } from 'react-redux'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import { architeckCall } from 'database';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row, FormGroup, Input, CardFooter } from 'reactstrap';
import { formatMoney } from 'utils/currency';

import ArchkInArray from '../query/QueryCreator/Filter/inputs/ArchkInArray'

import _analytics from '_functions/analytics';

const RenderCard = (props) => (

    <Card>

        <CardHeader>
            <CardTitle className="mb-0">{props.title}</CardTitle>
        </CardHeader>

        <CardBody>

            <Row>
                <Col xs={4}>
                    <p className="text-sm mb-0">Standard:</p>
                </Col>
                <Col xs={8} className="text-right">
                    <p className="text-sm mb-0">{formatMoney(props.data.standard)}</p>
                </Col>
            </Row>

            <Row>
                <Col xs={4}>
                    <p className="text-sm mb-0">Trust:</p>
                </Col>
                <Col xs={8} className="text-right">
                    <p className="text-sm mb-0">{formatMoney(props.data.trust)}</p>
                </Col>
            </Row>

            <hr className="my-2"/>

            <Row>
                <Col xs={4}>
                    <p className="font-weight-bold text-sm mb-0">Total:</p>
                </Col>
                <Col xs={8} className="text-right">
                    <p className="font-weight-bold text-sm mb-0 text-success">{formatMoney(props.data.total)}</p>
                </Col>
            </Row>

        </CardBody>

    </Card>
)

class PaymentProjections extends Component {


    state = {
        data: null,
        division: null,
        recurrings: null,
        chapter: false,
        offices: []
    };

    queryAll = () => {
        this.fetchData();
        this.fetchRecurrings();
    }

    getQueryParams = () => {
        const { division, offices, chapter, case_filed } = this.state;
        let filter = division && division !== 'false' ? `?division=${division}` : '?';

        if(offices && offices.length) {
            let officeString = ''
            offices.forEach((office, i) => {
                if(i === 0) {
                    if(filter === '?') {
                        officeString = `offices=${office}`
                    } else {
                        officeString = `&offices=${office}`
                    }
                } else {
                    officeString += `|${office}`
                }
            })
            filter += officeString
        }

        if(chapter && chapter !== 'false') {
            if(filter === '?') {
                filter += `chapter=${chapter}`
            } else {
                filter += `&chapter=${chapter}`
            }
        }

        if(case_filed === 'Yes') {
            if(filter === '?') {
                filter += `case_filed=true`
            } else {
                filter += `&case_filed=true`
            }
        }
      
        if(case_filed === 'No') {
            if(filter === '?') {
                filter += `case_filed=false`
            } else {
                filter += `&case_filed=false`
            }
        }

        return filter;

    }

    fetchData = async () => {
        
        const data = await architeckCall({
            method: 'get',
            url: `/api/v1/analytics/custom_reports/payments/projections${this.getQueryParams()}`
        })

        this.setState({data: data.data})


    }

    fetchRecurrings = async () => {

        const data = await _analytics.payments.recurringsAdded(this.getQueryParams());
        if(data.data) {
            this.setState({recurrings: data.data})
        }
    }

    componentDidMount = async () => {

        this.fetchData();
        this.fetchRecurrings();

    }

    render() {

        const { data, division, recurrings, chapter, case_filed } = this.state;
        const { divisions, offices } = this.props;

        if(!data) return <div className="py-6"><Circle /></div>

        return (

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                    breadcrumb_1="Projections"
                    breadcrumb_2="View"
                />

                {recurrings ? (
                    <>
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">Global Metrics</CardTitle>
                        </CardHeader>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>System Totals</th>
                                        <th className="text-right">Standard</th>
                                        <th className="text-right">Trust </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Current Payment Plans</td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.activeRecurrings.amount_standard)}{' '}
                                            <span className="text-info-original">({recurrings.activeRecurrings.count_standard})</span>
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.activeRecurrings.amount_trust)}{' '}
                                            <span className="text-info-original">({recurrings.activeRecurrings.count_trust})</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Hires With Payment Plan On Day</th>
                                        <th className="text-right">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Today</td>
                                        <td className="text-right">{recurrings.withPlanToday}</td>
                                    </tr>
                                    <tr>
                                        <td>Last 7 Days</td>
                                        <td className="text-right">{recurrings.withPlan7}</td>
                                    </tr>
                                    <tr>
                                        <td>Last 30 Days</td>
                                        <td className="text-right">{recurrings.withPlan30}</td>
                                    </tr>
                                    <tr>
                                        <td>Last 90 Days</td>
                                        <td className="text-right">{recurrings.withPlan90}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </Card>

                    <Card>

                    <CardHeader>
                        <CardTitle className="mb-0">
                        Total To Be Collected: <b className="text-dark font-weight-bold"> {formatMoney(data.total_to_collect)}</b>
                        </CardTitle>
                    </CardHeader>

                    <CardHeader>
                        <FormGroup>
                            <label className="form-control-label">Division</label>
                            <Input 
                                type="select"
                                value={division || 'false'}
                                onChange={(e) => this.setState({division: e.target.value})}
                            >
                                <option value="false">All Divisions (No Filter)</option>
                                {divisions.map((division, i) => (
                                    <option key={i} value={division._id}>{division.name}</option>
                                ))}
                            </Input>
                        </FormGroup>

                        <ArchkInArray 
                            label="Office"
                            data={offices.map(o => { return {value: o._id, text: o.name} })}
                            onChange={offices => this.setState({offices: offices ? offices.$in : []})}
                        />

                        <FormGroup>
                            <label className="form-control-label">Chapter</label>
                            <Input 
                                type="select"
                                value={chapter}
                                onChange={(e) => this.setState({chapter: e.target.value})}
                            >
                                <option value="false">All Chapters (No Filter)</option>
                                <option value="Chapter 7 Full">Chapter 7 Upfront</option>
                                <option value="Chapter 7 Emergency">Chapter 7 Zero Down</option>
                                <option value="Chapter 13">Chapter 13</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <label className="form-control-label">Case Filed</label>
                            <Input 
                                type="select"
                                value={case_filed}
                                onChange={(e) => this.setState({case_filed: e.target.value})}
                            >
                                <option value="ignore">-</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Input>
                        </FormGroup>

                    </CardHeader>

                    <CardHeader className="text-right">
                        <button onClick={this.queryAll} className="btn btn-success">Get Stats</button>
                    </CardHeader>

                        <CardFooter>
                            <CardTitle className="mb-0">Plans At A Glance</CardTitle>
                        </CardFooter>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th className="text-right">Standard</th>
                                        <th className="text-right">Trust </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Added Today</td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.addedToday.total_standard)}{' '}
                                            <span className="text-info-original">({recurrings.addedToday.count_standard})</span>
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.addedToday.total_trust)}{' '}
                                            <span className="text-info-original">({recurrings.addedToday.count_trust})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Added Last 7 days</td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added7.total_standard)}{' '}
                                            <span className="text-info-original">({recurrings.added7.count_standard})</span>
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added7.total_trust)}{' '}
                                            <span className="text-info-original">({recurrings.added7.count_trust})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Added Last 30 days</td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added30.total_standard)}{' '}
                                            <span className="text-info-original">({recurrings.added30.count_standard})</span>
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added30.total_trust)}{' '}
                                            <span className="text-info-original">({recurrings.added30.count_trust})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Added Last 90 days</td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added90.total_standard)}{' '}
                                            <span className="text-info-original">({recurrings.added90.count_standard})</span>
                                        </td>
                                        <td className="text-right">
                                            {formatMoney(recurrings.added90.total_trust)}{' '}
                                            <span className="text-info-original">({recurrings.added90.count_trust})</span>
                                        </td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>

                        <CardFooter>
                            <p className="text-sm">** Above metrics show the combined cash value and total count <b className="text-info-original font-weight-bold">(in blue)</b> of each payment plan metric. </p>
                            <p className="text-sm mb-0">** Note that the metric "Current Payment Plans" will not change upon searching for divisions as it is a global metric</p>
                        </CardFooter>

                      


                    
                    </Card>

                    </>
                ) : (
                    <Card>
                        <div className="py-6">
                        <Circle />
                        </div>
                    </Card>
                )}

                <Row>

                    <Col lg={4}>
                        <RenderCard
                            title="Next 15 Days"
                            data={data.payments_15_days}
                        />
                    </Col>

                    <Col lg={4}>
                        <RenderCard
                            title="Next 30 Days"
                            data={data.payments_30_days}
                        />
                    </Col>

                    <Col lg={4}>
                        <RenderCard
                            title="Next 60 Days"
                            data={data.payments_60_days}
                        />
                    </Col>

                    <Col lg={4}>
                        <RenderCard
                            title="Next 90 Days"
                            data={data.payments_90_days}
                        />
                    </Col>

                    <Col lg={4}>
                        <RenderCard
                            title="Next 180 Days"
                            data={data.payments_180_days}
                        />
                    </Col>

                    <Col lg={4}>
                        <RenderCard
                            title="180 Days +"
                            data={data.payments_over_180_days}
                        />
                    </Col>

                </Row>

            </Container>

        )

    }

}

const mapStateToProps = state => {
    return {
        divisions: state.divisions.divisions,
        offices: state.offices.offices,
    };
};

export default connect(mapStateToProps, '')(PaymentProjections);   
