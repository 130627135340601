import moment from 'moment';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { CardBody, Card, CardHeader, CardTitle } from "reactstrap";
import renderName from 'utils/renderName';

import keys from 'keys';

const UserHiredLogsEntryTable = ({data}) => {

    const columns = [
        {
            dataField: "case",
            text: "Case",
            formatter: (cell, row) => (
                row.case && row.contact ? (
                    <div>
                        <div>
                            <a className="text-capitalize" target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`}>
                            {row.case.name}
                            </a>
                        </div>

                        <div>
                            {moment.unix(row.date).format("M/D/YYYY") + ' at ' + moment.unix(row.date).format("h:mm A")}
                        </div>
                    </div>
                ) : 'No Case Found'
            )
        },
       
        {
            dataField: "step_moved_by",
            text: "User & Payment",
            formatter: (cell, row) => row.step_moved_by ? (
                <div>
                    <div className="text-capitalize">{renderName(row.step_moved_by)}</div>
                    <div>
                        <div>Plan Setup: { row.has_recurring_on_day ? <i className="fas fa-check text-success " /> : <i className="fas fa-times text-danger " /> }</div>
                    </div>
                </div>
               
            ) :  '-'
        },
       
      
    ]

    const pagination = paginationFactory({
        page: 1,
        alwaysShowAllBtns: false,
        sizePerPage: 100,
        showTotal: false,
        withFirstAndLast: false,
        sizePerPageRenderer: () => <></>,
        pageButtonRenderer: () => <p className="text-sm mb-0"><small>**PPs Compile At 3AM</small></p>,

    });

    return (
        <Card>
            <CardHeader>
                <CardTitle className="mb-0">Hires By User</CardTitle>
            </CardHeader>

            {data.length ? (

                <ToolkitProvider
                    data={data}
                    ignoreSinglePage={true}
                    keyField="_id"
                    columns={columns}
                    search
                >
                    {props => (
                        <>

                            <div className=" table-responsive table-vertical-align">
                                <BootstrapTable
                                    pagination={pagination}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                />
                            </div>
                        </>
                    )}
                </ToolkitProvider>

                ) : (

                <CardBody>
                    <p className="text-sm mb-0 text-warning font-weight-bold">No contacts were hired in this time frame for the current dataset.</p>
                </CardBody>

            )}
        </Card>
    )

}

export default UserHiredLogsEntryTable;