import keys from 'keys'
import React from "react";
import { connect } from 'react-redux';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, Navbar, NavItem, UncontrolledDropdown } from "reactstrap";
import AvatarImage from 'components/functional/images/AvatarImage';

import getLogoutURL from 'utils/getLogoutURL'

import A from 'components/markup/links/A'

const HeaderDesktop = ({viewing_user}) => (
    <Navbar style={{background: '#053A53'}} className="dashboard-nav navbar-top navbar-expand border-bottom navbar d-none d-md-block"  >
        <Container fluid>

            <Nav className="align-items-center d-none d-md-block" navbar>
                <NavItem className="mr-5">
                    <A href={keys.APP_URL}>
                        <img style={{position: 'relative', top: -1}} alt="..." className="navbar-brand-img mr-3" src={keys.LOGO} />
                    </A>
                </NavItem>
            </Nav>

            <Collapse navbar isOpen={true}>

                <Nav className="align-items-center ml-md-auto " navbar />

                <Nav className="align-items-center ml-auto ml-md-0" navbar>

                    <UncontrolledDropdown nav style={{marginTop: -6, top: 3, position: 'relative'}}>

                        <DropdownToggle className="nav-link pl-0 pr-0" color="" tag="a">
                            <Media className="align-items-center">


                                <Media className="ml-2 d-none d-md-block">
                                    <span className="mb-0 text-sm font-weight-bold text-white">
                                        <span className="text-capitalize">{viewing_user.given_name} {viewing_user.family_name}</span>

                                        <i className="ml-1 fas fa-caret-down pr-4 pl-2"></i>
                                        <AvatarImage  src={viewing_user.avatar} className="mr-2 z-depth-2" />

                                    </span>
                                </Media>

                            </Media>
                        </DropdownToggle>

                        <DropdownMenu className="bg-primary" style={{borderTop: 'solid 1px white', right: 0}} right>

                            <DropdownItem className="noti-title" header tag="div">
                                <img src={keys.LOGO} className="w-75 " alt="Fairmax" />
                            </DropdownItem>

                            <DropdownItem divider />

                            <a href={keys.APP_URL} target="_blank" rel="noopener noreferrer">
                                <DropdownItem	>
                                <i className="fas fa-home" />
                                    <span>Home</span>
                                </DropdownItem>
                            </a>

                            <span onClick={() => window.location.href = getLogoutURL()}>
                                <DropdownItem>
                                    <i className="ni ni-user-run" />
                                    <span>Logout</span>
                                </DropdownItem>
                            </span>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>

            </Collapse>
        </Container>
    </Navbar>
)

const mapStateToProps = state => {
  	return {
    	viewing_user: state.auth.viewing_user,
  	};
};

export default connect(mapStateToProps, '')(HeaderDesktop);
