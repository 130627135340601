import * as privileges from '_functions/users/privileges'

const getInitialRoute = () => {

    if(privileges.canModerateAnalytics()) return '/analytics/master'; 
    
    if(privileges.canModerateStaff()) return '/core/users/all';
    if(privileges.canModerateCaseTemplates()) return '/core/cases/alert_templates/all';
    if(privileges.canModerateAppointmentTemplates()) return '/core/appointments/templates/all';
    if(privileges.canModerateForms()) return '/core/forms/all';
    if(privileges.canModerateWorkflows()) return '/core/workflows';
    if(privileges.canModerateMissingDocs()) return '/core/documents/missing';
    if(privileges.canModerateTemplateDocs()) return '/core/documents/word';
    if(privileges.canModerateTags()) return '/core/tags/all';
    if(privileges.canModerateUserStatuses()) return '/core/user_statuses/all';
    if(privileges.canModerateLocations()) return '/core/locations/location_hearings/all';
    if(privileges.canModerateOffices()) return '/core/locations/offices/all';
    if(privileges.canModerateObjectionNumbers()) return '/core/objection_numbers/all';

    return '/analytics/master';

}

export default getInitialRoute;