import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";
import renderName from 'utils/renderName';
import _collections from '_functions/collections';
import moment from 'moment'

import A from 'components/markup/links/A'

import keys from 'keys';

import DelinquentsToSend from './ToSend';
import DelinquentsFiled4Months from './Filed4Months';
import DelinquentsAll from './All';
import DelinquentsFiled from './Filed';

class Delinquents extends React.Component {

    state = {
        marked_for_collections: false, 
        hideAll: false
    }

    columns = [
        {
            dataField: "contact",
            text: "Contact/Case",
            formatter: (cell, row) => (
                <div>
                    {row.contact ? (
                        <div className="text-capitalize">
                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} className="cursor-pointer">
                            {renderName(row.contact)}
                            </A>
                        </div>
                    ) : null}
                    {row.case ? (
                        <div className="text-capitalize">
                            <A href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`} className="cursor-pointer">
                            {row.case.name}
                            </A>
                        </div>
                    ) : null}
                </div>
            ),
        },
        {
            dataField: "delinquent_since",
            text: "Dates",
            formatter: (cell, row) => (
                <div>
                    <div>
                        Delinquent Since: {moment.unix(row.delinquent_since).format('MMM Do, YYYY')}
                    </div>
                    <div>
                        Last Payment On: {' '}
                        {row.last_successful_payment ? (
                            moment.unix(row.last_successful_payment).format('MMM Do, YYYY')
                        ) : '-'}
                    </div>
                </div>
            )
        },
        {
            dataField: "collection_attempts",
            text: "Next Call / Attempts",
            formatter: (cell, row) => (
                <div>
                    <div>{moment.unix(row.next_call).format('MM/DD/YYYY h:mm A')}</div>
                    <div>Past Attempts: {row.collection_attempts}</div>
                </div>
                
            )
        },
        {
            dataField: "last_collection_attempt",
            text: 'Last Call For Payment',
            formatter: (cell, row) => row.last_collection_attempt ? moment.unix(row.last_collection_attempt).format('MM/DD/YYYY h:mm A') : '-'
        },
       
        {
            dataField: "updated_at",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        {row.contact ? (
                            row.marked_for_collections ? (
                                <p className="text-sm text-success">In Collections</p>
                            ) : (
                                <button onClick={() => this.setState({collectionToSend: row})} className="btn btn-danger">Send To Collections</button>
                            )
                        ) : null}
                    </div>
                )
            }
        },

    ]

    toggleMarkedForCollection = () => {
        this.setState({marked_for_collections: !this.state.marked_for_collections, hideAll: true}, () => {
            this.setState({hideAll: false})
        })
    }

    componentDidMount = async () => {

        const collections = await _collections.search({
            marked_for_collections: this.state.marked_for_collections,
            search: undefined,
            limit: this.state.sizePerPage
        })

        this.setState({data: collections.data, total_documents: collections.total_documents})

    }


    render() {

        let { marked_for_collections, hideAll } = this.state;

        return (
            <>

            <Container fluid>

                <Helmet>
                    <title>{`Collections (${this.state.total_documents}) `}</title>
                    <meta name="description" content="Collections" />
                </Helmet>

                <DashHeaderOpen
                    icon="fas fa-home"
                    icon_link="/dashboard"
                    title={<span >Collections</span>} breadcrumb_1="All"
                    actionComponent={(
                        marked_for_collections ? (
                            <button className="btn btn-warning" onClick={this.toggleMarkedForCollection}>
                                Showing Marked For Collection
                            </button>
                        ) : (
                            <button className="btn btn-success" onClick={this.toggleMarkedForCollection}>
                                Showing All Delinquents
                            </button>
                        )
                    )}
                />

                {!hideAll ? (
                    <>
                        {!marked_for_collections ? (
                            <>
                                <DelinquentsFiled        columns={this.columns} />
                                <DelinquentsFiled4Months columns={this.columns} />
                                <DelinquentsToSend       columns={this.columns} />
                            </>
                        ) : null}
                        <DelinquentsAll columns={this.columns} marked_for_collections={marked_for_collections} />
                    </>
                ) : null}
               
            </Container>

            </>
        );
    }
}

export default Delinquents
