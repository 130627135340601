/*
Documentation

This file shows the address fields when creating or or updating a user

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import States from 'components/markup/inputs/States';
import Countries from 'components/markup/inputs/Countries';
import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from 'prop-types';

class Address extends React.Component {

    render() {

        const user = this.props.user;

        return (
            <>

                <Row>

                    <Col md="6">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="address_line_1"
                            title="Address Line 1 "
                        />
                    </Col>

                    <Col md="6">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="address_line_2"
                            title="Address Line 2 "
                        />
                    </Col>

                </Row>

                <Row>

                    <Col lg="4">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="city"
                            title="City"
                        />
                    </Col>

                    <Col lg="4">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="state"
                            title="State"
                            type="select"
                        >
                            <option value="">Select State</option>
                            <States />
                        </StandardFormGroup>
                    </Col>

                    <Col lg="4">
                        <StandardFormGroup
                            obj={user}
                            objName="user"
                            onChange={(o, f, v) => this.props.onChange(f, v)}
                            field="postal_code"
                            title="Postal code "
                        />
                    </Col>
                </Row>

                <StandardFormGroup
                    obj={user}
                    objName="user"
                    onChange={(o, f, v) => this.props.onChange(f, v)}
                    field="country"
                    title="Country"
                    type="select"
                >
                    <option value="">Select Country</option>
                    <Countries />
                </StandardFormGroup>

            </>
        );
    }
}

Address.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default Address
