import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Badge } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName';
import { formatMoney } from 'utils/currency'

import _analytics from '_functions/analytics'

import A from 'components/markup/links/A'
import QueryCreator from './QueryCreator';

import keys from 'keys';

const QueryPayments = ({offices, tags, divisions}) => {

    useEffect(() => {
        document.body.classList.add('analytics-sidebar-small')
        return () => {
            document.body.classList.remove('analytics-sidebar-small')
        }

    }, [])

    return (
        <QueryCreator 
            title="Payment Reports"
            searchPlaceholder="Contact or Case ID"
            runQuery={async (query, cb) => new Promise (async resolve => {
                // set payments to default to today only at first
                if(!query.hasRun) {

                    const date = moment().startOf('day');
                    query.filter.created_at = {
                        $gte: parseInt(date.format('X')),
                        $lt: parseInt(date.format('X')) + 86400,
                    }
                }
                const result = await _analytics.query.payments(query)
                return resolve(result.data)
            })}
            inputs={[
                { type: 'ArchkDates', field: 'created_at', label: '', defaultValue: new Date() },

                {
                    type: 'ArchkSelectSimple', field: 'status', label: 'Status',
                    data: [
                        {value: 'succeeded', text: 'Succeeded'},
                        {value: 'failed', text: 'Failed'},
                        {value: 'refunded', text: 'Refunded'},
                        {value: 'chargeback', text: 'Chargeback'},
                        {value: 'authorized', text: 'Authorized'},
                    ]
                },
                {
                    type: 'ArchkSelectSimple', field: 'division', label: 'Division',
                    data: divisions.map(i => { return {value: i._id, text: i.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'chapter', label: 'Chapter',
                    data: [
                        {value: 'Chapter 7 Full', text: 'Chapter 7 Upfront'},
                        {value: 'Chapter 7 Emergency', text: 'Chapter 7 Zero Down'},
                        {value: 'Chapter 13', text: 'Chapter 13'},
                    ]
                },
                {
                    type: 'ArchkSelectSimple', field: 'office', label: 'Office',
                    data: offices.map(o => { return {value: o._id, text: o.name} })
                },
             
                {
                    type: 'ArchkInArray', field: 'tags', label: 'Tags', query: 'in',
                    data: tags.map(t => { return {value: t._id, text: t.name} })
                },
                {
                    type: 'ArchkSelectSimple', field: 'type', label: 'Type',
                    data: [
                        {value: 'cash', text: 'Cash'},
                        {value: 'recurring', text: 'Recurring'},
                        {value: 'one time', text: 'One Time'},
                    ]
                },
                { type: 'ArchkNull', field: 'user', label: 'Made By Staff', },
                { type: 'ArchkTruthy', subType: 'boolean', field: 'is_trust', label: 'Is Trust', },
                { type: 'ArchkNumber',  field: 'transaction_amount', label: 'Transaction Amount', },
               
            ]}
            columns={[
                {
                    dataField: "created_at",
                    text: "Payment Date",
                    formatter: (cell, row) => moment.unix(row.created_at).format("M/D/YYYY") + ' at ' + moment.unix(row.created_at).format("h:mm A")
                },
                {
                    dataField: "given_name",
                    text: "Contact",
                    formatter: (cell, row) => (
                        row.contact ? (
                            <A href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} className="text-capitalize">
                                {renderName(row.contact)}
                            </A>
                        ) : '-'
                       
                    ),
                    filterValue: (cell, row) => renderName(row).toLowerCase()
                },

                {
                    dataField: "status",
                    text: "Status",
                    formatter: (cell, row) => {

                        if(row.status === 'succeeded') return   <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="success">Succeeded</Badge>
                        if(row.status === 'failed') return      <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Failed</Badge>
                        if(row.status === 'refunded') return    <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Refunded</Badge>
                        if(row.status === 'chargeback') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="danger">Charged Back</Badge>
                        if(row.status === 'voided') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="danger">Voided</Badge>
                        if(row.status === 'authorized') return  <Badge style={{width: 80, display: 'inline-block'}} className="text-s" color="warning">Authorized</Badge>

                    }
                },
                {
                    dataField: "transaction_amount",
                    text: "Amount",
                    formatter: (cell, row) => {

                        if(row.status === 'failed' || row.status === 'refunded') return <span className="text-warning">{formatMoney(row.transaction_amount)}</span>
                        if(row.status === 'chargeback') return <span className="text-danger">{formatMoney(row.transaction_amount)}</span>

                        return formatMoney(row.transaction_amount)

                    }
                },
                {
                    dataField: "is_trust",
                    text: "Trust",
                    headerStyle: {textAlign: 'center'},
                    formatter: (cell, row) => (
                        <div className="text-center">
                            {row.is_trust ?  <i className="fas fa-check text-success" /> : <i className="fas fa-times text-warning" />}
                        </div>
                    )
                },
                {
                    dataField: "case",
                    text: "Case Name",
                    headerStyle: {textAlign: 'right'},
                    formatter: (cell, row) => (
                        <div className="text-right">
                            {row.case ? (
                                <A href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`} className="text-capitalize">
                                {row.case.name}
                            </A>
                            ) :(
                                <span className="text-danger">Not Found</span>
                            )}
                            
                        </div>
                    )
                },


            ]}
        
        />
    )

}



const mapStateToProps = state => {
    return {
        offices: state.offices.offices,
        tags: state.tags.tags,
        divisions: state.divisions.divisions,
    };
};

export default connect(mapStateToProps, '')(QueryPayments);