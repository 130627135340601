import React, { memo } from 'react';
import { connect } from 'react-redux';

import { Card, CardHeader, Col, UncontrolledCollapse, Row } from 'reactstrap';

import moment from 'moment';
import renderName from 'utils/renderName'
import A from 'components/markup/links/A'
import { secondsToTime } from 'utils/time';

import keys from 'keys';

const CallLogs = ({callLogTypes, call_logs, users}) => {

    const getUserName = (user) => {
        const _user = users.find(u => u._id === user);
        if(!_user) return 'User Not Found'
        return renderName(_user);
    }

    return (
        <CardHeader>
            <div >
                <Row>
                    <Col xs={6}>
                        <A href="/communication/call_logs_outgoing">
                            <i className="fas fa-door-open mr-2 text-success " />
                        </A>
                        <span id="archk-call-logs-toggler" className="cursor-pointer">
                            Call Logs <i className="fas fa-caret-down text-warning " />
                        </span>
                    </Col>
                    <Col xs={6} className="text-right">
                        {call_logs.length}
                    </Col>
                </Row>
    
                {Object.keys(callLogTypes).map((key, i) => (
                    <Row key={i}>
                        <Col xs={9}>
                            <span className="pl-4 text-capitalize">- {getUserName(key)}</span>
                        </Col>
                        <Col xs={3} className="text-right">
                            ({
                            ((callLogTypes[key] / call_logs.length) 
                            * 100).toFixed(2)}%)
                            {' '}
                            {callLogTypes[key]}
                        </Col>
                    </Row>
                ))}
            </div>
            
            <UncontrolledCollapse toggler="#archk-call-logs-toggler">
                <Card>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>User</th>
                                    <th>Contact</th>
                                    <th>Duration</th>
                                    <th className="text-right">Hired At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {call_logs.map((log) => (
                                    <tr key={log._id}>
                                        <td className="text-capitalize">
                                            {getUserName(log.user)}
                                        </td>
                                        <td className="text-capitalize">
                                            {log.contact ? (
                                                <A href={`${keys.APP_URL}/dashboard/contacts/view/${log.contact._id}`}>
                                                    {renderName(log.contact)}
                                                </A>
                                            ) : 'contact not found'}
                                        </td>
                                        <td>{secondsToTime(log.CallDuration ? log.CallDuration : log.duration)}</td>
                                        <td className="text-right">
                                            {moment.unix(log.created_at).format('MMM Do, YYYY h:mm A')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </UncontrolledCollapse>
        </CardHeader>
    )
}


const mapStateToProps = state => {
    return {
        users: state.users.users,
    };
};

export default connect(mapStateToProps, '')(memo(CallLogs));   