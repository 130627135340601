import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import Circle from 'components/markup/loading/Circle';
import { architeckCall } from 'database';
import moment from 'moment';
import React, { Component } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, FormGroup, Input } from 'reactstrap';
import { formatMoney } from 'utils/currency';
import renderName from 'utils/renderName';
import { formatPhone } from 'utils/text'

import OverdueEmail from './OverdueEmail';
import OverdueText from './OverdueText';

import keys from 'keys';

const { SearchBar } = Search;

class PaymentProjections extends Component {


    state = {
        data: null,
        overdue_recurrings: null,
        all_overdue_recurrings: null,
        showModalText: false,
        showModalEmail: false,
        caseFiled: '__false__'
    };


    columns = [
        {
            dataField: "given_name",
            text: "Name",
            filterValue: (cell, row) => renderName(row.contact),
            formatter: (cell, row) => (
                row.contact ? (
                    <a href={`${keys.APP_URL}/dashboard/contacts/view/${row.contact._id}`} target="_blank" rel="noopener noreferrer" className="text-capitalize">
                        {renderName(row.contact)}
                    </a>
                ) : 'Contact Not Found'
            )
        },
        {
            dataField: "case",
            text: "Current Step",
            filterValue: (cell, row) => renderName(row.contact),
            formatter: (cell, row) => (
                row.case && row.case.current_step ? (
                    <a href={`${keys.APP_URL}/dashboard/cases/view/${row.case._id}`} target="_blank" rel="noopener noreferrer" >
                        {row.case.current_step.name}
                    </a>
                    
                ) : '-'
            )
        },

        {
            dataField: "email",
            text: "Email",
            filterValue: (cell, row) => {

                let string = ''

                if(row.contact && row.contact.phone) {
                    string += formatPhone(row.contact.phone)
                }
                if(row.contact && row.contact.email) {
                    string += row.contact.email
                }

                return string

            },
            formatter: (cell, row) => {

                if(!row.contact) return '-'

                let markup = []

                if(row.contact.email) {
                    markup.push((
                        <div key={row.contact._id}><i className="fas fa-envelope mr-2" /> {row.contact.email}</div>
                    ))
                }

                if(row.contact.phone) {
                    markup.push((
                        <div key={row.contact._id + row.contact.created_at}><i className="fas fa-mobile mr-3" /> {formatPhone(row.contact.phone)}</div>
                    ))
                }

                return markup.length ? markup : '-'

            }
        },

        {
            dataField: "delinquent_at",
            text: "Dates",
            formatter: (cell, row) => {
                return (
                    <div>
                        <div><span style={{display: 'inline-block', width: 120}}>Delinquent From: </span> <b>{moment.unix(row.delinquent_at).format('MM/DD/YYYY')}</b></div>
                        <div><span style={{display: 'inline-block', width: 120}}>Next Run Date: </span> <b>{moment.unix(row.next_run_date).format('MM/DD/YYYY')}</b></div>

                    </div>
                )
            }
        },

        {
            dataField: "transaction_amount",
            text: "Recurring Info",
            headerStyle: { textAlign: 'right' },
            formatter: (cell, row) => {

                let interval = parseInt(row.interval) === 1 ? 'Every' : parseInt(row.interval) === 2 ? 'Every Two' : 'Every Three'
                let interval_type = row.interval_type === 'd' ? ' Day' : row.interval_type === 'w' ? ' Week' : ' Month'

                let string = interval + interval_type

                if(parseInt(row.interval) > 1) string += 's'

                return (
                    <div className="text-right">
                        <div className="font-weight-bold text-success">{formatMoney(row.transaction_amount)} </div>
                        <div>{string}</div>
                    </div>
                )
            }
        },

    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    filterByTime = (days) => {
        let data = JSON.parse(JSON.stringify(this.state.all_overdue_recurrings));

        if(days) {
            const date = parseInt(moment().subtract(days, 'days').format('X'));
            data = data.filter(d => d.delinquent_at >= date);
        }

        this.setState({overdue_recurrings: data})
       
    }
    

    fetchReport = async () => {

        const data = await architeckCall({
            method: 'get',
            url: '/api/v1/analytics/custom_reports/payments/overdue'
        })

        this.setState({data: data.data})

    }

    fetchRecurrings = async () => {

        const overdue_recurrings = await architeckCall({
            method: 'get',
            url: '/api/v1/analytics/custom_reports/payments/overdue/fetch'
        })

        this.setState({
            overdue_recurrings: overdue_recurrings.data,
            all_overdue_recurrings: overdue_recurrings.data,
        })

    }

    getPercent = (percent) => percent ? percent : 0

    componentDidMount = async () => {


        this.fetchReport()
        this.fetchRecurrings()

    }

    render() {

        const { data, showModalEmail, showModalText, caseFiled } = this.state
        let {  overdue_recurrings } = this.state;

        console.log(data)

        if(!overdue_recurrings) return <div className="py-6"><Circle /></div>

        if(caseFiled === 'no') {
            overdue_recurrings = overdue_recurrings.filter(d => d.case && d.case.filed_on === 0 ? true : false);
        } else if(caseFiled === 'yes') {
            overdue_recurrings = overdue_recurrings.filter(d => d.case && d.case.filed_on > 0 ? true : false);  
        }

        return (

            <Container fluid>

                <OverdueEmail 
                    showModal={showModalEmail}
                    toggleModal={() => this.setState({showModalEmail: false})}
                    recurrings={overdue_recurrings}
                />
                <OverdueText 
                    showModal={showModalText}
                    toggleModal={() => this.setState({showModalText: false})}
                    recurrings={overdue_recurrings}
                />

                <DashHeaderOpen
                    title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                    breadcrumb_1="Overdue"
                    breadcrumb_2="View"
                />

                <Card>

                    <CardHeader>
                        <FormGroup>
                            <Input
                                type="select"
                                value={caseFiled}
                                onChange={(e) => this.setState({caseFiled: e.target.value})}
                            >
                                <option value="__false__">No Filter</option>
                                <option value="yes">Case Filed</option>
                                <option value="no">Case Not Filed</option>
                            </Input>
                        </FormGroup>
                    </CardHeader>
                    <CardHeader>
                        <Row>
                            <Col lg={6} className="align-self-center">
                                <CardTitle className="mb-0">Overdue Recurring Payments</CardTitle>
                            </Col>
                            <Col lg={6} className="align-self-center text-right">
                                <button onClick={() => this.setState({showModalText: true})} className="btn btn-outline-warning">Mass Text</button>
                                <button onClick={() => this.setState({showModalEmail: true})} className="btn btn-outline-success">Mass Email</button>
                            </Col>
                        </Row>
                    </CardHeader>

                    <CardBody>

                        <div className="percent-breakdown">

                            <div className="legend">
                                <div className="text-sm item day-15">
                                    <span onClick={() => this.filterByTime(15)} className="cursor-pointer">
                                        <span className="key" />{' '}
                                        1-15 Days {' '}
                                        ({this.getPercent(data.delinquent_15_days.percent)}%, {' '}
                                        {formatMoney(data.delinquent_15_days.total)}){' '}
                                    </span>
                                </div>

                                <div className="text-sm item day-30">
                                    <span onClick={() => this.filterByTime(30)} className="cursor-pointer">
                                        <span className="key" />{' '}
                                        16 - 30 Days {' '}
                                        ({this.getPercent(data.delinquent_30_days.percent)}%, {' '}
                                        {formatMoney(data.delinquent_30_days.total)}){' '}
                                    </span>
                                </div>

                                <div className="text-sm item day-60">
                                    <span onClick={() => this.filterByTime(60)} className="cursor-pointer">
                                        <span className="key" />{' '}
                                        30-60 Days {' '}
                                        ({this.getPercent(data.delinquent_60_days.percent)}%, {' '}
                                        {formatMoney(data.delinquent_60_days.total)}){' '}
                                    </span>
                                </div>

                                <div className="text-sm item day-60-plus">
                                    <span onClick={() => this.filterByTime(null)} className="cursor-pointer">
                                        <span className="key" />{' '}
                                        Over 60 Days {' '}
                                        ({this.getPercent(data.delinquent_past_60_days.percent)}%,{' '}
                                        {formatMoney(data.delinquent_past_60_days.total)}){' '}
                                    </span>
                                </div>
                            </div>

                            <div onClick={() => this.filterByTime(15)} className="descriptor day-15" style={{width: data.delinquent_15_days.percent + '%'}}>
                                {data.delinquent_15_days.percent}%
                            </div>
                            <div onClick={() => this.filterByTime(30)} className="descriptor day-30" style={{width: data.delinquent_30_days.percent + '%'}}>
                                {data.delinquent_30_days.percent}%
                            </div>
                            <div onClick={() => this.filterByTime(60)} className="descriptor day-60" style={{width: data.delinquent_60_days.percent + '%'}}>
                                {data.delinquent_60_days.percent}%
                            </div>
                            <div onClick={() => this.filterByTime(null)} className="descriptor day-60-plus" style={{width: data.delinquent_past_60_days.percent + '%'}}>
                                {data.delinquent_past_60_days.percent}%
                            </div>

                        </div>

                    </CardBody>

                </Card>

                <Card className="card-color card-primary">

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">All Over Recurrings</CardTitle>
                    </CardHeader>

                    {overdue_recurrings ? (
                        <ToolkitProvider
                            data={overdue_recurrings}
                            keyField="_id"
                            columns={this.columns}
                            search
                        >
                            {props => (
                                <>
                                <div
                                    id="datatable-basic_filter"
                                    className="dataTables_filter px-4 pb pt-3 pb-2"
                                    style={{width: '100%'}}
                                >
                                    <label>
                                        Search:
                                        <SearchBar
                                            className="form-control-sm"
                                            placeholder="Name, Email, Phone"
                                            {...props.searchProps}
                                        />
                                    </label>
                                </div>
                                <div className=" table-responsive table-vertical-align">
                                    <BootstrapTable
                                        pagination={this.pagination}
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                    />
                                </div>

                                </>
                            )}
                        </ToolkitProvider>
                    ) : <Circle />}

                </Card>


            </Container>

        )

    }

}

export default PaymentProjections;
