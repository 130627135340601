/*
Documentation

This file shows all objection numbers in the system. Has simple functionality to create, and delete them

*/

import NotificationDelete from 'components/functional/notifications/Delete';
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Container, Row, Col, CardFooter } from "reactstrap";
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import _objection_numbers from '_functions/objection_numbers';

const { SearchBar } = Search;

class ObjectionNumbers extends React.Component {

    state = {
        objection_number: {},
        objectionNumberToDelete: null,
    }

    columns = [
        {
            dataField: "name",
            text: "Name",
            headerStyle: {width: 150},
            formatter: (cell, row) => <span onClick={() => this.setState({objection_number: row})} className="text-capitalize font-weight-bold">{row.name}</span>
        },
        {
            dataField: "_id",
            text: "Actions",
            headerStyle: {textAlign: 'right'},
            formatter: (cell, row) => {
                return (
                    <div className="text-right">
                        <span onClick={() => this.setState({objection_number: row})}><i className="fas fa-edit text-success mr-2"></i></span>
                        <i onClick={() => this.setState({objectionNumberToDelete: row})} className="fas fa-trash text-danger"></i>
                    </div>
                )
            }
        },
    ]

    pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
            <div className="dataTables_length" id="datatable-basic_length">
                <label>
                    Show{" "}
                    {
                    <select
                        value={currSizePerPage}
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={e => onSizePerPageChange(e.target.value)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    }{" "}
                    entries.
                </label>
            </div>
        )
    });

    onSelectCreate = () => {
        this.setState({objection_number: {}})
    }

    onCreate = async () => {

        const { objection_number } = this.state

        if(!objection_number.name) {
            return this.setState({objection_number: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _objection_numbers.create(objection_number)

        this.setState({objection_number: {}, loading: false})


    }

    onUpdate = async () => {

        const { objection_number } = this.state

        if(!objection_number.name) {
            return this.setState({objection_number: {nameState: 'invalid'}})
        } else {
            this.setState({loading: true})
        }

        await _objection_numbers.update(objection_number._id, {name: objection_number.name})

        this.setState({objection_number: {}, loading: false})

    }

    render() {

        const objection_numbers = this.props.objection_numbers
        const objectionLength = objection_numbers.length;
        const objectionNumberToDelete = this.state.objectionNumberToDelete;

        const { objection_number, loading } = this.state;

        const isUpdating = objection_number._id;

        return (
            <>

            <Helmet>
                <title>{`Objection Numbers (${objectionLength}) `}</title>
                <meta name="description" content="Objection Numbers" />
            </Helmet>

            {objectionNumberToDelete && (
                <NotificationDelete
                    deletionURL={`/api/v1/core/objection_numbers/delete/${objectionNumberToDelete._id}`}
                    confirmBtnText="Delete Objection Number"
                    title={<span className="text-capitalize">{`Delete Objection Number: ${objectionNumberToDelete.name}`}</span>}
                    text="Deleting this objection number will remove it from all open objections and will display as 'Number Not Found' when viewing any objections associated to this number in the future."
                    onClose={() => this.setState({objectionNumberToDelete: null})}
                    onSuccess={(object) => { } }
                />
            )}

            <Container fluid>

                <DashHeaderOpen
                    title={<span><i className="fas fa-database mr-2 text-success " /> System</span>}
                    breadcrumb_1="Objection Numbers"
                    breadcrumb_2="All"
                />

                <Card>

                    <CardHeader>
                        <CardTitle className="mb-0 float-left">Objection Numbers</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <p className="text-sm mb-0">Objection Numbers all an system admin to create pre-formatted objection numbers to be used when creating case objections.</p>
                    </CardBody>

                </Card>

                <Row>

                    <Col lg={4}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">
                                    {isUpdating ? 'Update Objection Number' : 'Create Objection Number' }

                                </CardTitle>
                            </CardHeader>

                            <CardBody>
                                <StandardFormGroup
                                    obj={objection_number}
                                    objName="objection_number"
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    field="name"
                                    title="Objection Number"
                                />
                            </CardBody>

                            <CardFooter className="text-right">
                                {isUpdating ? (
                                    <>
                                        <button disabled={loading} className="btn btn-outline-success" onClick={this.onSelectCreate}>Create Objection Number</button>
                                        <button disabled={loading} className="btn btn-success" onClick={this.onUpdate}>Update Objection Number</button>
                                    </>
                                ) : (
                                    <button disabled={loading} className="btn btn-success" onClick={this.onCreate}>Create New Objection Number</button>
                                )}
                            </CardFooter>

                        </Card>
                    </Col>

                    <Col lg={8}>
                        <Card>

                            <CardHeader>
                                <CardTitle className="mb-0 float-left">All Objection Numbers</CardTitle>
                            </CardHeader>

                            {objection_numbers.total ? (

                                <ToolkitProvider
                                    data={objection_numbers.objection_numbers}
                                    keyField="_id"
                                    columns={this.columns}
                                    search
                                >
                                    {props => (
                                        <>
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb pt-3 pb-2"
                                            style={{width: '100%'}}
                                        >
                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control-sm"
                                                    placeholder="Case Name"
                                                    {...props.searchProps}
                                                />
                                            </label>
                                        </div>
                                        <div className=" table-responsive table-vertical-align table-not-fixed">
                                            <BootstrapTable
                                                pagination={this.pagination}
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                bordered={false}
                                            />
                                        </div>
                                        </>
                                    )}
                                </ToolkitProvider>

                            ) : (

                                <CardBody>
                                    <p className="text-sm mb-0">No Objection Numbers Have Been Created Yet.</p>
                                </CardBody>

                            )}

                        </Card>
                    </Col>

                </Row>

            </Container>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        objection_numbers: state.objection_numbers,
    };
};

export default connect(mapStateToProps, '')(ObjectionNumbers);
