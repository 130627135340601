import { useCallback, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import { Modal, CardBody } from "reactstrap";

import { toggleStandardLoader } from 'store/functions/system/system';

import _v2_call_center from '_functions/v2_call_center';


const ModalAdd = ({showModal, toggleModal,  division, fetchData, number}) => {

    const [errors, setErrors] = useState([]);

    const onDelete = useCallback(async (state) => {

        setErrors([])
        toggleStandardLoader(true);
        const data = await _v2_call_center.call_phone_numbers.delete(number._id)

        if(data.success) {
            toggleModal()
            fetchData()
        } else {
            setErrors(data.message)
        }

        toggleStandardLoader(false);

    }, [number, toggleModal, fetchData])

    return (
        <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="md"
        >

            <div className="modal-header">
                <h5 className="modal-title">Delete Number - {division.name}</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                Are you sure you wish to delete the number {number.number}
            </div>

            {errors.length ? (
                <CardBody>
                    <p className="text-sm mb-0 text-warning">Please Try Again</p>
                </CardBody>
            ) : ''}

            <div className="modal-footer text-right">
                <button className="btn btn-neutral" onClick={toggleModal}>
                    <i className="fas fa-aroow-left mr-2" />
                    Back
                </button>
                <button className="btn btn-danger" onClick={onDelete}>
                    <i className="fas fa-save mr-2" />
                    Delete
                </button>
            </div>

        </Modal>

    )

}

ModalAdd.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}


const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources
    };
};

export default connect(mapStateToProps, '')(ModalAdd);

