import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Card, CardHeader, CardTitle, CardFooter, CardBody, Container, FormGroup, Input, Row, Col } from 'reactstrap';
import _analytics from '_functions/analytics';
import Circle from 'components/markup/loading/Circle';

import UserSearchInput from 'components/system/migrated/UserSearchInput';
import renderName from 'utils/renderName';
import _v2_call_center from '_functions/v2_call_center';

import ModalToggler from 'components/functional/modals/Toggler'
import ModalAdd from './ModalAdd'
import ModalDelete from './ModalDelete'

const CallPhoneNumbers = ({divisions, lead_sources}) => {

    const [data, setData] = useState(null)

    const getSourceName = useCallback((s) => {
        const found = lead_sources.find(source => source._id === s);
        return found ? found.name : '-'
    }, [lead_sources])

    const fetchData = useCallback(async () => {
        let PromiseArr = [];

        divisions.forEach(d => {
            PromiseArr.push(_v2_call_center.call_phone_numbers.findByDivision(d._id))
        })

        let numbers = []

        const values = await Promise.all(PromiseArr)
        values.forEach(v => {
            if(v.data && Array.isArray(v.data)) {
                numbers = numbers.concat(v.data)
            }
        })

        setData(numbers)
    }, [divisions])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <Container fluid>

            <DashHeaderOpen
                title={<span><i className="fas fa-headset mr-2 text-success " /> Communication</span>}
                breadcrumb_1="V2"
                breadcrumb_2="Call Phone Numbers"
            />

            {!data ? <Circle /> : (
                <div>
                    {divisions.map(division => {

                        const numbers = data.filter(d => d.division === division._id)

                        return (
                            <Card key={division._id}>
                                <CardHeader>
                                    <Row>
                                        <Col xs={6}>
                                            <CardTitle className="mb-0">{division.name} - Numbers</CardTitle>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <ModalToggler component={ModalAdd} division={division} fetchData={fetchData}>
                                                <button className="btn btn-success">Add Number</button>
                                            </ModalToggler>
     
                                        </Col>
                                    </Row>
                                </CardHeader>
    
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Number</th>
                                                <th>Lead Source</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {numbers.length ? numbers.map(number => (
                                                <tr key={number._id}>
                                                    <td>{number.number}</td>
                                                    <td>{getSourceName(number.lead_source)}</td>
                                                    <td className="text-right">
                                                    <ModalToggler component={ModalDelete} division={division} number={number} fetchData={fetchData}>
                                                        <button className="btn btn-sm btn-danger">Delete</button>
                                                    </ModalToggler>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td>No numbers have been created for this division.</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        )
                    })}
                </div>
            )} 

        </Container>

    )
}

const mapStateToProps = state => {
    return {
        lead_sources: state.lead_sources.lead_sources,
        divisions: state.divisions.divisions
    };
};

export default connect(mapStateToProps, '')(CallPhoneNumbers);