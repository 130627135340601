/*
Documentation

This file shows all options for updating a task within the tasks table on the step drawer

*/

import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Modal, Row, FormGroup, Input, Badge } from "reactstrap";
import { connect } from 'react-redux';
import _workflow_tasks from '_functions/workflow_tasks';
import AssignAutomations from 'views/admin/pages/automations/exports/AssignAutomations'
import { toggleStandardLoader } from 'store/functions/system/system'
import ReactSelect from 'components/functional/inputs/ReactSelect';

const required_form_fields = [
    'name',
    'type',
]

const oneHour = 3600;
class StepDrawerTaskModal extends Component {

    state = {
        answer: '',
        automations: [],
        task: this.props.task || {},
    }

    //on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter' && this.state.answer) {
            let answers = this.state.task.answers ? JSON.parse(JSON.stringify(this.state.task.answers)) : [];
            if(!answers.includes(this.state.answer)) answers.push(this.state.answer);
            this.setState({task: {...this.state.task, answers}, answer: ''})
		}
	}
    //on keypress of enter simulate the form being submitted for better UI
	onRemoveAnswer = (answer) => {
        let answers = this.state.task.answers ? JSON.parse(JSON.stringify(this.state.task.answers)) : [];
        answers = answers.filter(a => a !== answer);
        this.setState({task: {...this.state.task, answers}})
	}

    toggle = () => {
        this.setState({automations: [], task: {}});
        this.props.toggle()
    }

    onDelete = async  () => {

        toggleStandardLoader(true);

        const deleted = await _workflow_tasks.delete(this.state.task._id)
        if(deleted.success) this.props.onTaskDeleted(deleted.data)


        this.toggle()
        toggleStandardLoader(false);

    }

    onSave = async () => {

        this.setState({canSave: false, error_message: true})
        //copy state
        let newState = Object.assign({}, this.state.task);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {
            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        if(newState.type === 'select') {

            if(!newState.answers || (newState.answers && !newState.answers.length)) {
                return this.setState({answerState: 'invalid' } )
            }

        }
     
        if(newState.type === 'custom data') {

            if(!newState.analytics_data_type) {
                return this.setState({task: {...newState, analytics_data_typeState: 'invalid'} } )
            }

        }

        if(newState.type === 'tag question') {

            if(!newState.tag) {
                return this.setState({task: {...newState, tagState: 'invalid'} } )
            }

        }

        if(newState.type === 'appointment') {

            if(!newState.template_appointment || !newState.template_appointment._id) {
                return this.setState({task: {...newState, template_appointmentState: 'invalid'} } )
            }

        }

        // if(newState.type === 'send template doc') {

        //     if(!newState.template_doc || !newState.template_doc) {
        //         return this.setState({task: {...newState, template_docState: 'invalid'} } )
        //     }

        // }

        if(newState.type === 'set case role') {

            if(!newState.role) {
                return this.setState({task: {...newState, roleState: 'invalid'} } )
            }

        }

        this.setState({ task: newState });

        if(!errors) {

            const data = {
                ...newState,
                workflow: this.props.workflow._id,
                workflow_step: this.props.step_id,
                automations: this.state.automations,
                persistent: newState.persistent === 'true' ? true : false,
                required: newState.persistent === 'true' ? false : newState.required
            }

            toggleStandardLoader(true);

            if(newState._id) {

                const saved = await _workflow_tasks.update(newState._id, data)
                if(saved.success) this.props.onTaskUpdated(saved.data)

            } else {

                const saved = await _workflow_tasks.create(data)
                if(saved.success) this.props.onTaskCreated(saved.data)

            }

            this.setState({selectedTemplateAppointment: {}, selectedTemplateDoc: {}})

            toggleStandardLoader(false);

            this.toggle()
        }

        this.setState({canSave: true})

    }

    componentWillReceiveProps = (nextProps) => {

        if(this.props.task._id !==  nextProps.task._id) {

            this.setState({task: nextProps.task})

            if(nextProps.task && nextProps.task.template_appointment) {
                this.setState({
                    selectedTemplateAppointment: {
                        _id: nextProps.task.template_appointment, label: nextProps.task.template_appointment.name
                    },
                })
            } else if(nextProps.task && nextProps.task.template_doc) {
                this.setState({
                    selectedTemplateDoc: {
                        _id: nextProps.task.template_doc, label: nextProps.task.template_doc.name
                    }
                })
            }  else {
                this.setState({selectedTemplateAppointment: null, selectedTemplateDoc: null})
            }

        }

    }


    render() {

        const { task, answer } = this.state
        const { analytics_data_types, workflow, tags,  templateAppointmentOptions } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={this.props.showModal}
                toggle={this.toggle}
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title">
                        Edit Task: {' '}
                        {task.type === 'party association' ? 'Associate Party ' : null}
                        {task.name}
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggle}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">

                    <Row>

                        <Col lg={8}>

                            <Row>

                                <Col lg={7}>
                                    <StandardFormGroup
                                        inputId="modal-task-card-first-input"
                                        obj={task}
                                        objName='task'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        type="text"
                                        field="name"
                                        title={task.type === 'party association' ? 'Name Of Party To Set *' : 'Task Name *'}
                                    />
                                </Col>

                                <Col lg={5}>
                                    <StandardFormGroup
                                        obj={task}
                                        objName='task'
                                        onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                        formatter={(val) => val ? val.toString() : ''}
                                        type="number"
                                        field="default_billable_minutes"
                                        title="Default Billable Minutes*"
                                    />
                                </Col>

                            </Row>

                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="type"
                                title="Type *"
                            >
                                <option></option>
                                <option value="text">Text Entry</option>
                                <option value="select">Dropdown</option>
                                <option value="upload">Document Upload</option>
                                <option value="appointment">Appointment</option>
                                <option value="tag question">Tag Question</option>
                                <option value="party association">Party Association</option>
                                <option value="custom data">Custom Data Entry</option>
                                <option value="set chapter">Set Chapter</option>
                                <option value="set case number">Set Case Number</option>
                                <option value="set case role">Set Case Role</option>
                                <option value="set assigned user">Set Assigned User</option>
                                <option value="set location court">Set Court</option>
                                <option value="set office">Set Office</option>
                                <option value="set location hearing trustee">Set Trustee</option>
                                <option value="set location hearing">Set Trustee Hearing Location</option>
                            </StandardFormGroup>

                            {task.type === 'select' ? (
                                <FormGroup>
                                    <label className="form-control-label">Dropdown Answer (Press ENTER to add)</label>
                                    <Input 
                                        type="text" 
                                        value={answer}
                                        onChange={(e) => this.setState({answer: e.target.value})}
                                        onKeyDown={this._handleKeyDown}
                                        invalid={this.state.answerState === 'invalid'}
                                    />
                                    <div className="mt-2">
                                        {task.answers && task.answers.length ? task.answers.map((answer, i) => (
                                            <Badge key={i} color="success">{answer} <i onClick={() => this.onRemoveAnswer(answer)} className="fas fa-times ml-2 cursor-pointer text-danger " /> </Badge>
                                        )) : (
                                            <Badge color="warning">No Answers Created</Badge>
                                        )}
                                    </div>
                                </FormGroup>
                            ) : null}
                          
                            {task.type === 'set case role' ? (
                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="role"
                                title="Case Role Name*"
                            >
                                <option value={null}></option>
                                {workflow.roles && workflow.roles.length ? workflow.roles.map((role, i) => (
                                    <option key={i} value={role}>{role}</option>
                                )) : null}
                            </StandardFormGroup>
                            ) : null}

                            {task.type === 'appointment' ? (
                                <FormGroup>

                                    <label className="form-control-label">
                                        <span className={task.template_appointmentState === 'invalid' ? 'text-danger' : null}>
                                        Template Appointment*
                                        </span>
                                    </label>
                                    <ReactSelect
                                        value={this.state.selectedTemplateAppointment || {}}
                                        onChange={(values) => this.setState({
                                            selectedTemplateAppointment: values,
                                            task: { ...task, template_appointment: {_id: values.value, name: values.label} }
                                        }) }
                                        options={templateAppointmentOptions}
                                    />
                                </FormGroup>
                            ) : null}

                            {task.type === 'tag question' ? (
                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="tag"
                                title="Tag To Set On Positive Question Answer*"
                            >
                                <option></option>
                                {tags && tags.length ? tags.map(a => (
                                    <option key={a._id} value={a._id}>{a.name} </option>
                                )) : null}
                            </StandardFormGroup>
                            ) : null}

                            {task.type === 'custom data' ? (
                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="analytics_data_type"
                                title="Custom Data Field*"
                            >
                                <option></option>
                                {analytics_data_types && analytics_data_types.length ? analytics_data_types.map(a => (
                                        a.deleted === false &&  a.collection_name === 'cases' && a.division === workflow.division  ?
                                            <option key={a._id} value={a._id}>{a.key}</option> : null
                                )) : null}
                            </StandardFormGroup>
                            ) : null}

                            {!task.persistent && !task.complete_before_move ? (
                                <StandardFormGroup
                                    obj={task}
                                    objName='task'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="required"
                                    title="Required"
                                >
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </StandardFormGroup>
                            ) : null}

                            {!task.persistent ? (
                                <StandardFormGroup
                                    obj={task}
                                    objName='task'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v === "true" ? true : false, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="complete_before_move"
                                    title="Task Is To Be Completed When Moving To This Step"
                                >
                                    <option value="false">False</option>
                                    <option value="true">True</option>

                                </StandardFormGroup>
                            ) : null}

                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="textarea"
                                field="description"
                                title="Description"
                            />

                            <StandardFormGroup
                                obj={task}
                                objName='task'
                                onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                type="select"
                                field="assigned_to"
                                populate={task.assigned_to && task.assigned_to._id ? '_id' : null}
                                title="Assigned To (User Role)"
                            >
                            <option value={null}></option>
                                {workflow.roles && workflow.roles.length ? workflow.roles.map((role, i) => (
                                <option key={i} value={role}>{role}</option>
                            )) : null}
                            </StandardFormGroup>

                            {task.persistent || task.complete_before_move  ? null : (
                                <StandardFormGroup
                                    obj={task}
                                    objName='task'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="due_after_assignment"
                                    title="Due After Assignment "
                                >
                                    <option value={null}></option>
                                    <option value={oneHour / 60}>1 Minute</option>
                                    <option value={oneHour}>1 Hour</option>
                                    <option value={oneHour * 2}>2 Hours</option>
                                    <option value={oneHour * 3}>3 Hours</option>
                                    <option value={oneHour * 6}>6 Hours</option>
                                    <option value={oneHour * 12}>12 Hours</option>
                                    <option value={oneHour * 24 * 1}>1 Day</option>
                                    <option value={oneHour * 24 * 2}>2 Days</option>
                                    <option value={oneHour * 24 * 3}>3 Days</option>
                                    <option value={oneHour * 24 * 4}>4 Days</option>
                                    <option value={oneHour * 24 * 5}>5 Days</option>
                                    <option value={oneHour * 24 * 6}>6 Days</option>
                                    <option value={oneHour * 24 * 7 * 1}>1 Week</option>
                                    <option value={oneHour * 24 * 7 * 2}>2 Weeks</option>
                                    <option value={oneHour * 24 * 7 * 3}>3 Weeks</option>
                                    <option value={oneHour * 24 * 7 * 4}>4 Weeks</option>
                                </StandardFormGroup>
                            )}

                            {!task.complete_before_move ? (
                                <StandardFormGroup
                                    obj={task}
                                    objName='task'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="persistent"
                                    title="Persistent "
                                    description="If this is set to yes than this task will not be required to move any step however it will show up as a task to fulfill on any step greater than or equal to this one until it is completed. Persistent tasks cannot be assigned due dates as they float between steps."
                                >
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </StandardFormGroup>
                            ) : null}


                            {task.persistent ? (
                                <StandardFormGroup
                                    obj={task}
                                    objName='task'
                                    onChange={(o, f, v) => this.setState({[o]: {...this.state[o], [f]: v, [`${f}State`]: v ? 'valid' : 'invalid'}})}
                                    type="select"
                                    field="persistent_everywhere"
                                    title="Persistent Everywhere"
                                    description="If this is set to true then this task will be shown on all steps, if false it will be shown on all steps greater than or equal to the one it is assigned to until finished"
                                >
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                </StandardFormGroup>
                            ) : null}
                        </Col>

                        <Col lg={4} style={{borderLeft: 'solid 1px #eee'}}>

                            <AssignAutomations
                                keyValue={task._id}
                                onChange={(values) => this.setState({automations: values.automations_ids})}
                                stateAutomations={task.automations ? task.automations : []}
                            />

                        </Col>

                    </Row>

                </div>

                <div className="modal-footer text-right">
                    {task._id ? (
                        <>
                            <button onClick={this.onDelete} className="btn btn-danger">Delete Task</button>
                            <button onClick={this.onSave} className="btn btn-success">Update Task</button>
                        </>
                    ) : (
                        <button onClick={this.onSave} className="btn btn-success">Create Task</button>
                    )}
                </div>

            </Modal>

        )

    }

}

StepDrawerTaskModal.propTypes = {
    workflow                      : PropTypes.object.isRequired,
    task                          : PropTypes.object.isRequired,
    showModal                     : PropTypes.bool.isRequired,
    toggle                        : PropTypes.func.isRequired,
    onTaskCreated                 : PropTypes.func.isRequired,
    onTaskUpdated                 : PropTypes.func.isRequired,
    onTaskDeleted                 : PropTypes.func.isRequired,
    step_id                       : PropTypes.string,
    templateAppointmentOptions    : PropTypes.array,
}

const mapStateToProps = state => {
    return {
        analytics_data_types: state.analytics_data_types.analytics_data_types,
        tags: state.tags.tags,
    };
};

export default connect(mapStateToProps, '')(StepDrawerTaskModal);
