import React, { Component } from 'react';
import Graphs from '../../_components_main/Graphs';
import { Container } from 'reactstrap';
import { connect } from 'react-redux'
import DashHeaderOpen from 'components/markup/headers/DashHeaderOpen';

class CasesGraphs extends Component {

    state = {
        loaded: false,
        collection: 'payments'
    };

    componentDidMount = async () => {



    }

    render() {

        return (

            <>

            <Container fluid>
                <DashHeaderOpen
                    title={<span><i className="fas fa-dollar-sign mr-2 text-success " /> Payments</span>}
                    breadcrumb_1="Graphs"
                    breadcrumb_2="View"
                />
            </Container>

            <Graphs

                titleMain="Payment Graphs"
                titleFilter="Payment Graph Filters"

                collection={this.state.collection}

                filters={[
                    { status: 'succeeded', filter_name: 'Succeeded' },
                    { status: 'failed', filter_name: 'Failed' },
                    { status: 'refunded', filter_name: 'Refunded' },
                    { status: 'chargeback', filter_name: 'Charge Backs' },
                    { status: 'authorized', filter_name: 'Authorized' },
                ]}

                setup={[
                    { name: 'Time Series',                            type: 'line',   size: 12,   },
                    { name: 'Raw Data',                               type: 'bar',    size: 8,    },
                    { name: 'Filter Breakdown',                       type: 'pie',    size: 4,    },
                    // { name: 'Average Contact Attempts By Time Frame', type: 'average',    size: 4,   groups: ['$created_at'],   label: 'created_at ${}' },
                    // { name: 'Total Times Contacted',                  type: 'group',      size: 8,   groups: ['$created_at'],   label: 'Contacted ${} Times' },
                    // { name: 'Average Days To Convert',                type: 'group',      size: 8,   groups: ['$created_at'],   label: 'Converted In ${} Days', format: 'days' },
                    // { name: 'Average Days To Convert By Time Frame',  type: 'average',    size: 4,   groups: ['$created_at'],   label: 'Contacted ${} Times', format: 'days' },
                ]}

                inputs={[

                    [ // column 1
                        {
                            type: 'ArchkSelect', field: 'status', label: 'Status',
                            data: [
                                {value: 'succeeded', text: 'Succeeded'},
                                {value: 'failed', text: 'Failed'},
                                {value: 'refunded', text: 'Refunded'},
                                {value: 'chargeback', text: 'Chargeback'},
                            ]
                        },
                        { type: 'ArchkNull', field: 'fortispay_recurring_id', label: 'Is A Recurring Payment', },
                        { type: 'ArchkNull', field: 'user', label: 'Made By Staff', },


                    ],

                ]}
            />

            </>

        )

    }

}



const mapStateToProps = state => {

    return {
        analytics_data_types: state.analytics_data_types.analytics_data_types
    };
};

export default connect(mapStateToProps, '')(CasesGraphs);
