/*
Documentation

This file controls all custom notifications that can get attached
to an appointment template. These are what fire at intervals before
the appointment from the servers cron job.

*/

import PropTypes from 'prop-types'
import StandardFormGroup from 'components/functional/inputs/StandardFormGroup';
import React from "react";
import { Card, CardBody, CardHeader, CardTitle, CardFooter, FormGroup } from "reactstrap";
import validator from 'utils/validator';
import ReactHTMLParser from 'react-html-parser'
import ReactQuill from "react-quill";

import Info from './Info'

const minute = 60;
const hour = 3600;
const day = 86400;

class TemplatesCrudNotifications extends React.Component {

    state = {
        showModal: false,
        past_notification: null,
        notification: {
            type: 'email and text',
            send_text_link: 'no'
        },
        error: false,
        reactQuillText: '',
    }

    handleReactQuillChange = value => {
        this.setState({
            reactQuillText: value,
            notification: { ...Object.assign({}, this.state.notification), body: value }
        });
    };

    onEditNotification = (i) => {

        const notifications = this.props.template_appointment.notifications

        this.props.onRemoveNotification(i)

        this.setState({
            past_notification: notifications[i],
            notification: notifications[i],
            reactQuillText: notifications[i].body
        })

        window.scrollTo({top: 0, left: 0})

    }

    toggleModal = () => this.setState({showModal: !this.state.showModal})

    onCancelUpdate = () => {

        const notification = this.state.past_notification
        this.props.onAddNotification({
            send_text_link            : notification.send_text_link,
            subject                   : notification.subject,
            type                      : notification.type,
            body                      : notification.body,
            time_before_appointment   : parseInt(notification.time_before_appointment)
        })

        this.setState({past_notification: null, notification: { type: 'email and text', send_text_link: 'no' }, reactQuillText: ''})

    }

    returnPass = (body, match) => {

        const regex = new RegExp(match, "g");
        body = body.replace(regex, `<span class="text-success font-weight-bold ml-1 mr-1">${match}</span>`)

        return body
    }

    returnFail = (body, match) => {

        const regex = new RegExp(match, "g");
        body = body.replace(regex, `<span class="text-danger font-weight-bold">${match}</span>`)

        return body
    }

    // this shows correct fields in green and bad fields in red
    // for each appointment notification
    renderBody = (body) => {

        const matches = body.match(/\{(.*?)\}/g,)

        if(matches && matches.length) {
            matches.forEach(match => {

                body = this.returnFail(body, match)

                if(match === '{first_name}') body = this.returnPass(body, match)
                if(match === '{middle_name}') body = this.returnPass(body, match)
                if(match === '{last_name}') body = this.returnPass(body, match)
                if(match === '{email}') body = this.returnPass(body, match)
                if(match === '{phone}') body = this.returnPass(body, match)
                if(match === '{_id}') body = this.returnPass(body, match)

                if(match === '{case.missing_docs_list}') body = this.returnPass(body, match)
                if(match === '{case.case_number}') body = this.returnPass(body, match)

                if(match === '{appointment.name}') body = this.returnPass(body, match)
                if(match === '{appointment.date}') body = this.returnPass(body, match)
                if(match === '{appointment.date_range}') body = this.returnPass(body, match)

                if(match === '{office.name}') body = this.returnPass(body, match)
                if(match === '{office.email}') body = this.returnPass(body, match)
                if(match === '{office.phone}') body = this.returnPass(body, match)
                if(match === '{office.address_line_1}') body = this.returnPass(body, match)
                if(match === '{office.address_line_2}') body = this.returnPass(body, match)
                if(match === '{office.city}') body = this.returnPass(body, match)
                if(match === '{office.state}') body = this.returnPass(body, match)
                if(match === '{office.state}') body = this.returnPass(body, match)
                if(match === '{office.postal_code}') body = this.returnPass(body, match)
                if(match === '{office.country}') body = this.returnPass(body, match)
                if(match === '{office.map}') body = this.returnPass(body, match)

                if(match === '{court.name}') body = this.returnPass(body, match)
                if(match === '{court.email}') body = this.returnPass(body, match)
                if(match === '{court.phone}') body = this.returnPass(body, match)
                if(match === '{court.address_line_1}') body = this.returnPass(body, match)
                if(match === '{court.address_line_2}') body = this.returnPass(body, match)
                if(match === '{court.city}') body = this.returnPass(body, match)
                if(match === '{court.state}') body = this.returnPass(body, match)
                if(match === '{court.postal_code}') body = this.returnPass(body, match)
                if(match === '{court.country}') body = this.returnPass(body, match)

                if(match === '{location_hearing.name}') body = this.returnPass(body, match)
                if(match === '{location_hearing.email}') body = this.returnPass(body, match)
                if(match === '{location_hearing.phone}') body = this.returnPass(body, match)
                if(match === '{location_hearing.address_line_1}') body = this.returnPass(body, match)
                if(match === '{location_hearing.address_line_2}') body = this.returnPass(body, match)
                if(match === '{location_hearing.city}') body = this.returnPass(body, match)
                if(match === '{location_hearing.state}') body = this.returnPass(body, match)
                if(match === '{location_hearing.postal_code}') body = this.returnPass(body, match)
                if(match === '{location_hearing.name}') body = this.returnPass(body, match)
                if(match === '{location_hearing.name}') body = this.returnPass(body, match)
                if(match === '{location_hearing.country}') body = this.returnPass(body, match)

                if(match === '{trustee._id}') body = this.returnPass(body, match)
                if(match === '{trustee.first_name}') body = this.returnPass(body, match)
                if(match === '{trustee.middle_name}') body = this.returnPass(body, match)
                if(match === '{trustee.last_name}') body = this.returnPass(body, match)
                if(match === '{trustee.email}') body = this.returnPass(body, match)
                if(match === '{trustee.phone}') body = this.returnPass(body, match)

                if(match.includes('party.')) {
                    if(match.split('.').length === 3) body = this.returnPass(body, match)
                }

                if(match.includes('role.')) {
                    if(match.split('.').length === 3) body = this.returnPass(body, match)
                }

                if(match.includes('cd.')) {
                    if(match.split('.').length === 2) body = this.returnPass(body, match)
                }

            })
        }

        return body

    }

    renderTime = (time_before_appointment) => {

        const value = parseInt(time_before_appointment)

        switch (value) {
            case 0 :          return 'On Appointment Creation';

            case minute * 15 : return '15 Minutes Before';
            case minute * 30 : return '30 Minutes Before';
            case minute * 45 : return '45 Minutes Before';
            case hour        : return '1 Hour Before';
            case hour * 2    : return '2 Hours Before';
            case hour * 3    : return '3 Hours Before';
            case hour * 4    : return '4 Hours Before';
            case hour * 8    : return '8 Hours Before';
            case hour * 12   : return '12 Hours Before';
            case day         : return '1 Day Before';
            case day * 2     : return '2 Days Before';
            case day * 3     : return '3 Days Before';
            case day * 7     : return '7 Days Before';
            case day * 14    : return '14 Days Before';
            case day * 21    : return '21 Days Before';
            case day * 28    : return '28 Days Before';
            case day * 30    : return '30 Days Before';
            case day * 45    : return '45 Days Before';
            case day * 60    : return '60 Days Before';

            case -minute * 15 : return '15 Minutes After';
            case -minute * 30 : return '30 Minutes After';
            case -minute * 45 : return '45 Minutes After';
            case -hour        : return '1 Hour After';
            case -hour * 2    : return '2 Hours After';
            case -hour * 3    : return '3 Hours After';
            case -hour * 4    : return '4 Hours After';
            case -hour * 8    : return '8 Hours After';
            case -hour * 12   : return '12 Hours After';
            case -day         : return '1 Day After';
            case -day * 2     : return '2 Days After';
            case -day * 3     : return '3 Days After';
            case -day * 7     : return '7 Days After';
            case -day * 14    : return '14 Days After';
            case -day * 21    : return '21 Days After';
            case -day * 28    : return '28 Days After';
            case -day * 30    : return '30 Days After';
            case -day * 45    : return '45 Days After';
            case -day * 60    : return '60 Days After';

            default: return 'Error'
        }

    }

    onInputChange = (val, stateField) => validator.onInputChange(val, 'notification', stateField, this)

    onAddNotification = () => {

        this.setState({error: false})

        const { notification } = this.state;

        if(!notification.body) return this.setState({error: true})
        if(notification.time_before_appointment === undefined) return this.setState({error: true})
        if(notification.time_before_appointment === 'false') return this.setState({error: true})


        this.props.onAddNotification({
            send_text_link            : notification.send_text_link,
            subject                   : notification.subject,
            type                      : notification.type,
            body                      : notification.body,
            time_before_appointment   : parseInt(notification.time_before_appointment)
        })

        this.setState({past_notification: null, notification: { type: 'email and text', send_text_link: 'no' }, reactQuillText: ''})

    }

    render() {

        const { onRemoveNotification, template_appointment } = this.props
        const { notifications } = template_appointment;

        const { notification, error, showModal, past_notification} = this.state

        // sort by furthest away from appointment first
        if(notifications) notifications.sort((a, b) => a.time_before_appointment > b.time_before_appointment ? -1 : 1)

        return (

            <Card style={{boxShadow: 'none', borderLeft: 'none', borderBottom: 0}} className="mb-0">

                <CardHeader className="bg-secondary">
                    <CardTitle className="mb-0 float-left">Appointment Reminders</CardTitle>
                </CardHeader>

                <CardBody>

                    <FormGroup>
                        <div
                            style={{borderBottom: 'solid 1px #eee'}}
                            className={notification.bodyState === 'invalid' ? 'quill-invalid' : null}
                        >
                            <label className="form-control-label">Notification Body</label>
                            <ReactQuill
                                value={this.state.reactQuillText}
                                onChange={this.handleReactQuillChange}
                                theme="snow"
                                modules={{
                                    toolbar: [
                                        ["bold", "italic"],
                                        ["link",],
                                        [
                                            { list: "ordered" },
                                            { list: "bullet" }
                                        ]
                                    ]
                                }}
                            />
                        </div>
                    </FormGroup>

                    <StandardFormGroup
                        obj={notification}
                        objName="notification"
                        onChange={(o, f, v) => this.onInputChange(v, f)}
                        type="select"
                        field="time_before_appointment"
                        title="Reminder Time"
                    >
                        <option value="false">Select Time</option>
                        <option value={0}>On Appointment Creation</option>

                        <option value={minute * 15}>15 Minutes Before</option>
                        <option value={minute * 30}>30 Minutes Before</option>
                        <option value={minute * 45}>45 Minutes Before</option>
                        <option value={hour}>1 Hour Before</option>
                        <option value={hour * 2}>2 Hours Before</option>
                        <option value={hour * 3}>3 Hours Before</option>
                        <option value={hour * 4}>4 Hours Before</option>
                        <option value={hour * 8}>8 Hours Before</option>
                        <option value={hour * 12}>12 Hours Before</option>
                        <option value={day}>1 Day Before</option>
                        <option value={day * 2}>2 Days Before</option>
                        <option value={day * 3}>3 Days Before</option>
                        <option value={day * 7}>7 Days Before</option>
                        <option value={day * 14}>14 Days Before</option>
                        <option value={day * 21}>21 Days Before</option>
                        <option value={day * 28}>28 Days Before</option>
                        <option value={day * 30}>30 Days Before</option>
                        <option value={day * 45}>45 Days Before</option>
                        <option value={day * 60}>60 Days Before</option>

                        <option value={-minute * 15}>15 Minutes After</option>
                        <option value={-minute * 30}>30 Minutes After</option>
                        <option value={-minute * 45}>45 Minutes After</option>
                        <option value={-hour}>1 Hour After</option>
                        <option value={-hour * 2}>2 Hours After</option>
                        <option value={-hour * 3}>3 Hours After</option>
                        <option value={-hour * 4}>4 Hours After</option>
                        <option value={-hour * 8}>8 Hours After</option>
                        <option value={-hour * 12}>12 Hours After</option>
                        <option value={-day}>1 Day After</option>
                        <option value={-day * 2}>2 Days After</option>
                        <option value={-day * 3}>3 Days After</option>
                        <option value={-day * 7}>7 Days After</option>
                        <option value={-day * 14}>14 Days After</option>
                        <option value={-day * 21}>21 Days After</option>
                        <option value={-day * 28}>28 Days After</option>
                        <option value={-day * 30}>30 Days After</option>
                        <option value={-day * 45}>45 Days After</option>
                        <option value={-day * 60}>60 Days After</option>

                    </StandardFormGroup>

                    <StandardFormGroup
                        obj={notification}
                        objName="notification"
                        onChange={(o, f, v) => this.onInputChange(v, f)}
                        type="select"
                        field="type"
                        title="Notification Type"
                    >
                        <option value="email and text">Email And Text</option>
                        <option value="email">Email</option>
                        <option value="text">Text</option>
                    </StandardFormGroup>

                    {notification.type && notification.type.includes('email') && (
                        <StandardFormGroup
                            obj={notification}
                            objName="notification"
                            onChange={(o, f, v) => this.onInputChange(v, f)}
                            type="text"
                            field="subject"
                            title="Email Subject"
                        />
                    )}

                    {notification.type && notification.type === 'email' && (
                        <StandardFormGroup
                            obj={notification}
                            objName="notification"
                            onChange={(o, f, v) => this.onInputChange(v, f)}
                            type="select"
                            field="send_text_link"
                            title="Text Client Link To View Email"
                        >
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </StandardFormGroup>
                    )}



                    {error ? <div className="alert alert-danger">Notification body and reminder time must be set to create a notification</div> : null}

                </CardBody>

                <CardFooter className="text-right bg-secondary">
                    {past_notification ? (
                        <button className="btn btn-outline-warning" onClick={this.onCancelUpdate}>Cancel</button>
                    ) : null}
                    <button className="btn btn-outline-success" onClick={this.onAddNotification}>Save Reminder</button>
                </CardFooter>


                <div className="table-responsive">
                    <table className="table border-bottom ">

                        <thead>
                            <tr>
                                <th>
                                    Notification Body
                                    <span className="float-right">
                                        <i style={{fontSize: '16px'}} onClick={this.toggleModal} className="fas fa-info-circle text-info-original" />
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {notifications && notifications.length ? notifications.map((notification, i) => (
                                <tr key={i}>
                                    <td style={{whiteSpace: 'pre-line'}} className="border">
                                        <h4 className="bg-dark text-white p-3 px-4 mx--4 mt--2">
                                            {this.renderTime(notification.time_before_appointment)}

                                            <i onClick={() => onRemoveNotification(i)} className="float-right fas fa-trash text-danger cursor-pointer mr-3" />
                                            <i onClick={() => this.onEditNotification(i)} className="float-right fas fa-edit text-success cursor-pointer mr-3" />

                                        </h4>
                                        <span className="text-sm">{ReactHTMLParser(this.renderBody(notification.body))}</span>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td>No appointment notifications have been added.</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>

                <Info
                    showModal={showModal}
                    toggleModal={this.toggleModal}
                />

            </Card>

        );
    }
}

TemplatesCrudNotifications.propTypes = {
    template_appointment    : PropTypes.object.isRequired,
    onAddNotification       : PropTypes.func.isRequired,
    onRemoveNotification    : PropTypes.func.isRequired,
}

export default TemplatesCrudNotifications
